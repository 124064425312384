import React, { useMemo } from 'react';
import { App, Col, Row } from 'antd';
import {
  Card,
  Form,
  i18n,
  InputWithSearch,
  TextInput,
} from '../../../../../../../Components';
import { TextInputWithMask } from '../../../../../../../Components/StateInputs/TextInputWithMask';
import { IPutConglomerateRequest } from '../../../../../../../Data/Interfaces/request/Conglomerate/IConglomerate';
import { LIST_FOR_DORPDOWN_ADMIN_TYPE } from '../../../../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import {
  EDIT_CONGLOMERATE_TYPE,
  GET_CONGLOMERATE_TYPE,
} from '../../../../../../../ControllerApiHook/UniqIds/Accountmanager/ConglomerateKeys';
import { FooterButton } from '../../../../../../../Layouts/CompanyFormLayout/Components/FooterButton';
import { PageLoading } from '../../../../../../../Layouts/PageLoading';
import { ConglomerateDetailsSchema } from './ConglomerateDetails.schema';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { IListCompanyData } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import { ConglomerateAPI } from '@/Data/API/Accountmanager/ConglomerateApi';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { IGetConglomerateResponse } from '@/Data/Interfaces/response/Conglomerate/IConglomerateResponde';
import { history } from '@/Store';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';

interface IConglomerateEditForm {
  disabled: boolean;
  externalId: string;
  onDisabledEditButton: (value: boolean) => void;
}

export const ConglomerateEditForm: React.FC<IConglomerateEditForm> = ({
  disabled,
  externalId,
  onDisabledEditButton,
}) => {
  const app = App.useApp();

  const { data, isLoading, refetch, fetchNewPage } =
    useControllerQueryListApiHook({
      uniqId: LIST_FOR_DORPDOWN_ADMIN_TYPE,
      entityApi: CompanyAPI.listCompanyAdminForDropdown,
      pagination: {
        sorter: { column: 'nomeFantasia', direction: 'DESC' },
        filter: [
          {
            filterName: 'conglomeradoExternalId',
            value: externalId,
          },
          {
            filterName: 'tipoEmpresaId',
            value: 1,
          },
        ],
      },
      autoIncrement: true,
    });

  const { data: conglomerate, isLoading: conglomerateLoading } =
    useControllerQueryApiHook<IGetConglomerateResponse>({
      uniqId: GET_CONGLOMERATE_TYPE,
      entityApi: ConglomerateAPI.getConglomerate,
      externalIds: [externalId],
    });

  const editConglomerate = useControllerMutateApiHook<any>({
    uniqId: EDIT_CONGLOMERATE_TYPE,
    entityApi: ConglomerateAPI.editConglomerate,
    options: {
      onSuccess: () => history.push('/admin-bootis/conglomerate'),
      onError: (x: any) => messageErrorToast(x),
    },
  });

  const onSubmit = async (values: IPutConglomerateRequest) => {
    const res = await editConglomerate.mutateAsync(values);
    if (res.success) onDisabledEditButton(true);
  };

  const parentCompany = useMemo(
    () => conglomerate?.empresas.find((x) => x.tipoEmpresaId === 1),
    [conglomerate?.empresas]
  );

  const initialValues = useMemo(
    () => ({
      conglomeradoExternalId: conglomerate?.externalId,
      nome: conglomerate?.nome,
      ativo: conglomerate?.ativo,
      ...(parentCompany?.externalId && {
        empresaExternalIdMatriz: parentCompany?.externalId,
        empresaExternalIdMatrizLabelValue: {
          id: parentCompany?.externalId,
          label: parentCompany?.nomeFantasia,
          avatar: {
            name: parentCompany?.nomeFantasia,
          },
        },
      }),
    }),
    [conglomerate, parentCompany?.externalId, parentCompany?.nomeFantasia]
  );

  return (
    <PageLoading loading={isLoading || conglomerateLoading}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        schema={ConglomerateDetailsSchema}
      >
        {(form) => (
          <Card
            title={i18n.t('adminBootis.editConglomerate.conglomerateData')}
            withoutCollapse
          >
            <Row gutter={[16, 0]}>
              <Col flex="80%">
                <TextInput
                  name="nome"
                  label={i18n.t(
                    'adminBootis.editConglomerate.conglomerateDataCard.conglomerateName'
                  )}
                  disabled={disabled}
                  dataTestId="input-name"
                />
              </Col>
              <Col flex="20%">
                <TextInputWithMask
                  value={conglomerate?.dataInclusao}
                  mask="dataMask"
                  label={i18n.t(
                    'adminBootis.editConglomerate.conglomerateDataCard.inclusionDate'
                  )}
                  disabled
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col flex="28%">
                <InputWithSearch
                  name="empresaExternalIdMatriz"
                  items={data?.data?.map((x: IListCompanyData) => ({
                    id: x.externalId,
                    label: x.nomeFantasia,
                    avatar: {
                      name: x.nomeFantasia,
                    },
                  }))}
                  label={i18n.t(
                    'adminBootis.editConglomerate.conglomerateDataCard.headquarters'
                  )}
                  selectedItemFromForm="empresaExternalIdMatrizLabelValue"
                  withoutMarginBottom
                  onScrollEnd={fetchNewPage}
                  onSearch={(search) =>
                    refetch({
                      search: search,
                    })
                  }
                  disabled={disabled}
                  isLoading={isLoading}
                  withoutSearchIcon
                  dataTestId="dropdown-company"
                />
              </Col>
            </Row>
            {!disabled && (
              <FooterButton
                cancelButtonClick={
                  () => handleCancelEdit(form, app, () => onDisabledEditButton(true))
                }
                loading={editConglomerate.isLoading}
                dataTestId="submit-button"
              />
            )}
          </Card>
        )}
      </Form>
    </PageLoading>
  );
};
