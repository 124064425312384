import { FC, useEffect, useRef, useState } from 'react';
import { Dropdown, Space } from 'antd';
import styles from './DropdownTab.module.scss';
import { Icon } from '../Icon';
import { history } from '../../Store';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';
import { Tooltip } from '../Tooltip';
import { Hotkey, PossibleKey } from '../Shortcuts/Hotkey';

interface IDropdownTab {
  tabLabel: string;
  tabItems: {
    href: string;
    label: string;
    permission?: number;
    permissionTooltip?: string;
    disabled?: boolean;
    hotKeyIcon?: PossibleKey;
    hotKeyMultipleIcons?: PossibleKey[];
  }[];
}

export const DropdownTab: FC<IDropdownTab> = ({ tabLabel, tabItems }) => {
  const [dropdownWidth, setDropdownWidth] = useState('100px');
  const ref = useRef<HTMLDivElement>(null);
  const totalPadding = 32;
  const currentUserRoles = getCurrentUserRoles();

  useEffect(() => {
    if (ref.current) {
      setDropdownWidth(`${ref.current.offsetWidth + totalPadding}px`);
    }
  }, []);

  const userHasPermission = (permission?: number) => {
    if (!permission) return true;
    return currentUserRoles.includes(permission);
  };

  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown
          placement="bottom"
          dropdownRender={() => {
            return (
              <div
                className={styles['dropdownContainer']}
                style={{ width: dropdownWidth }}
              >
                <div className={styles['dropdownBoxShadow']}>
                  {tabItems.map((item) => (
                    <Tooltip
                      title={item.permissionTooltip}
                      showMe={!userHasPermission(item.permission)}
                    >
                      <div
                        className={`${styles['dropdownItem']} ${
                          userHasPermission(item.permission) && !item.disabled
                            ? ''
                            : styles['hasNoPermission']
                        }`}
                        key={item.label}
                        onClick={() => {
                          history.push(item.href);
                        }}
                      >
                        {item.label}
                        {(item.hotKeyIcon || item.hotKeyMultipleIcons) && (
                          <Hotkey
                            hotKey={item.hotKeyIcon}
                            hotkeys={item.hotKeyMultipleIcons}
                            width={60}
                            height={20}
                            fontSize={10}
                          />
                        )}
                      </div>
                    </Tooltip>
                  ))}
                </div>
              </div>
            );
          }}
        >
          <div ref={ref} className={styles['dropdownLabel']}>
            {tabLabel}
            <div style={{ marginLeft: '5px' }}>
              <Icon icon="chevron-down" style={{ color: undefined }} />
            </div>
          </div>
        </Dropdown>
      </Space>
    </Space>
  );
};
