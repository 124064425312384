import { useTranslation } from 'react-i18next';
import { List as ProductList } from '../Components/ProductForm';
import { DetailsForm } from '../Components/Details';
import { IUseMemorizations } from './interfaces';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';
import { history } from '@/Store';
import { IPermissionEditButon } from '@/Components/HorizontalTabs';
import { useParams } from 'react-router';

export const useEditMemorizations = ({
  disabled,
  setDisabled,
  setModalDeleteSubGroup,
  data,
  getShortcut,
}: IUseMemorizations) => {
  const params: Readonly<Partial<{ externalId: string; tab: string }>> =
    useParams();
  const { t } = useTranslation();

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabProductsHotkey = getShortcut('alternateTabProducts');
  const editSubGroupHotkey = getShortcut('editSubGroup');

  const items = [
    {
      label: t('admin.editUserPage.tab.details'),
      key: 'details',
      children: (
        <DetailsForm
          disabled={disabled}
          onChangeDisabled={(x: boolean) => setDisabled(x)}
        />
      ),
      dataTestId: 'tab-sub-group-details',
      shortCutTooltip: {
        title: alternateTabDetailsHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
    {
      label: t('products.common.products'),
      key: 'products',
      children: <ProductList />,
      dataTestId: 'tab-sub-group-products',
      shortCutTooltip: {
        title: alternateTabProductsHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
  ];

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: t('admin.editUserPage.tab.buttonActions.delete'),
      icon: 'trash',
      onClick: () => setModalDeleteSubGroup(true),
      dataTestId: 'delete-sub-group-option',
      key: 'backspace',
    },
  ];

  const rightButton = {
    ...(disabled &&
      params.tab === 'details' && {
        onClick: () => setDisabled(false),
        isVisible: disabled,
        codePermissionEdit: 3016,
        messageTooltipPermission: 'noPermission.supply.subGroups.edit',
        dataTestId: 'edit-subgroup-button',

        hotkeyTooltip: {
          title: editSubGroupHotkey.tooltip,
        },
      }),
  };

  const leftButton: IPermissionEditButon | undefined = {
    isVisible: true,
    onClick: () =>
      history.push(`/inventory/groups/${data?.grupoExternalId}/details`),
    leftIcon: 'tag',
    children: t('products.subGroupList.goToGroup'),
  };

  return {
    items,
    dropdownItems,
    rightButton,
    leftButton,
  };
};
