import { Icon, Text } from '@/Components';
import { RadioButton } from '@/Components/Inputs/RadioButton';
import { RadioGroup } from '@/Components/Inputs/RadioButton/RadioGroup';
import { FormikContextType } from 'formik';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { BaseItem } from '../SortableList/SortableList';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { useTranslation } from 'react-i18next';
import { ComponentType } from '../..';

import styles from './OptionsButton.module.scss';

export enum QSPType {
  normal = 1,
  qsp = 2,
  qs = 3,
}

interface IOptionsButton {
  index: number;
  setItems: Dispatch<SetStateAction<BaseItem[]>>;
  setEditingSynonymComponent: Dispatch<SetStateAction<boolean>>;
  setVisibleOptionButton: Dispatch<SetStateAction<boolean>>;

  setVisibleCreateSynonymModal: Dispatch<SetStateAction<boolean>>;

  form: FormikContextType<IPostManipulatedRecipeRequest>;
  componentClass?: number;
}

export const OptionsButton: FC<IOptionsButton> = ({
  index,
  setItems,
  setEditingSynonymComponent,
  setVisibleOptionButton,

  setVisibleCreateSynonymModal,

  form,
  componentClass,
}) => {
  const { t } = useTranslation();
  const hidedLabel = useMemo(
    () => form.values.itens[index].ocultaRotulo,
    [form.values.itens, index]
  );

  const onComponentDeleteRow = useCallback(() => {
    if (form.values.itens.length === 1) return setItems([]);
    const components: any = form.values.itens.slice();
    components.splice(index, 1);
    setItems(components);
  }, [form.values.itens, index, setItems]);

  const optionsi18n = useMemo(
    () =>
      'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components.optionsButton',
    []
  );

  return (
    <div className={styles['container']}>
      <RadioGroup name={`itens[${index}].tipo`} styles={{ width: '100%' }}>
        <RadioButton
          name="qsp"
          value={QSPType.qsp}
          label="Q.S.P."
          labelType="ui-tiny-content"
          className={styles['radio-button']}
        />
        <RadioButton
          name="qs"
          value={QSPType.qs}
          label="Q.S."
          labelType="ui-tiny-content"
          className={styles['radio-button']}
        />
        <RadioButton
          name="normal"
          value={QSPType.normal}
          label="Normal"
          labelType="ui-tiny-content"
          className={styles['radio-button']}
        />
      </RadioGroup>
      <div className={styles['divider']} />

      <Divflex
        className={styles['content']}
        onClick={() => {
          setEditingSynonymComponent(true);
          setVisibleOptionButton(false);
        }}
      >
        <Icon icon="rename" size="ML" color="text-300" />
        <Text
          type="ui-tiny-content"
          color="text-50"
          children={t(optionsi18n + '.editLabel')}
        />
      </Divflex>

      {form.values.itens[index].descricaoRotulo &&
        (componentClass === ComponentType.formula ||
          componentClass === ComponentType.rawMaterial) && (
          <Divflex
            className={styles['content']}
            onClick={() => {
              setVisibleCreateSynonymModal(true);
              setVisibleOptionButton(false);
            }}
          >
            <Icon icon="synonym-square" size="ML" color="text-300" />
            <Text
              type="ui-tiny-content"
              color="text-50"
              children={t(optionsi18n + '.saveSynonym')}
            />
          </Divflex>
        )}

      <Divflex
        className={styles['content']}
        onClick={() => {
          form.setFieldValue(
            `itens[${index}].ocultaRotulo`,
            form.values.itens[index] ? !hidedLabel : true
          );
          setVisibleOptionButton(false);
        }}
      >
        <Icon
          icon={hidedLabel ? 'eye' : 'close-eye'}
          size="ML"
          color="text-300"
        />
        <Text
          type="ui-tiny-content"
          color="text-50"
          children={t(
            optionsi18n + (hidedLabel ? '.showOnLabel' : '.hideOnLabel')
          )}
        />
      </Divflex>
      <div className={styles['divider']} />
      <Divflex
        className={styles['trash']}
        onClick={() => onComponentDeleteRow()}
      >
        <Icon icon="trash-line" size="ML" color="text-300" />
        <Text
          type="ui-tiny-content"
          color="text-50"
          children={t('common.delete')}
        />
      </Divflex>
    </div>
  );
};
