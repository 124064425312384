import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { 
  FETCH_GET_PRODUCT, 
  FETCH_LIST_FOR_DROPDOWN_TYPE 
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useInputProductCallAPI = ({
  produtoExternalId
}: IUseCallAPI) => {
  const {
    data: productList,
    isLoading: isProductLoading,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  }: any = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const { data: product } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalIds: [produtoExternalId]
  });

  return {
    productList,
    product,
    isProductLoading,
    fetchNewProductPage,
    refetchSearchProductPage,
  };
};
