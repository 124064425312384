import React, { FC, ReactNode } from 'react';

import styles from './Row.module.scss';

interface IRow {
  children?: ReactNode;
  className?: string;
}

export const Row: FC<IRow> = ({ children, className }) => (
  <div className={`${styles['Row']} ${className}`}>{children}</div>
);
