import { FC } from 'react';
import { Button } from '@/Components';
import { useTranslation } from 'react-i18next';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';
import { App } from 'antd';

import styles from '../../CapsuleColors.module.scss';

interface IFooterButtons {
  form: any
  isLoadingEditCapsuleColor: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const FooterButtons: FC<IFooterButtons> = ({
  form,
  isLoadingEditCapsuleColor,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const app = App.useApp();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => 
          handleCancelEdit(form, app, () => onChangeDisabled(true))
        }
      />
      <Button
        type="primary"
        children={
          isLoadingEditCapsuleColor ? t('common.saving') : t('common.save')
        }
        htmlType="submit"
        loading={isLoadingEditCapsuleColor}
        dataTestId="submit-capsule-color"
      />
    </div>
  );
};
