import { FC } from 'react';
import { Table } from '@/Components';
import { UsersColumns } from './UserForm.columns';
import { AddUserModal } from './Modals/AddUserGroup';
import { IUserGroup } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useUserFormStates } from './Hooks/useUserFormStates';
import { useUserFormFunctions } from './Hooks/useUserFormFunctions';
import { useUserFormMemorizations } from './Hooks/useUserFormMemorizations';
import { useUserFormCallAPI } from './Hooks/useUserFormCallAPI';
import { useTranslation } from 'react-i18next';
import { useUserGroupUsersShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/userGroups/users';
import { PageLoading } from '@/Layouts/PageLoading';

interface IUserForm {
  externalId: string;
  userGroup?: IUserGroup | null;
}

export const UserForm: FC<IUserForm> = ({ externalId, userGroup }) => {
  const { t } = useTranslation();
  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visible,
    setVisible,
    selectedItems,
    setSelectedItems,
  } = useUserFormStates();

  const {
    userListData,
    userListLoading,
    userListRefetch,
    removeUserInUserGroup,
  } = useUserFormCallAPI({ externalId });

  const { getShortcut } = useUserGroupUsersShortcuts(t, {
    selectAllCallback: () => {
      setSelectedItems(userListData?.data?.data || []);
    },
  });

  const { headerOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useUserFormMemorizations({
      userGroup,
      selectedRowsOnTable,
      setVisible,
      getShortcut,
    });

  const { deleteUsers, selectRowsOnTable } = useUserFormFunctions({
    externalId,
    removeUserInUserGroup,
    setSelectedRowsOnTable,
  });

  return (
    <>
      <PageLoading loading={userListLoading}>
        <Table
          headerOptions={headerOptions}
          hasSelection
          columns={UsersColumns(t)}
          onRefetch={userListRefetch}
          onDelete={deleteUsers}
          pagination={userListData}
          loading={userListLoading}
          keyToSelect="externalId"
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          deleteModal={deleteModal}
          initialRowsSelected={selectedItems}
          tableHeaderTooltip={tableHeaderHotKeyTooltip}
          selectedRowsOnTable={selectedRowsOnTable}
        />
      </PageLoading>
      <AddUserModal
        visible={visible}
        setVisible={setVisible}
        externalId={externalId}
      />
    </>
  );
};
