import React from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { FormSubGroupModal } from './Modal/FormSubGroupModal';
import { subGroupCollums } from './sub-groups.columns';
import { PageLoading } from '@/Layouts/PageLoading';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { useSubGroupListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/subGroups/list';

export const List: React.FC = () => {
  const { t } = useTranslation();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleModal,
    setVisibleModal,
    visibleEditModal,
    setVisibleEditModal,
    selectedItems,
  } = useListStates();

  const { deleteSubGroup, subGroupList, isLoading, refetchSubGroupList } =
    useListCallAPI();

  const { getShortcut } = useSubGroupListShortcuts(t, {});

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      subGroupList,
      setVisibleModal,
      setVisibleEditModal,
      selectedRowsOnTable,
      getShortcut,
    });

  const { onDelete, onVisibleModal, onVisibleEditModal } = useListFunctions({
    setSelectedRowsOnTable,
    deleteSubGroup,
    setVisibleModal,
    setVisibleEditModal,
  });

  return (
    <>
      <PageLoading loading={isLoading}>
        <Table
          dataTestId="inventory-sub-groups"
          columns={subGroupCollums(t)}
          headerOptions={headerOptions}
          rowsOptions={rowOptions}
          loading={isLoading}
          hasSelection
          onRefetch={refetchSubGroupList}
          pagination={subGroupList}
          onDelete={onDelete}
          deleteModal={deleteModal}
          selectedRowsOnTable={selectedRowsOnTable}
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          tableHeaderTooltip={tableHeaderHotKeyTooltip}
          keyToSelect="externalId"
          initialRowsSelected={selectedItems}
        />
        <FormSubGroupModal
          changeVisible={onVisibleModal}
          visible={visibleModal}
          group={selectedRowsOnTable}
        />
        <FormSubGroupModal
          changeVisible={onVisibleEditModal}
          visible={visibleEditModal}
          group={selectedRowsOnTable}
          title={`${t('common.edit')} "${
            selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
          }"`}
          selectedRowsOnTable={selectedRowsOnTable}
        />
      </PageLoading>
    </>
  );
};
