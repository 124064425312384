import { InputWithSearch } from '@/Components';
import { ProductClass } from '@/Data/Interfaces/model/productClassEnum';
import { IProductPrescriptionListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { FormikContextType } from 'formik';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { ComponentType, ComponentTypeIcon } from '../..';
import { useTranslation } from 'react-i18next';
import { QSPType } from '../OptionsButton';
import _ from 'lodash';

interface ISelectComponent {
  index: number;

  setEditingComponent: Dispatch<SetStateAction<boolean>>;

  componentsEditing: number[];

  form: FormikContextType<any>;

  productPrescriptionList: any;

  focus: boolean;

  validatingComponents?: boolean;

  setSynonymousOf: Dispatch<SetStateAction<string | null>>;
  setComponentClass: Dispatch<SetStateAction<number | undefined>>;
  setProductDescription: Dispatch<SetStateAction<string | undefined>>;
}
export const SelectComponent: FC<ISelectComponent> = ({
  index,

  setEditingComponent,

  componentsEditing,

  form,

  productPrescriptionList,

  focus,

  validatingComponents,

  setSynonymousOf,
  setComponentClass,
  setProductDescription,
}) => {
  const { t } = useTranslation();

  const componenti18n = useMemo(
    () => 'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components',
    []
  );
  const optionsFilteri18n = useMemo(
    () =>
      'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components.optionsFilter',
    []
  );

  const filterSearchs = useMemo(
    () => [
      {
        label: t(optionsFilteri18n + '.rawMaterial'),
        id: ProductClass.Feedstock,
        checked: true,
        disabled: true,
      },
      {
        label: t(optionsFilteri18n + '.finishedProduct'),
        id: ProductClass.FinishedProduct,
      },
      {
        label: t(optionsFilteri18n + '.packaging'),
        id: ProductClass.Packaging,
      },
      {
        label: t(optionsFilteri18n + '.capsuleType'),
        id: ProductClass.TypeCapsule,
      },
      {
        label: t(optionsFilteri18n + '.service'),
        id: ProductClass.Service,
      },
      {
        label: t(optionsFilteri18n + '.readyCapsule'),
        id: ProductClass.ReadyCapsule,
      },
      {
        label: t(optionsFilteri18n + '.useAndConsumption'),
        id: ProductClass.UsageConsumption,
      },
    ],
    [optionsFilteri18n, t]
  );

  const addNewLine = useCallback(
    (itemSelected: IProductPrescriptionListData) => {
      if (!form.values?.itens || form.values?.itens?.length === index + 1)
        form.setFieldValue('itens', [...form.values.itens, { id: form.values.itens[index].id + 1 }]);
      if (
        ComponentType.rawMaterial !== itemSelected.tipoComponente &&
        ComponentType.formula !== itemSelected.tipoComponente
      )
        form.setFieldValue(`itens[${index}].ocultaRotulo`, true);

      form.setFieldValue(`itens[${index}].descricaoRotulo`, undefined);
      form.setFieldValue(`itens[${index}].unidadeMedidaId`, undefined);
      form.setFieldValue(`itens[${index}].tipo`, QSPType.normal);
      form.setFieldValue(
        `itens[${index}].produtoExternalId`,
        itemSelected.produtoExternalId
      );
      form.setFieldValue(
        `itens[${index}].produtoSinonimoExternalId`,
        itemSelected.sinonimoExternalId
      );

      setEditingComponent(false);
      setProductDescription(itemSelected.descricao);
      setComponentClass(itemSelected.classeProduto);
    },
    [index, form, setEditingComponent, setProductDescription, setComponentClass]
  );

  return (
    <InputWithSearch
      focus={focus}
      name={`itens[${index}].externalId`}
      placeHolder={t(componenti18n + '.searchPlaceholder')}
      filterSearch={{
        items: filterSearchs,
        title: t(componenti18n + '.searchFilterDescription'),
      }}
      onScrollEnd={productPrescriptionList.fetchNewPage}
      items={productPrescriptionList.data?.data?.map(
        (x: IProductPrescriptionListData) => ({
          id: x.externalId,
          label: x.descricao,
          subLabel: `${t('common.code')}: ${x.codigo}${
            x.sinonimoExternalId
              ? ` | ${t(componenti18n + '.synonymOf')} ${x.produtoDescricao}`
              : ''
          }`,
          icon: {
            icon: ComponentTypeIcon[x.tipoComponente],
            color: 'text-300',
          },
          allItem: x,
        })
      )}
      error={
        validatingComponents &&
        componentsEditing.some((value) => value === index)
      }
      onSearch={(search, filters) =>
        !_.isNil(filters) &&
        productPrescriptionList.refetch({
          pageIndex: 1,
          pageSize: 10,
          search: search,
          filter: [{ filterName: 'classeProdutoIds', value: [0, ...filters] }],
        })
      }
      onChange={(_, itemSelected: IProductPrescriptionListData) =>
        addNewLine(itemSelected)
      }
      isLoading={productPrescriptionList.isLoading}
      withoutMarginBottom
      getPopupContainerId="add-manipulated-recipe-dropdown-components-container"
      withoutLeftIconSelected
    />
  );
};
