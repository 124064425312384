import React, { useEffect, FC, useState } from 'react';
import { FooterButton } from '../Components/FooterButton';
import { useFormikContext } from 'formik';
import { Card, i18n, InputWithSearch } from '../../../Components';
import { App, Col, Row } from 'antd';
import { 
  LIST_FOR_DORPDOWN_ADMIN_TYPE 
} from '../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { RadioButton } from '../../../Components/StateInputs/RadioButton';
import { RadioGroup } from '../../../Components/StateInputs/RadioButton/RadioGroup';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { IListCompanyData } from '@/Data/Interfaces/response/Company/ICompanyResponse';

import styles from './CompanyBillingsCard.module.scss';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';

export enum BillingsTypeEnum {
  ownCompany = 1,
  anotherCompany = 2,
}

interface ICompanyBillingsCard {
  editForm?: boolean;

  onButtonChange: (value: boolean) => void;
  companyGroupExternalId?: string;

  radioCompanyGroup: boolean;

  radioBillings: boolean;
  isLoading?: boolean;

  closeEditButton?: boolean;
  onChangeCloseEditButton?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompanyBillingsCard: FC<ICompanyBillingsCard> = ({
  editForm,
  onButtonChange,
  radioCompanyGroup,
  companyGroupExternalId,
  radioBillings,
  isLoading,
  closeEditButton,
  onChangeCloseEditButton,
}) => {
  const app = App.useApp();
  const form: any = useFormikContext();
  
  const [buttonDisabled, setButtonDisabled] = useState(editForm);

  const { data, refetch, fetchNewPage } = useControllerQueryListApiHook({
    uniqId: LIST_FOR_DORPDOWN_ADMIN_TYPE,
    entityApi: CompanyAPI.listCompanyAdminForDropdown,
    autoIncrement: true
  });

  useEffect(() => {
    if(form?.values?.conglomeradoExternalId || companyGroupExternalId)
      refetch({
        filter: [
          {
            filterName: 'conglomeradoExternalId',
            value: form?.values?.conglomeradoExternalId
              ? form?.values?.conglomeradoExternalId
              : companyGroupExternalId,
          },
        ],
      });
  }, [form?.values?.conglomeradoExternalId, companyGroupExternalId, radioBillings]);

  useEffect(() => {
    !editForm && form?.setFieldValue('empresaPagadoraExternalId', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioCompanyGroup, radioBillings]);

  useEffect(() => {
    !editForm && onButtonChange && onButtonChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioCompanyGroup]);

  useEffect(() => {
    setButtonDisabled(closeEditButton ? closeEditButton : buttonDisabled);
    
    onChangeCloseEditButton &&
      onChangeCloseEditButton((prevState) => closeEditButton ? false : prevState);
  }, [closeEditButton]);

  return (
    <Card
      title={i18n.t('adminBootis.createCompany.billing')}
      withoutCollapse={editForm}
    >
      <RadioGroup
        className={styles['radio-button']}
        valueChecked={radioBillings ? 1 : 2}
      >
        <RadioButton
          value={1}
          label={i18n.t('adminBootis.createCompany.billingCard.ownBilling')}
          onChange={() => {
            onButtonChange(true);
            
            if(form?.values?.externalId)
              form?.setFieldValue(
                'empresaPagadoraExternalId', 
                form?.values?.externalId
              );
          }}
          disabled={buttonDisabled && editForm}
        />
        <RadioButton
          value={2}
          label={i18n.t('adminBootis.createCompany.billingCard.companyBilling')}
          onChange={() => {
            onButtonChange(false);
            form?.setFieldValue('empresaPagadoraExternalId', undefined);
          }}
          disabled={(!form?.values.conglomeradoExternalId && !editForm) || buttonDisabled}
          tooltip={
            !buttonDisabled && !form?.values.conglomeradoExternalId
              ? i18n.t('adminBootis.createCompany.billingCard.radioTooltip')
              : ''
          }
        />
      </RadioGroup>
      {!radioBillings && (
        <Row>
          <Col flex="34%">
            <InputWithSearch
              name="empresaPagadoraExternalId"
              items={data?.data?.map((x: IListCompanyData) => ({
                id: x.externalId,
                label: x.nomeFantasia,
                avatar: {
                  name: x.nomeFantasia,
                },
              }))}
              label={i18n.t(
                'adminBootis.createCompany.billingCard.companySelect'
              )}
              placeHolder={i18n.t(
                'adminBootis.createCompany.billingCard.companySelectPlaceholder'
              )}
              withoutMarginBottom
              selectedItemFromForm='empresaPagadoraInitialValue'
              disabled={buttonDisabled}
              required
              onScrollEnd={fetchNewPage}
            />
          </Col>
        </Row>
      )}
      {editForm && (
        <FooterButton
          disabled={!!buttonDisabled}
          editLabel={i18n.t('adminBootis.createCompany.billingCard.edit')}
          onChange={(value) => setButtonDisabled(value)}
          loading={isLoading}
          dataTestId="billings-edit-button"
          cancelButtonClick={
            () => handleCancelEdit(form, app, () => setButtonDisabled(!buttonDisabled))
          }
        />
      )}
    </Card>
  );
};
