import { FC } from 'react';
import { Table } from '@/Components';
import { userGroupsColumns } from './user-groups.columns';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { useTranslation } from 'react-i18next';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { PageLoading } from '@/Layouts/PageLoading';
import { useUserGroupListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/userGroups/list';

const UserGroupsList: FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useListStates();

  const { userGroupList, changeUserGroupStatus, deleteUserGroup } =
    useListCallAPI();

  const { getShortcuts, getShortcut } = useUserGroupListShortcuts(t, {});

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      selectedRowsOnTable,
      getShortcut,
      getShortcuts,
    });

  const { onEdit, onDelete } = useListFunctions({
    changeUserGroupStatus,
    deleteUserGroup,
  });

  return (
    <PageLoading loading={userGroupList.isLoading}>
      <Table
        columns={userGroupsColumns(t)}
        headerOptions={headerOptions}
        loading={userGroupList.isLoading}
        hasSelection
        pagination={userGroupList.data}
        fieldStatus="seAtivo"
        onEdit={onEdit}
        onDelete={onDelete}
        onRefetch={userGroupList.refetch}
        rowsOptions={rowOptions}
        editOptions={editTableStatus()}
        translations={{
          editModal: { customEndTitle: t('common.userGroups') },
        }}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        editModal={{ okButtonName: t('common.save') }}
        editStatusModalLoading={changeUserGroupStatus.isLoading}
        keyToSelect="externalId"
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        selectedRowsOnTable={selectedRowsOnTable}
        deleteModal={deleteModal}
        initialRowsSelected={selectedItems}
      />
    </PageLoading>
  );
};

export default UserGroupsList;
