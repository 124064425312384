import { FC } from 'react';
import { Button } from '@/Components';
import { useTranslation } from 'react-i18next';
import { IFChildren } from '@/Components/Form';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';
import { App } from 'antd';

import styles from '../../DetailsForm.module.scss';

interface IFooterButtons {
  isLoadingEditStorageLocation: boolean;
  props: IFChildren;
  onChangeDisabled: (x: boolean) => void;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditStorageLocation,
  props,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const app = App.useApp();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => 
          handleCancelEdit(props, app, () => onChangeDisabled(true))
        }
      />
      <Button
        type="primary"
        children={
          isLoadingEditStorageLocation ? t('common.saving') : t('common.save')
        }
        htmlType="submit"
        loading={isLoadingEditStorageLocation}
      />
    </div>
  );
};
