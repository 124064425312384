import _, { debounce } from 'lodash';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { VALIDATION_CNPJ_COMPANY } from '@/ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';

export const useCheckCnpjAlreadyUsed = () => {
  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: VALIDATION_CNPJ_COMPANY,
    entityApi: CompanyAPI.cnpjCompanyValidation,
  });

  const checkCnpjDuplicationsDebounced: any = debounce(
    async (
      value: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = (await mutateAsync(value)) as boolean;
        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return {
    checkCnpjDuplicationsDebounced,
  };
};
