import React from 'react';
import { useParams } from 'react-router-dom';
import { PageLoading } from '@/Layouts/PageLoading';
import { CompanyDataCardWrapper } from '@/Layouts/CompanyFormLayout/CompanyDataCard';
import { CompanyAdressCard } from '@/Layouts/CompanyFormLayout/CompanyAdressCard';
import { CompanyResponsibleUserCardWrapper } from '@/Layouts/CompanyFormLayout/CompanyResponsibleUserCard/Edit';
import { useCompanyFormStates } from './Hooks/useCompanyFormStates';
import { useCompanyFormMemorizations } from './Hooks/useCompanyFormMemorizations';
import { useCompanyFormCallAPI } from './Hooks/useCompanyFormCallAPI';
import { useEditCompanyShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/companys/details';
import { useTranslation } from 'react-i18next';

export const Create: React.FC = () => {
  const { t } = useTranslation();

  const { radioUser, setRadioUser } = useCompanyFormStates();

  const { externalId } = useParams<{ externalId: string }>();

  const { isEditForm } = useCompanyFormMemorizations({ externalId });

  const { company, isLoading } = useCompanyFormCallAPI({ externalId });

  const { getShortcut } = useEditCompanyShortcuts(t);

  return (
    <PageLoading loading={isLoading}>
      {company && (
        <CompanyDataCardWrapper company={company} getShortcut={getShortcut} />
      )}
      {company && (
        <CompanyAdressCard
          editForm={isEditForm}
          company={company}
          getShortcut={getShortcut}
        />
      )}
      {company && (
        <CompanyResponsibleUserCardWrapper
          company={company}
          radioUser={radioUser}
          onChangeRadioUser={setRadioUser}
          getShortcut={getShortcut}
        />
      )}
    </PageLoading>
  );
};
