import React, { FC } from 'react';
import { IconName } from '../../../../Icon/IconClasses';
import { Color } from '../../../../../Utils/ColorUtils';
import { Icon, Label } from '../../../..';
import Spinner from '../../../../Spinner';
import { Input } from 'antd';

import './TextInputState.override.scss';
import styles from './TextInputState.module.scss';
import { IInputLabel } from '../../../../Inputs/Dropdown';
import { Hotkey } from '@/Components/Shortcuts/Hotkey';

export interface ITextInput {
  type?: 'text' | 'password' | 'number';
  label?: IInputLabel;
  disabled?: boolean;
  placeHolder?: string;

  leftIcon?: IconName;
  rightIcon?: IconName;
  iconColor?: Color;

  isLoading?: boolean;

  success?: boolean;
  error?: boolean;
  className?: string;
  dataTestId?: string;

  onChange?: (search: string) => void;

  hotKeyRightIcon?: boolean;
}

export const TextInputState: FC<ITextInput> = ({
  type,
  label,
  placeHolder,
  iconColor,
  isLoading,
  error,
  success,
  rightIcon,
  leftIcon,
  disabled,
  className,
  dataTestId,
  onChange,
  hotKeyRightIcon,
}) => {
  return (
    <div className={styles['Container']}>
      {label ? (
        <Label
          type={label.typeLabel || 'ui-tiny-semibold'}
          color={label.colorLabel || 'text-50'}
        >
          {label.children}
        </Label>
      ) : null}
      <div
        className={`
              ${styles['input-container']} 
              ${className} 
              ${leftIcon ? styles['with-left-icon'] : null}
              ${
                rightIcon || success || error ? styles['with-right-icon'] : null
              } ${error ? styles['error'] : null}
              ${success ? styles['success'] : null}
							${disabled ? styles['disabled'] : null}`}
      >
        {leftIcon ? (
          isLoading ? (
            <Spinner className={styles['spinner']}></Spinner>
          ) : (
            <Icon
              className={styles['Left-Icon']}
              icon={leftIcon}
              size="M"
              color={iconColor ? iconColor : 'text-50'}
            />
          )
        ) : null}

        <Input
          type={type}
          className={styles['TextInput']}
          onChange={(event) => onChange && onChange(event.target.value)}
          placeholder={placeHolder}
          disabled={disabled}
          data-testid={dataTestId}
        />
        {error ? (
          <Icon
            className={styles['Icon']}
            icon="exclamation"
            size="M"
            color="status-danger-base"
          />
        ) : null}

        {success ? (
          <Icon
            className={styles['Icon']}
            icon="check"
            size="M"
            color="status-success-base"
          />
        ) : null}

        {rightIcon ? (
          <Icon
            className={styles['Icon']}
            icon={rightIcon}
            size="M"
            color={iconColor ? iconColor : 'text-50'}
          />
        ) : null}

        {hotKeyRightIcon && (
          <Hotkey
            hotKey="p"
            className={styles['Hotkey-Icon']}
            width={20}
            height={20}
            fontSize={11}
          />
        )}
      </div>
    </div>
  );
};
