import { FC } from 'react';
import { Button } from '@/Components';
import { useTranslation } from 'react-i18next';
import styles from '../../PharmaceuticalForm.module.scss';
import { IFChildren } from '@/Components/Form';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';
import { App } from 'antd';

interface IFooterButtons {
  isLoadingEditPharmaceuticalForm: boolean;
  onChangeDisabled: (x: boolean) => void;
  form: IFChildren;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditPharmaceuticalForm,
  onChangeDisabled,
  form,
}) => {
  const { t } = useTranslation();
  const app = App.useApp();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => 
          handleCancelEdit(form, app, () => onChangeDisabled(true))
        }
      />
      <Button
        type="primary"
        children={
          isLoadingEditPharmaceuticalForm
            ? t('common.saving')
            : t('common.save')
        }
        htmlType="submit"
        loading={isLoadingEditPharmaceuticalForm}
      />
    </div>
  );
};
