import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { DetailsTab } from '../Tabs/Details';
import { FinanceTab } from '../Tabs/Finance';
import { IncompatibleProductTab } from '../Tabs/IncompatibleProduct';
import { CreateIncompatibleProduct } from '../Tabs/CreateIncompatibleProduct';
import { ProductMessageListTab } from '../Tabs/ProductMessage';
import { IncompatibleProductDetails } from '../Tabs/IncompatibleProductDetails';
import { SynonymProductTab } from '../Tabs/SynonymProduct';
import { CreateSynonym } from '../Tabs/CreateSynonym';
import { ProductMessageTabDetail } from '../Tabs/ProductMessage/Detail';
import { SynonymDetail } from '../Tabs/SynonymDetails';
import { DilutedProduct } from '../Tabs/DilutedProduct';
import { CreateDilutedProduct } from '../Tabs/CreateDilutedProduct';
import { DilutedProductDetails } from '../Tabs/DilutedProductDetails';
import { AssociatedProduct } from '../Tabs/AssociatedProduct';
import { CreateAssociatedProduct } from '../Tabs/CreateAssociatedProduct';
import { AssociatedProductDetails } from '../Tabs/AssociatedProductDetails';
import { StockBalanceTab } from '../Tabs/StockBalance';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { useLocation, useParams } from 'react-router-dom';
import { IUseMemorizations } from './interfaces';
import { IPermissionEditButon } from '@/Components/HorizontalTabs';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useEditMemorizations = ({
  disabledForm,
  setDisabledForm,
  currentProductMessagePage,
  externalId,
  currentTechnicalInformationPage,
  disabledDropdownLinks,
  defineEditPermission,
  defineMessageTooltipPermission,
  setVisibleModal,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const params: Readonly<
    Partial<{
      externalId: string;
      tab: string;
    }>
  > = useParams();
  const location = useLocation();

  const editPermissions = {
    finance: PermissionEnum.Produtos_Produtos_EditarInformacoesFinanceiras,
    details: PermissionEnum.Produtos_Produtos_EditarInformacoes,
    incompatible: PermissionEnum.Estoque_ProdutosIncompativel_EditarDetalhes,
    synonym: PermissionEnum.Estoque_ProdutosSinonimo_EditarDetalhes,
    messageDetail: PermissionEnum.Estoque_MensagemProduto_EditarDetalhes,
    diluted: PermissionEnum.Estoque_ProdutosDiluido_EditarDetalhes,
  };

  const messageTooltipPermissions = {
    finance: 'noPermission.supply.products.editFinancial',
    details: 'noPermission.supply.products.edit',
    incompatible: 'noPermission.supply.products.editIncompatible',
    synonym: 'noPermission.supply.products.editSynonym',
    messageDetail: 'noPermission.supply.messageProduct.edit',
    diluted: 'noPermission.supply.products.editDiluted',
  };

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabFinancesHotkey = getShortcut('alternateTabFinance');
  const alternateTabStockHotkey = getShortcut('alternateTabStock');
  const alternateTabProductMessageHotkey = getShortcut(
    'alternateTabProductMessage'
  );

  const items: any = [
    {
      label: t('common.details'),
      key: 'details',
      children: (
        <DetailsTab
          disabledForm={disabledForm ?? false}
          onDisabledForm={() => setDisabledForm?.(true)}
          toogleDisabled={(x: boolean) => setDisabledForm?.(x)}
        />
      ),
      dataTestId: 'tab-user-details',
      shortCutTooltip: {
        title: alternateTabDetailsHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
    {
      label: t('common.financial'),
      key: 'finance',
      children: (
        <FinanceTab
          disabledForm={disabledForm ?? false}
          onDisabledForm={() => setDisabledForm?.(true)}
          toogleDisabled={(x: boolean) => setDisabledForm?.(x)}
        />
      ),
      dataTestId: 'tab-user-finance',
      shortCutTooltip: {
        title: alternateTabFinancesHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
    {
      label: t('common.inventory'),
      key: 'inventory',
      children: <StockBalanceTab />,
      dataTestId: 'tab-user-stock',
      shortCutTooltip: {
        title: alternateTabStockHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
    {
      label: t('common.tabProductMessage'),
      key: currentProductMessagePage,
      tabPermission: PermissionEnum.Estoque_ProdutosMensagem_Visualizar,
      permissionTooltip: 'noPermission.supply.messageProduct.linkTabProduct',
      children:
        currentProductMessagePage === 'messageDetail' ? (
          <ProductMessageTabDetail
            productExternalId={externalId ?? ''}
            disabledForm={disabledForm ?? false}
            toogleDisabled={(x: boolean) => setDisabledForm?.(x)}
          />
        ) : (
          <ProductMessageListTab />
        ),
      dataTestId: 'tab-user-product-message',
      shortCutTooltip: {
        title: alternateTabProductMessageHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
    {
      label: t('common.technicalInformation'),
      key: currentTechnicalInformationPage,

      children:
        currentTechnicalInformationPage === 'createIncompatible' ? (
          <CreateIncompatibleProduct />
        ) : currentTechnicalInformationPage === 'incompatibleDetails' ? (
          <IncompatibleProductDetails
            disabledForm={disabledForm ?? false}
            disableForm={() => setDisabledForm?.(true)}
          />
        ) : currentTechnicalInformationPage === 'incompatible' ? (
          <IncompatibleProductTab />
        ) : currentTechnicalInformationPage === 'synonym' ? (
          <SynonymProductTab />
        ) : currentTechnicalInformationPage === 'createSynonym' ? (
          <CreateSynonym />
        ) : currentTechnicalInformationPage === 'synonymDetails' ? (
          <SynonymDetail
            disabledForm={disabledForm ?? false}
            disableForm={() => setDisabledForm?.(true)}
          />
        ) : currentTechnicalInformationPage === 'diluted' ? (
          <DilutedProduct />
        ) : currentTechnicalInformationPage === 'createDiluted' ? (
          <CreateDilutedProduct />
        ) : currentTechnicalInformationPage === 'dilutedDetails' ? (
          <DilutedProductDetails
            disabledForm={disabledForm ?? false}
            disableForm={() => setDisabledForm?.(true)}
          />
        ) : currentTechnicalInformationPage === 'associated' ? (
          <AssociatedProduct />
        ) : currentTechnicalInformationPage === 'createAssociated' ? (
          <CreateAssociatedProduct />
        ) : currentTechnicalInformationPage === 'associatedDetails' ? (
          <AssociatedProductDetails
            disabledForm={disabledForm ?? false}
            disableForm={() => setDisabledForm?.(true)}
          />
        ) : (
          ''
        ),
      dropdownLinks: [
        {
          label: t('products.technicalInformation.associated'),
          href: `/inventory/products/${externalId}/associated`,
          disabled: !disabledDropdownLinks?.associated,
          hotKeyMultipleIcons: ['shift', '5'],
        },
        {
          label: t('products.technicalInformation.diluted'),
          href: `/inventory/products/${externalId}/diluted`,
          disabled: !disabledDropdownLinks?.diluted,
          permission: PermissionEnum.Estoque_ProdutosDiluido_Visualizar,
          permissionTooltip: t('noPermission.supply.products.viewDiluted'),
          hotKeyMultipleIcons: ['shift', '6'],
        },
        {
          label: t('products.technicalInformation.incompatible'),
          href: `/inventory/products/${externalId}/incompatible`,
          permission: PermissionEnum.Estoque_ProdutosIncompativel_Visualizar,
          permissionTooltip: t('noPermission.supply.products.viewIncompatible'),
          disabled: !disabledDropdownLinks?.incompatible,
          hotKeyMultipleIcons: ['shift', '7'],
        },
        {
          label: t('products.technicalInformation.synonym'),
          href: `/inventory/products/${externalId}/synonym`,
          permission: PermissionEnum.Estoque_ProdutosSinonimo_Visualizar,
          permissionTooltip: t('noPermission.supply.products.viewSynonym'),
          disabled: !disabledDropdownLinks?.synonym,
          hotKeyMultipleIcons: ['shift', '8'],
        },
      ],
    },
  ];

  const leftButton: IPermissionEditButon | undefined = {
    onClick: () => history.push(`/inventory/products/details`),
    leftIcon: 'Box',
    children: t('supply.lot.details.goToProducts'),
  };

  const editProductHotkey = getShortcut('editProduct');
  const editProductFinanceHotkey = getShortcut('editProductFinance');

  const rightButton: IPermissionEditButon | undefined = {
    onClick: () => setDisabledForm?.(false),
    isVisible:
      disabledForm &&
      (location.pathname.includes('details') ||
        location.pathname.includes('finance') ||
        location.pathname.includes('create') ||
        location.pathname.includes('messageDetail') ||
        location.pathname.includes('Details')),
    codePermissionEdit: defineEditPermission?.(params.tab || ''),
    messageTooltipPermission: defineMessageTooltipPermission?.(
      params.tab || ''
    ),
    dataTestId: 'edit-product-button',
    hotkeyTooltip: {
      title:
        params.tab === 'finance'
          ? editProductFinanceHotkey.tooltip
          : editProductHotkey.tooltip,
    },
  };

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: t('common.delete'),
      icon: 'trash',
      onClick: () => setVisibleModal && setVisibleModal(true),
      permission: {
        permission: 3007,
        tooltip: 'noPermission.supply.products.delete',
      },
      dataTestId: 'dropdown-option-delete',
      key: 'backspace',
    },
  ];

  return {
    editPermissions,
    messageTooltipPermissions,
    items,
    leftButton,
    rightButton,
    dropdownItems,
  };
};
