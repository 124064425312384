export const FETCH_GET_SALES_ORDER = '[SALES_ORDER] FETCH_GET_SALES_ORDER';
export const FETCH_GET_HISTORIC_SALES_ORDER =
  '[SALES_ORDER] FETCH_GET_HISTORIC_SALES_ORDER';

export const FETCH_LIST_SALES_ORDER = '[SALES_ORDER] FETCH_LIST_SALES_ORDER';

export const CREATE_SALES_ORDER = '[SALES_ORDER] CREATE_SALES_ORDER';
export const CREATE_FINISHED_PRODUCT = '[SALES_ORDER] CREATE_FINISHED_PRODUCT';
export const DELETE_SALES_ORDER = '[SALES_ORDER] DELETE_SALES_ORDER';
export const EDIT_FINISHED_PRODUCT = '[SALES_ORDER] EDIT_FINISHED_PRODUCT';

export const CHANGE_SELECTED_FINISHED_PRODUCT =
  '[SALES_ORDER] CHANGE_SELECTED_FINISHED_PRODUCT';
export const CHANGE_ITEM_AMOUNT = '[SALES_ORDER] CHANGE_ITEM_AMOUNT';
export const PATCH_SALES_ORDER = '[SALES_ORDER] PATCH_SALES_ORDER';
export const UPDATE_DELIVERY_SALES_ORDER =
  '[SALES_ORDER] UPDATE_DELIVERY_SALES_ORDER';
export const APPROVE_SALES_ORDER = '[SALES_ORDER] APPROVE_SALES_ORDER';
export const REPROVE_SALES_ORDER = '[SALES_ORDER] REPROVE_SALES_ORDER';
export const CANCEL_SALES_ORDER = '[SALES_ORDER] CANCEL_SALES_ORDER';
export const REVERSE_SALES_ORDER = '[SALES_ORDER] REVERSE_SALES_ORDER';

export const CREATE_MANIPULATED_RECIPE =
  '[SALES_ORDER] CREATE_MANIPULATED_RECIPE';
