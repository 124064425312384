import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { InventoryAPI } from "@/Data/API/Supply/InventoryApit";
import { 
  CANCEL_RELEASE_INVENTORY 
} from "@/ControllerApiHook/UniqIds/Inventory/Inventory";
import { messageErrorToast } from "@/Utils/MessageErrorToast";

export const useReleaseCallAPI = () => {
  const cancelRelease = useControllerMutateApiHook({
    uniqId: CANCEL_RELEASE_INVENTORY,
    entityApi: InventoryAPI.cancelInventoryRelease,
    options: {
      onError: (x) => messageErrorToast(x),
    },
  });

  return {
    cancelRelease
  };
};
