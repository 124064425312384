import { useState } from 'react';

export const useCreateStates = () => {
  const [personType, setPersonType] = useState(0);

  return {
    personType,
    setPersonType,
  };
};
