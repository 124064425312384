import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { IHeaderTableOptions } from '@/Components/Table/components/HeaderTable';
import { deleteModalBody, deleteModalTitle } from '../UserForm.helpers';
import { Placements } from '@/Components/Tooltip';

export const useUserFormMemorizations = ({
  userGroup,
  selectedRowsOnTable,
  setVisible,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addUserGroupUsersHotkey = getShortcut('addUsersToGroup');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteUserGroupUsersHotkey = getShortcut('deleteSelectedUsers');

  const headerOptions: IHeaderTableOptions | undefined = {
    hasSearch: true,
    nameKey: 'nome',
    searchPlaceholder: t('admin.editUserGroups.userForm.searchPlaceholder'),
    newButton: {
      dataTestId: 'add-new-user-in-group',
      label: t('admin.editUserGroups.userForm.add'),
      onClick: () => setVisible(true),
      tooltip: {
        title: addUserGroupUsersHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    deleteButton: {
      icon: 'remove-account',
      label: t('common.remove'),
      tooltip: {
        title: deleteUserGroupUsersHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'delete-selected-users',
    },
  };

  const deleteModal = useMemo(
    () =>
      selectedRowsOnTable && {
        title: deleteModalTitle(selectedRowsOnTable, t),
        body: userGroup && deleteModalBody(selectedRowsOnTable, t, userGroup),
        buttons: {
          okButtonColor: 'status-danger-base',
        },
        okButtonName: 'common.remove',
      },
    [selectedRowsOnTable, userGroup, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
