import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { IUseCheckNameDuplicationCallAPI } from "./interfaces";
import { debounce } from "lodash";
import { THERE_IS_STANDARD_FORMULA_TYPE } from "@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys";
import { StandardFormulaAPI } from "@/Data/API/Production/StandardFormula";

export const useCheckNameDuplicationCallAPI = ({
  initialProduct
}: IUseCheckNameDuplicationCallAPI) => {
  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: THERE_IS_STANDARD_FORMULA_TYPE,
    entityApi: StandardFormulaAPI.isStandardFormulaNameAlreadyRegistred,
  });

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      produtoExternalId: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = 
          initialProduct !== produtoExternalId
          ? (await mutateAsync(produtoExternalId) as boolean)
          : true;

        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return {
    checkNameDuplicationsDebounced
  };
};