import {
  Button,
  Dropdown,
  Icon,
  InputWithSearch,
  Modal,
  NumberInput,
} from '@/Components';
import { IListClientData } from '@/Data/Interfaces/response/Person/Client';
import { IListPharmaceuticalFormDropdownData } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { ISimpleListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { MaskForCNPJ, MaskForCPF } from '@/Utils/MasksUtils';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { DoseModalBody } from './Modals/doseModalBody';
import { useTranslation } from 'react-i18next';
import { FormikContextType, useFormikContext } from 'formik';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { useHeaderRecipeDescriptionCallAPI } from './Hooks/useHeaderRecipeDescriptionCallAPI';
import { useHeaderRecipeDescriptionStates } from './Hooks/useHeaderRecipeDescriptionStates';

import styles from './HeaderRecipeDescription.module.scss';

export const HeaderRecipeDescription: FC = () => {
  const form: FormikContextType<IPostManipulatedRecipeRequest> =
    useFormikContext();

  const { t } = useTranslation();

  const {
    clientList,
    clientListLoading,
    fetchNewClientPage,
    refetchClientList,
    fetchNewPagePharmaceuticalList,
    pharmaceuticalList,
    pharmaceuticalListLoading,
    prescriberList,
  } = useHeaderRecipeDescriptionCallAPI();

  const {
    doseModal,
    setDoseModal,
    doseValues,
    setDoseValues,
    pharmaceuticalCalculateTypeSelected,
    setPharmaceuticalCalculateTypeSelected,
    pharmaceuticalSelected,
    setPharmaceuticalSelected,
  } = useHeaderRecipeDescriptionStates();

  const formatDoseAndTotalDose = (dose: number) => {
    const formattedDose = Number.isInteger(dose)
      ? dose.toString()
      : dose.toFixed(5);

    return formattedDose.length > 5
      ? `${formattedDose.slice(0, 5)}...`
      : formattedDose;
  };

  return (
    <Row gutter={[12, 18]} style={{ marginBottom: 12 }}>
      <Col span={12}>
        <InputWithSearch
          label={t(
            'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.patient'
          )}
          items={clientList?.data?.map((x: IListClientData) => ({
            avatar: { name: x.nome },
            id: x.externalId,
            label: x.nome,
            subLabel: `Código: ${x.codigo} | ${
              x.cpf
                ? `CPF: ${MaskForCPF(x.cpf)}`
                : `CNPJ: ${MaskForCNPJ(x.cnpj)}`
            }`,
          }))}
          selectedOptionRender="withoutSubLabel"
          name="pacienteExternalId"
          withoutSearchIcon
          placeholderWithAvatar
          isLoading={clientListLoading}
          onScrollEnd={fetchNewClientPage}
          required
          onSearch={(search) =>
            refetchClientList({
              search: search,
            })
          }
          withoutMarginBottom
          selectedItemFromForm="pacienteExternalIdInitialValue"
        />
      </Col>
      <Col span={12}>
        <InputWithSearch
          name="prescritorExternalId"
          label={t(
            'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.prescriber'
          )}
          placeHolder={t(
            'saleOrder.editOrder.SaleOrder.addProductModal.prescriberPlaceholder'
          )}
          items={prescriberList?.data?.data?.map(
            (x: ISimpleListPrescribersData) => ({
              id: x.externalId,
              label: x.nomeCompleto,
              subLabel: `${x.siglaRegistro}/${x.siglaRegistro} ${x.codigoRegistro}`,
            })
          )}
          onScrollEnd={prescriberList.fetchNewPage}
          isLoading={prescriberList.isLoading}
          onSearch={(search) =>
            prescriberList.refetch({
              search: search,
            })
          }
          withoutMarginBottom
        />
      </Col>
      <Col flex="36%">
        <Dropdown
          name="formaFarmaceuticaExternalId"
          label={t(
            'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.pharmaceuticalForm'
          )}
          required
          items={
            pharmaceuticalList?.data?.map(
              (x: IListPharmaceuticalFormDropdownData) => ({
                id: x.externalId,
                label: x.descricao,
                allItem: x,
              })
            ) || undefined
          }
          isLoading={pharmaceuticalListLoading}
          onScrollEnd={fetchNewPagePharmaceuticalList}
          withoutMarginBottom
          onChange={(__, _, item) => {
            setPharmaceuticalSelected(item?.apresentacao);
            setPharmaceuticalCalculateTypeSelected(item?.tipoCalculo);
            form.setFieldValue('dose', undefined);
          }}
        />
      </Col>
      <div className={styles['divider']} />
      <Col flex="17%">
        <NumberInput
          name="quantidadeReceita"
          label={t('saleOrder.editOrder.SaleOrder.addManipulatedRecipe.amount')}
          required
          placeHolder="0"
          rightIcon={{
            titleString: pharmaceuticalSelected,
          }}
          withoutMarginBottom
          value={form.values.quantidadeDose}
          subText={
            form.values.quantidadeRepetir
              ? `Dose: ${formatDoseAndTotalDose(
                  form.values.quantidadeReceita / form.values.quantidadeRepetir
                )} ${pharmaceuticalSelected}`
              : undefined
          }
          onChange={(value) =>
            form.setFieldValue(
              'total',
              (value || 0) * (form.values.quantidadeRepetir || 1)
            )
          }
        />
      </Col>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            height: '100%',
          }}
        >
          <Icon
            icon="close-x"
            size="S"
            color="text-300"
            style={{
              marginBottom: !form.values.quantidadeDose ? '12px' : '33px',
            }}
          />
        </div>
      </div>
      <Col flex="14%">
        <NumberInput
          name="quantidadeRepetir"
          label={t('saleOrder.editOrder.SaleOrder.addManipulatedRecipe.repeat')}
          placeHolder="0"
          withoutMarginBottom
          withTooltip={{
            icon: 'question-mark',
            title: t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.repeatTooltip'
            ),
          }}
          onChange={(value) =>
            form.setFieldValue(
              'total',
              (form.values.quantidadeReceita || 0) * (value || 1)
            )
          }
        />
      </Col>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            height: '100%',
          }}
        >
          <Icon
            icon="Equal"
            size="S"
            color="text-300"
            style={{
              marginBottom: !form.values.quantidadeDose ? '12px' : '33px',
            }}
          />
        </div>
      </div>
      <Col flex="17%">
        <NumberInput
          name="total"
          label={t('saleOrder.editOrder.SaleOrder.addManipulatedRecipe.total')}
          placeHolder="0"
          disabled
          rightIcon={{
            titleString: pharmaceuticalSelected,
          }}
          withoutMarginBottom
          subText={
            form.values.quantidadeDose
              ? `${formatDoseAndTotalDose(
                  form.values.quantidadeDose * form.values.quantidadeRepetir
                )} doses`
              : undefined
          }
        />
      </Col>
      <Col>
        {pharmaceuticalCalculateTypeSelected === 2 ? (
          <Button
            type="secondary"
            children={t(
              'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.dose'
            )}
            style={{ marginTop: 20 }}
            onClick={() => setDoseModal(true)}
          />
        ) : (
          <></>
        )}
      </Col>
      <Modal
        title={t(
          'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.insertDose'
        )}
        body={
          <DoseModalBody
            pharmaceuticalSelected={pharmaceuticalSelected}
            doseModalValues={doseValues}
            form={form}
            setDoseModalValues={setDoseValues}
          />
        }
        visible={doseModal}
        onCancelClick={() => {
          setDoseValues((prevState) => ({
            ...prevState,
            dose: undefined,
          }));
          form.setFieldValue('quantidadeDose', undefined);
          setDoseModal(false);
        }}
        onClose={() => {
          setDoseValues((prevState) => ({
            ...prevState,
            dose: undefined,
          }));
          form.setFieldValue('quantidadeDose', undefined);
          setDoseModal(false);
        }}
        onOkClick={() => {
          form.setFieldValue('quantidadeReceita', doseValues.amount);
          form.setFieldValue(
            'quantidadeDose',
            doseValues.dose && doseValues.dose * form.values.quantidadeRepetir
          );
          setDoseModal(false);
        }}
      />
    </Row>
  );
};
