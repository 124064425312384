import { FormikContextType, useFormikContext } from 'formik';
import { IUseFunctions } from './interfaces';

export const useStorageLocationRowFunctions = ({
  refetch,
  onOriginChange,
  setChangeStorageLocationModal,
  setOrigin,
  setDestiny,
  destiny,
  onDestinyChange,
}: IUseFunctions) => {
  const form: FormikContextType<any> = useFormikContext();

  const onChangeSupplyPlaceLocationFrom = (value: string) => {
    onOriginChange(value);
    if (
      !!origin &&
      form.values.transfers.length >= 1 &&
      destiny !== undefined
    ) {
      setChangeStorageLocationModal(true);
    } else {
      setOrigin(value);
    }
  };

  const onChangeSupplyPlaceLocationTo = (value: string) => {
    setDestiny(value);
    onDestinyChange(value);
  };

  const onSearch = (search: string | undefined) => {
    refetch({
      search: search,
    });
  };

  const actionOnFocus = () => {
    refetch({
      search: '',
    });
  };

  return {
    onSearch,
    actionOnFocus,
    onChangeSupplyPlaceLocationFrom,
    onChangeSupplyPlaceLocationTo,
  };
};
