import { useEffect, Fragment } from 'react';
import { FormikContextType, FormikValues } from 'formik';

export interface FocusErrorProps {
  formik: Pick<
    FormikContextType<FormikValues>,
    'isSubmitting' | 'isValidating' | 'errors'
  >;
  focusDelay?: number;
  onFocus?: () => void;
  orderedErrors?: string[];
}

export function FocusError({
  formik: { isSubmitting, isValidating, errors },
  focusDelay = 100,
  onFocus,
  orderedErrors,
}: FocusErrorProps) {
  useEffect(() => {
    if ((isSubmitting && !isValidating) || (!isSubmitting && !isValidating)) {
      let errorNames = Object.keys(errors).flatMap((key) => {
        const errorValue = errors[key];

        if (Array.isArray(errorValue)) {
          return errorValue.flatMap((error, index) => {
            if (typeof error === 'object' && error !== null) {
              return Object.keys(error).map(
                (nestedKey) => `${key}.${index}.${nestedKey}`
              );
            }
            return [];
          });
        } else if (typeof errorValue === 'object' && errorValue !== null) {
          return Object.keys(errorValue).map(
            (nestedKey) => `${key}.${nestedKey}`
          );
        } else {
          return [key];
        }
      });

      if (orderedErrors) {
        errorNames = orderedErrors.filter((error) =>
          errorNames.includes(error)
        );
      }

      if (errorNames.length && typeof document !== 'undefined') {
        const errorElement = errorNames
          .map((errorKey) => {
            return (
              document.querySelector(`[name="${errorKey}"]`) ||
              document.getElementById(errorKey)
            );
          })
          .find((element) => element !== null) as HTMLElement | null;

        if (errorElement) {
          setTimeout(() => {
            if (errorElement.classList.contains('ant-select')) {
              const selectInput = errorElement.querySelector('input');
              if (selectInput) {
                (selectInput as HTMLElement).focus();
              }
            } else {
              errorElement.focus();
            }

            if (onFocus) {
              onFocus();
            }
          }, focusDelay);
        }
      }
    }
  }, [isSubmitting, isValidating, errors, focusDelay, onFocus, orderedErrors]);

  return <Fragment />;
}
