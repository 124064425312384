import { useState } from 'react';

export const useHeaderRecipeDescriptionStates = () => {
  const [pharmaceuticalSelected, setPharmaceuticalSelected] = useState<
    string | undefined
  >(undefined);

  const [
    pharmaceuticalCalculateTypeSelected,
    setPharmaceuticalCalculateTypeSelected,
  ] = useState<number | undefined>(undefined);

  const [doseValues, setDoseValues] = useState<{
    amount?: number | undefined;
    dose?: number | undefined;
    totalDose?: number | undefined;
  }>({});
  const [doseModal, setDoseModal] = useState(false);

  return {
    pharmaceuticalSelected,
    setPharmaceuticalSelected,

    pharmaceuticalCalculateTypeSelected,
    setPharmaceuticalCalculateTypeSelected,

    doseValues,
    setDoseValues,

    doseModal,
    setDoseModal,
  };
};
