import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';
import { cpf } from 'cpf-cnpj-validator';
export const CreatePhysicalPersonSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) =>
  yup.object().shape({
    nome: yup
      .string()
      .nullable()
      .required(i18n.t(`client.error.${'requiredName'}`)),
    cpf: yup
      .string()
      .nullable()
      .required(i18n.t('common.requiredCpf'))
      .test(
        'is-valid-cpf',
        i18n.t('purchasing.provider.create.errors.requiredCpf'),
        (value) => (value ? cpf.isValid(value) : false)
      )
      .test(
        'checkDuplications',
        t('purchasing.provider.create.errors.cpfAlreadyUsed'),
        (value) => {
          return new Promise((resolve) => {
            validateName(value, (success) => {
              resolve(success);
            });
          });
        }
      ),
  });
