import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HorizontalTabs } from '@/Components';
import { DeleteProductModal } from './Modal/DeleteModal';
import { PageLoading } from '@/Layouts/PageLoading';
import { useLocation, useParams } from 'react-router-dom';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { useEditMemorizations } from './Hooks/useEditMemorizations';
import { useProductDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/products/details';

export const EditProduct: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { externalId } = useParams<{
    externalId: string;
  }>();
  const location = useLocation();

  const { getShortcut } = useProductDetailsShortcuts(t, {});

  const { editPermissions, messageTooltipPermissions } = useEditMemorizations({
    getShortcut,
  });

  const {
    disabledForm,
    setDisabledForm,
    visibleModal,
    setVisibleModal,
    currentTechnicalInformationPage,
    setCurrentTechnicalInformationPage,
    currentProductMessagePage,
    setCurrentProductMessagePage,
  } = useEditStates();

  const { data, isLoading } = useEditCallAPI({ externalId });

  const {
    disabledDropdownLinks,
    defineEditPermission,
    defineMessageTooltipPermission,
  } = useEditFunctions({
    data,
    editPermissions,
    messageTooltipPermissions,
    setVisibleModal,
  });

  const { items, leftButton, rightButton, dropdownItems } =
    useEditMemorizations({
      disabledForm,
      setDisabledForm,
      currentProductMessagePage,
      externalId,
      currentTechnicalInformationPage,
      disabledDropdownLinks,
      defineEditPermission,
      defineMessageTooltipPermission,
      setVisibleModal,
      getShortcut,
    });

  useEffect(() => {
    if (location.pathname.includes('createIncompatible')) {
      setCurrentTechnicalInformationPage('createIncompatible');
    } else if (location.pathname.includes('incompatibleDetails')) {
      setCurrentTechnicalInformationPage('incompatibleDetails');
    } else if (location.pathname.includes('incompatible')) {
      setCurrentTechnicalInformationPage('incompatible');
    } else if (location.pathname.includes('createSynonym')) {
      setCurrentTechnicalInformationPage('createSynonym');
    } else if (location.pathname.includes('synonymDetails')) {
      setCurrentTechnicalInformationPage('synonymDetails');
    } else if (location.pathname.includes('synonym')) {
      setCurrentTechnicalInformationPage('synonym');
    } else if (location.pathname.includes('createDiluted')) {
      setCurrentTechnicalInformationPage('createDiluted');
    } else if (location.pathname.includes('dilutedDetails')) {
      setCurrentTechnicalInformationPage('dilutedDetails');
    } else if (location.pathname.includes('diluted')) {
      setCurrentTechnicalInformationPage('diluted');
    } else if (location.pathname.includes('createAssociated')) {
      setCurrentTechnicalInformationPage('createAssociated');
    } else if (location.pathname.includes('associatedDetails')) {
      setCurrentTechnicalInformationPage('associatedDetails');
    } else if (location.pathname.includes('associated')) {
      setCurrentTechnicalInformationPage('associated');
    }

    if (location.pathname.includes('messageDetail')) {
      setCurrentProductMessagePage('messageDetail');
    } else {
      setCurrentProductMessagePage('product-messages');
    }
  }, [location.pathname]);

  const editMoreActionsHotkey = getShortcut('editMoreActions');

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        leftButton={leftButton}
        rightButton={rightButton}
        moreActionsButton={{
          title: editMoreActionsHotkey.tooltip,
        }}
        dataTestIdDropdownButton="more-actions-button"
        dropdownItens={dropdownItems}
      />
      <DeleteProductModal
        dispatch={dispatch}
        product={data || undefined}
        visibleModal={visibleModal}
        changeVisibleModal={setVisibleModal}
      />
    </PageLoading>
  );
};
