import { useCallback } from "react";
import { 
  IEditSpecialtieRequest 
} from "@/Data/Interfaces/request/Specialties/ISpecialtiesRequest";
import { IUseFunctions } from "./interfaces";
import { IFChildren } from "@/Components/Form";
import { history } from '@/Store';
import { handleCancelEdit } from "@/Utils/BehaviorUtils";
import { App } from "antd";

export const useDetailsFunctions = ({
  editSpecialtie,
  setIsBackModalOpen,
  toogleDisabled,
  disabledForm
}: IUseFunctions) => {
  const app = App.useApp();

  const onSubmit = useCallback(async (values: IEditSpecialtieRequest) =>
    await editSpecialtie.mutateAsync(values)
  , [editSpecialtie]);

  const onClick = (props: IFChildren) => 
    handleCancelEdit(props, app, () => toogleDisabled(!disabledForm));

  const onOkClick = () => history.push('/sales/expertise');

  const onCancelClick = () => setIsBackModalOpen(false);

  const onClose = () => setIsBackModalOpen(false);

  return {
    onSubmit,
    onClick,
    onOkClick,
    onCancelClick,
    onClose
  };
};