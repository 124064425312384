import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../../../../../../Components/Modal';
import { Footer as ModalFooter } from '../../../../../../../Components/Modal/Components/Footer';
import {
  ICommonError,
  IGlobalModal,
} from '../../../../../../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Icon, Text } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { DeleteModalOnTable } from '../../../../../../../Utils/DeleteModalUtils';
import {
  formatInvoiceIdentifier,
  formatSerialNumber,
} from '../../utils/functions';

const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  const formattedRows = content.map((row) => ({
    ...row,
    identifier: `${formatInvoiceIdentifier(row)} (${row.nomeFornecedor})`,
  }));

  return (
    <>
      <BodyModal
        body={
          content?.length === 1 ? (
            <div>
              <Text type="ui-tiny-content">
                <ReactMarkdown>
                  {t('purchasing.invoice.modal.cannotDelete.subTitle', {
                    fornecedor: `**${content[0].nomeFornecedor}**`,
                    numero: `**${formatSerialNumber(content[0].numero, 9)}**`,
                    serie: `**${formatSerialNumber(content[0].serie, 3)}**`,
                  })}
                </ReactMarkdown>
              </Text>

              <Text type="ui-tiny-content" style={{ marginTop: 10 }}>
                {t('purchasing.invoice.modal.cannotDelete.description')}
              </Text>
            </div>
          ) : (
            DeleteModalOnTable(
              formattedRows,
              t('purchasing.invoice.modal.cannotDelete.batchSubTitle'),
              'identifier',
              t('common.invoices'),
              undefined,
              undefined,
              'auto',
              t('purchasing.invoice.modal.cannotDelete.batchDescription')
            )
          )
        }
        style={{ padding: '20px' }}
      />
    </>
  );
};

const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      {content?.length === 1
        ? t('purchasing.invoice.modal.cannotDelete.title')
        : t('purchasing.invoice.modal.cannotDelete.batchTitle', {
            notas: content?.length,
          })}
    </>
  );
};

const Footer: FC<{
  params: { loteExternalId: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();

  return (
    <ModalFooter
      onCancelClick={instance.destroy}
      cancelButtonName={t('common.close')}
      withoutOkButton
    />
  );
};

export const CannotDeleteModal: (
  error: ICommonError,
  params: { loteExternalId: string[] },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  closeIcon: <Icon icon="close-x" color="text-400" size="XXL" />,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer params={params} instance={instance} />,
});
