import {
  BootysShortcuts,
  defaultShortcuts,
  DefaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'user-group-users';

export interface UserGroupUsersTabCallbacks extends DefaultShortcuts {
  selectAllCallback: () => void;
}

const shortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: UserGroupUsersTabCallbacks
): BootysShortcuts[] => [
  ...defaultShortcuts(t, callbacks),
  {
    id: 'addUsersToGroup',
    name: t('admin.editUserGroups.shortcuts.addUserToGroup'),
    keys: 'n',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="add-new-user-in-group"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'selectAll',
    name: t('admin.userGroupList.shortcuts.selectAll'),
    keys: 'alt+x',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="table-select-all"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'search',
    name: t('admin.userGroupList.shortcuts.search'),
    keys: 'p',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="table-search"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.focus();
      }
    },
  },
  {
    id: 'deleteSelectedUsers',
    name: t('admin.editUserGroups.shortcuts.removeSelectedUsers'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="delete-selected-users"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useUserGroupUsersShortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: UserGroupUsersTabCallbacks
) => {
  const pageShortcuts = shortcuts(t, callbacks);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
