import { FormikProps, FormikValues } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form } from '@/Components';
import {
  CollapseList,
  getPermissionInitialValues,
} from '@/Components/CollapseList';
import { IUserGroup } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { RequiredPermissionModal } from '../../Modals/RequiredPermissionModal';
import { usePermissionFormFunctions } from './Hooks/usePermissionFormFunctions';
import { usePermissionFormMemorizations } from './Hooks/usePèrmissionFormMemorizations';
import { useUserGroupPermissionsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/userGroups/permissions';

export const PermissionForm: FC<{ userGroup?: IUserGroup }> = ({
  userGroup,
}) => {
  const { t } = useTranslation();
  const { onPermissionChange } = usePermissionFormFunctions(userGroup);
  const { modules } = usePermissionFormMemorizations(userGroup);

  const { getShortcut } = useUserGroupPermissionsShortcuts(t, {});

  const denyPermissionHotkeyTooltip = getShortcut('denyPermission');
  const allowPermissionHotkeyTooltip = getShortcut('allowPermission');

  return (
    <Form initialValues={getPermissionInitialValues(modules || [])}>
      {(props: FormikProps<FormikValues>) => {
        return (
          <>
            <Card
              title={t('admin.editUserGroups.tab.permissions')}
              status="default"
              withoutCollapse={true}
            >
              <CollapseList
                rows={modules || []}
                onChange={onPermissionChange(props)}
                denyPermissionHotkey={denyPermissionHotkeyTooltip}
                allowPermissionHotkey={allowPermissionHotkeyTooltip}
              />
            </Card>

            <RequiredPermissionModal />
          </>
        );
      }}
    </Form>
  );
};
