import { IFChildren } from '@/Components/Form';
import { Button } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';
import { App } from 'antd';

interface IFooterButton {
  form: IFChildren;
  disabledForm: boolean;
  onDisableEditButton: () => void;
  editLoss: any;
}

export const FooterButton = ({
  form,
  onDisableEditButton,
  disabledForm,
  editLoss,
}: IFooterButton) => {
  const { t } = useTranslation();
  const app = App.useApp();

  return (
    <>
      {!disabledForm && (
        <div style={{ display: 'flex' }}>
          <Button
            style={{ marginRight: '14px' }}
            type="secondary"
            children={t('common.cancel')}
            onClick={() => 
              handleCancelEdit(form, app, onDisableEditButton)
            }
          />
          <Button
            type="primary"
            children={t('common.save')}
            htmlType="submit"
            loading={editLoss.isLoading}
            disabled={!form.values.lossDate || !form.values.lossReason}
          />
        </div>
      )}
    </>
  );
};
