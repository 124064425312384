import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { Timezones } from '../Static/ts/timezones';
import StorageUtils from './StorageUtils';
import { Languages } from '@/Components/i18n';
import _ from 'lodash';

export type DatePatternType = 'dd/mm/yyyy' | 'mm/dd/yyyy' | string;

class DateUtils {
  /**
   * Returns all timezones in the planet
   *
   * @returns ITimezone[]
   */
  static getAllTimezones = () => Timezones;

  /**
   * Returns the current time
   * If timezone is used, it'll return the time of now in this timezone.
   *
   * Sample:
   * Imagine that you are on Brazil and its 9AM (UTC-3)
   * If you use this function without a timezone it'll return 9AM BUT if you pass 'America/Los_Angeles' (UTC-8) as timezone it'll return 4AM
   *
   * @param timezone timezone string like 'America/Sao_Paulo'
   * @returns DateTime
   */
  static now = (timezone?: string) => {
    const now = DateTime.now();

    if (!isNil(timezone)) {
      return now.setZone(timezone);
    }

    return now;
  };

  /**
   * Format a date in whatever pattern that you desire.
   *
   * Use this url as cheatsheet to build your own patterns:
   * https://moment.github.io/luxon/#/formatting?id=table-of-tokens
   *
   * @param date A string that represents a date (the string needs to be a valid date)
   * @param format A pattern to convert the string in
   * @returns Date string
   */
  static format = (date: string, format: DatePatternType) =>
    DateTime.fromISO(date).toFormat(format);

  /**
   * Returns only a time of a date.
   *
   * Note that will return military date (HH:mm)
   *
   * @param date A string that represents a date (the string needs to be a valid date)
   * @returns Date string
   */
  static toTime = (date: string) => DateTime.fromISO(date).toFormat('HH:mm');

  /**
   * Returns the current date pattern for the logged user
   *
   * @returns Date Paterrn
   */
  static getCurrentDatePattern = (): DatePatternType | undefined => {
    const currentPreferences = StorageUtils.getPreference();
    return currentPreferences?.padraoData;
  };

  /**
   * Convert the date in a specific pattern.
   *
   * Note that the pattern is not required, if the pattern is not present,
   * we'll use the current user date pattern
   *
   * @param date A string that represents a date (the string needs to be a valid date)
   * @param datePattern The pattern that you want to convert the date into (NOT REQUIRED)
   * @returns Date string
   */
  static convertDate = (date?: string, datePattern?: string) => {
    if (!datePattern) {
      datePattern = DateUtils.getCurrentDatePattern();
    }

    if (datePattern && date) {
      return DateTime.fromISO(date).toFormat(datePattern);
    }
    return date;
  };

  /**
   * Converts a string like 'dd/mm/yyyy', for example, to date format..
   *
   * @param date A string that represents a date (the string needs to be a valid date)
   * @param datePattern The pattern that you want to convert the date into (NOT REQUIRED)
   */
  static convertStringToDate = (date?: string, datePattern?: string): Date => {
    if (!datePattern) {
      datePattern = DateUtils.getCurrentDatePattern();
    }

    if (datePattern && date) {
      return DateTime.fromFormat(date, datePattern).toJSDate();
    }
    return new Date(date || '');
  };

  /**
   * Check if the date is valid by returning a boolean
   * @param date A string that represents a date (the string needs to be a valid date)
   */
  static isDateValid = (date?: string | null): boolean => {
    const datePattern = DateUtils.getCurrentDatePattern();
    return (
      !!datePattern && !!date && DateTime.fromFormat(date, datePattern).isValid
    );
  };

  /**
   * Returns a really verbose date format.
   *
   * Sample:
   * Imagine that you use this function with the date 11/09/2011 17:30 (dd/mm/yyyy HH:mm) - Make sure to protect your towers :D
   *
   * For english (mm/dd/yyyy) you'll receive: September 11, 2011 at 17:30
   * For portuguese (dd/mm/yyyy) you'll receive: 11 de Setembro de 2011, às 17:30
   *
   * Note that the pattern is not required, if the pattern is not present,
   * we'll use the current user date pattern
   *
   * @param date A string that represents a date (the string needs to be a valid date)
   * @param datePattern The pattern that you want to convert the date into (NOT REQUIRED)
   * @returns
   */
  static convertLongDate = (date?: string, datePattern?: string) => {
    if (!datePattern) {
      datePattern = DateUtils.getCurrentDatePattern();
    }

    const language = datePattern === 'dd/MM/yyyy' ? 'pt-BR' : 'en-US';
    if (date) {
      const data = new Date(date);
      const dia = data.getDate();
      let mes = data.toLocaleString(language, { month: 'long' });
      mes = mes.charAt(0).toUpperCase() + mes.slice(1);
      const ano = data.getFullYear();
      const hora = data.toLocaleTimeString(language, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });

      return datePattern === 'dd/MM/yyyy'
        ? `${dia} de ${mes} de ${ano}, às ${hora}`
        : `${mes} ${dia}, ${ano}, at ${hora}`;
    }
    return date;
  };

   /**
   * Returns a really verbose date format.
   *
   * Sample:
   * Imagine that you use this function with the date 11/09/2011 17:30 (dd/mm/yyyy HH:mm)
   *
   * For english (mm/dd/yyyy) you'll receive: 09/11/2011 at 17:30
   * For portuguese (dd/mm/yyyy) you'll receive: 11/09/2011 às 17:30
   *
   * Note that the pattern is not required, if the pattern is not present,
   * we'll use the current user date pattern
   *
   * @param date A string that represents a date (the string needs to be a valid date)
   * @param datePattern The pattern that you want to convert the date into (NOT REQUIRED)
   * @returns
   */
  static formatLongDate = (date: string, datePattern?: string) => {
    if (!datePattern) {
      datePattern = DateUtils.getCurrentDatePattern();
    }

    const formattedDate = new Date(date);

    const day = formattedDate.getDate().toString().padStart(2, '0');
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = formattedDate.getFullYear();
  
    const hours = formattedDate.getHours().toString().padStart(2, '0');
    const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
  
    return datePattern === 'dd/mm/yyyy'
      ? `${day}/${month}/${year} às ${hours}:${minutes}`
      : `${month}/${day}/${year} at ${hours}:${minutes}`;
  }

  /**
   * Convert a date string in a valid ISO date.
   *
   * Note that if the pattern is not fullfield we'll use the current user date pattern.
   *
   * @param date A string that represents a date (the string needs to be a valid date)
   * @param datePattern The pattern that date is currently formated (NOT REQUIRED)
   * @returns ISO Date string
   */
  static convertDateToApi = (date: string, datePattern?: DatePatternType) => {
    if (!datePattern) {
      datePattern = DateUtils.getCurrentDatePattern();
    }

    if (_.isNil(date)) return '';

    var arrDate = date?.split('/');
    if (arrDate.length <= 1) return date;
    return DateTime.fromFormat(
      datePattern === 'mm/dd/yyyy'
        ? `${arrDate[1]}/${arrDate[0]}/${arrDate[2]}`
        : `${arrDate[0]}/${arrDate[1]}/${arrDate[2]}`,
      datePattern || 'mm/dd/yyyy'
    ).toFormat('yyyy-MM-dd');
  };

  /**
   * Get a date placeholder based on the language
   *
   * For portuguse: dd/mm/aaaa
   * For english: mm/dd/yyyy
   */
  static getDatePlaceholder = (
    language?: Languages,
    datePattern?: DatePatternType
  ) => {
    if (!datePattern || !!language) {
      datePattern = DateUtils.getCurrentDatePattern();
    }

    if (language === 'pt-BR') {
      if (datePattern === 'MM/dd/yyyy') return 'mm/dd/aaaa';
      else return 'dd/mm/aaaa';
    } else {
      return datePattern?.replaceAll('MM', 'mm');
    }
  };

  static getCurrentDateHour = (idioma: string): string => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const currentDateFormated = `${year}-${month}-${day}`;

    const currentHourFormated = currentDate.toLocaleTimeString(idioma);

    const currentDateHour = `${currentDateFormated} ${currentHourFormated}`;

    return currentDateHour;
  };

  /**
   * Get the age
   *
   *  @param date A string received from API
   */
  static getAge = (date: string) => {
    const thisYear = new Date().getFullYear();
    return thisYear - parseInt(date.substring(0, 4));
  };

  /**
   * Check if a date is in future
   *
   * @param string A string that represents a date (the string needs to be a valid date)
   * 
   * @returns Date is in future or not
   */
    static dateIsInFuture = (
      date: string
    ) => {
      const [day, month, year] = date.split('/');
      const lossDate = new Date(Number(year), Number(month) - 1, Number(day));
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return lossDate.getTime() <= today.getTime();
    };

    /**
   * Check if a date is in past
   *
   * @param string A string that represents a date (the string needs to be a valid date)
   * 
   * @returns Date is in past or not
   */
    static dateIsInPast = (
      date: string
    ) => {
      const [day, month, year] = date.split('/');
      const lossDate = new Date(Number(year), Number(month) - 1, Number(day));
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return lossDate.getTime() >= today.getTime();
    };
}

export default DateUtils;
