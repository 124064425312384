import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '@/Components';
import { DeleteUserGroupModal } from './Modals/DeleteModal';
import { useParams } from 'react-router';
import { useEditUserGroupsStates } from './Hooks/useEditUserGroupsStates';
import { useEditUserGroupsCallAPI } from './Hooks/useEditUserGroupsCallAPI';
import { useEditUserGroupsMemorizations } from './Hooks/useEditUserGroupsMemorizations';
import { PageLoading } from '@/Layouts/PageLoading';
import { useUserGroupDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/userGroups/details';

const EditForm: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { modalDeleteUserGroup, setModalDeleteUserGroup } =
    useEditUserGroupsStates();

  const { userGroup, isLoading } = useEditUserGroupsCallAPI({
    externalId,
  });

  const { items, dropdownItems } = useEditUserGroupsMemorizations({
    externalId,
    userGroup,
    setModalDeleteUserGroup,
  });

  const { getShortcut } = useUserGroupDetailsShortcuts(t, {});

  const editMoreActionsHotkey = getShortcut('editMoreActions');

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserGroups.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        moreActionsButton={{
          title: editMoreActionsHotkey.tooltip,
        }}
        dropdownItens={dropdownItems}
        dataTestIdDropdownButton="more-actions-button"
      />

      {userGroup?.externalId ? (
        <DeleteUserGroupModal
          userGroup={userGroup}
          onCancelClick={() => setModalDeleteUserGroup(false)}
          visible={modalDeleteUserGroup}
          setVisible={setModalDeleteUserGroup}
        />
      ) : null}
    </PageLoading>
  );
};

export default EditForm;
