import { Modal } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Text } from '@/Components';

interface ICancelReleaseModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  loading: boolean;
}

export const CancelReleaseModal: React.FC<ICancelReleaseModalProps> = ({
  onCancel,
  onOk,
  visible,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${t('supply.inventory.release.modal.cancel.title')}?`}
      visible={visible}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.inventory.release.modal.cancel.body')}  
              </ReactMarkdown>
            </Text>
          </Divflex>
        </div>
      }
      onCancelClick={onCancel}
      onClose={onCancel}
      onOkClick={onOk}
      cancelButtonName={t('common.back')}
      okButtonColor="status-danger-base"
      okButtonName="supply.inventory.release.modal.cancel.confirmButton"
      loading={loading}
    />
  );
};
