import styles from './styles.module.scss';

interface ICardDates {
  children: React.ReactNode;
}

export const CardDates: React.FC<ICardDates> = ({ children }) => {
  return (
    <div className={`${styles['cardDates']}`}>
      <div>{children}</div>
    </div>
  );
};
