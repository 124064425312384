import { Dispatch, FC, SetStateAction } from 'react';
import { HeaderRecipeDescription } from './Components/HeaderRecipeDescription';
import { ComponentSelection } from './Components/ComponentSelection';

import styles from './AddManipulatedRecipe.module.scss';

interface IAddManipulatedRecipeModalBody {
  setComponentsEditing: Dispatch<SetStateAction<number[]>>;
  componentsEditing: number[];

  validatingComponents: boolean;
}

export const AddManipulatedRecipeModalBody: FC<
  IAddManipulatedRecipeModalBody
> = ({ setComponentsEditing, componentsEditing, validatingComponents }) => {
  return (
    <div
      className={styles['container']}
      id="add-manipulated-recipe-dropdown-components-container"
    >
      <HeaderRecipeDescription />
      <ComponentSelection
        setComponentsEditing={setComponentsEditing}
        componentsEditing={componentsEditing}
        validatingComponents={validatingComponents}
      />
    </div>
  );
};
