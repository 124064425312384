import { release } from 'os';

export const supplyEN = {
  places: {
    list: {
      code: 'Code',
      name: 'Name',
      placeName: 'Place name',
      status: 'Status',
      company: 'Company',
      searchPlaceHolder: 'Search for a supply place',
      statusTypes: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
  },

  losses: {
    newButton: 'New loss register',
    tooltips: {
      lossDate: 'Original loss date: {{date}}',
      reason: 'Original loss Reason: {{reason}}',
      comments: 'Original comments: {{comments}}',
      beforeAmount:
        'The amount before the loss is related with the amount in the moment of the adjustment.',
      afterAmount:
        'The amount after the lost is related with the amount after the adjustment.',
    },

    list: {
      searchPlaceholder: 'Search for a product, lot, stock location, etc.',
      lossDate: 'Loss Date',
      product: 'Product',
      number: 'Nº Lot',
      supplyPlace: 'Supply place',
      quantity: 'Quantity',
      owner: 'Owner',
    },

    create: {
      titleInformation: 'Information',
      titleInformationLossRecord: 'Loss Record',
      product: 'Product',
      productPlaceholder: 'Search for a product',
      stockLocation: 'Stock Location',
      stockLocationPlaceholder: 'Select a stock location',

      lot: 'Lot',
      lotPlaceholder: 'Search for a lot',

      provider: 'Invoice',
      invoice: 'Invoice Number',
      serialNumberInvoice: 'Sérial Invoice',

      dateLoss: 'Date of Loss',
      dateLossPlaceholder: 'dd/mm/yyyy',
      reasonloss: 'Reason for Loss',
      reasonLossPlaceholder: 'Select a reason',

      prevAmount: 'Quantity before loss',
      nextAmount: 'Quantity after loss',
      currentBalance: 'Current Balance',
      amountLoss: 'Loss Quality',
      newBalance: 'New Balance',

      comments: 'Comments',
      commentsPlaceholder: 'Notes on the loss',

      codeSubLabel: 'Code:',
      invoiceSubLabel: 'Invoice:',

      success: 'Loss Record Successfully Registered',

      errors: {
        requiredName: 'Product name is required',
        localEstoqueId: 'Stock location is required',
        quantidade: 'Amount of Loss is required',
        loteId: 'Lot is required',
        dataPerda: 'Date of Loss is required',
        dataPerdaIsHigher: 'Date of Loss is higher than the current day',
        motivoPerdaId: 'Reason for Loss is required',
      },
      toolTips: {
        currentQuantityNegative:
          'The loss amount cannot be greater than the current balance.',
        currentBalanceNegative: 'The new balance cannot be negative.',
      },
      backModal: {
        title: 'Do you want to exit without saving?',
        body: 'Are you sure that you want to leave without save? Your changes will not be saved.',
        okButton: 'Leave without save',
      },
    },
    detail: {
      title: 'Stock Loss',
      launchDate: 'Release date of',
    },
    editLosse: {
      tab: {
        details: 'Details',
      },
    },
  },

  edit: {
    success: 'Loss updated with success!',
  },

  lot: {
    card: {
      notRawMaterialCard:
        'Information available only for products in the Raw material class.',
    },
    list: {
      lot: 'Lot number',
      product: 'Product',
      supplier: 'Provider',
      date: 'Launch Date',
      validity: 'Validity',
      quantity: 'Quantity',
      status: 'Status',
    },
    create: {
      save: 'Register',
      saveLoading: 'Signing up',
      cancel: 'Cancel',
      titleInformation: 'Information',
      titleTechnicalInformation: 'Technical Information',
      titleInitialBalanceInformation: 'Initial Balance',
      titleInternalDilution: 'Internal dilution',
      titleConversionRates: 'Conversion rates',
      lot: 'Code lot',
      class: 'Class',
      product: 'Product',
      quantity: 'Quantity',
      un: 'Unit of measurement',
      quantityInitial: 'Quantity',
      manufacturing: 'Manufacturing date',
      validity: 'Expiration date',
      provider: 'Provider',
      corporateName: 'Social Reason',
      cnpj: 'CNPJ',
      numberInvoice: 'Invoice number',
      seriesInvoice: 'invoice series',
      density: 'Density',
      countryOfOrigin: 'Country of Origin',
      dilution: 'Dilution',
      stockLocation: 'Stock Location',
      batchCode: 'Batch code',
      availableQuantity: 'Available quantity',
      supplierDilution: 'Supplier Dilution (%)',
      supplierDilutionFactor: 'Supplier Dilution Factor',
      waterConcentration: 'Water Concentration (%)',
      waterConcentrationFactor: 'Conc. Factor Of Water',
      internalDilution: 'Internal Dilution (%)',
      internalDilutionFactor: 'Internal Dilution Factor',
      lotOrigin: 'Origin Lot',
      searchForLot: 'Search for a Lot',
      alternativeUnitOne: 'Alternative Unit 1',
      alternativeUnitTwo: 'Alternative Unit 2',
      code: 'Code',
      success: {
        lotCreated: 'Lot registered!',
      },
      errors: {
        requiredProduct: 'Product is mandatory',
        requiredCodeLot: 'Code lot is mandatory',
        requiredManufacturingDate: 'Manufacturing date is mandatory',
        higherManufacturingDate:
          'Manufacturing cannot be older than the current day',
        requiredValidityDate: 'Validity date is mandatory',
        higherValidityDate: 'Validity cannot be less than the current day',
        requiredProvider: 'Provider is mandatory',
        requiredNumberInvoice: 'Number invoice is mandatory',
        requiredSeriesInvoice: 'Series invoice is mandatory',
        requiredDensity: 'Density is mandatory',
        requiredSupplierDilution: 'Supplier dilution is mandatory',
        requiredSupplierDilutionFactor: 'Supplier dilution factor is mandatory',
        requiredWaterConcentration: 'Water concentration is mandatory',
        requiredWaterConcentrationFactor:
          'Water concentration factor is mandatory',
      },
      toolTips: {
        supplierDilution:
          'Percentage of Dilution informed in the Suppliers Certificate of Analysis. This value is typically between 1% and 100%.',
        supplierDilutionFactor:
          'Factor resulting from Supplier Dilution. Example: If the Supplier Dilution is 40%, the Dilution Factor will be 2.5, i.e. 100 / 40.',
        waterConcentration:
          'Concentration/Water Content informed in the Suppliers Certificate of Analysis. This value is normally between 1% and 17%.',
        waterConcentrationFactor:
          'Fator resultante da Concentração de Água. Exemplo: Se a Concentração de Água for de 8,5%, o Fator de Concentração de Água será de 1,093, isto é 100 / (100 - 8,5).',
        internalDilution:
          'Dilution carried out in the pharmacy. Examples: If the Dilution is 1:10 is 10%, 1:50 is 2%, 1:100 is 1% and 1:1000 is 0.1%.',
        internalDilutionFactor:
          'Factor resulting from the Dilution performed in the pharmacy. Example: If the Dilution is 25% the Internal Dilution Factor will be 4, that is 100/25.',
        lotOrigin:
          'Batch of raw material that, when diluted, gave rise to this batch.',
      },
    },
    placeholder: {
      lot: 'Ex: N1846214',
      product: 'Search for a product',
      quantity: 'Ex: 125,0000',
      un: 'Select a unit of measurement',
      dilution: 'Ex: 100,000',
      stockLocation: 'Select a stock location',
      quantityInitial: 'Ex: 250,0000',
      provider: 'Look for a supplier',
      numberInvoice: 'Ex: 123',
      seriesInvoice: 'Ex: 1',
      density: 'Ex: 1,000 g/cm',
      countryOfOrigin: 'Select a country',
      searchPlaceholder: 'Search for a lot, product, supplier, etc.',
    },
    details: {
      goToProducts: 'Go to product',
      stockLocation: {
        searchPlaceholder: 'Search for a stock location',
        code: 'Code',
        placeName: 'Place name',
        company: 'Company',
        availableQuantity: 'Available quantity',
      },
      details: {
        titleInformation: 'Information',
        batchCode: 'Batch code',
        launch: 'Release Date of',
        availableQuantity: 'Available quantity',
        product: 'Product',
        class: 'Class',
        manufacturing: 'Manufacturing date',
        validity: 'Expiration date',
        provider: 'Provider',
        corporateName: 'Social Reason',
        cnpj: 'CNPJ',
        numberInvoice: 'Invoice number',
        seriesInvoice: 'invoice series',
        technicalInformation: 'Technical information',
        density: 'Density',
        countryOfOrigin: 'Country of Origin',
        supplierDilution: 'Supplier Dilution (%)',
        supplierDilutionFactor: 'Supplier Dilution Factor',
        waterConcentration: 'Water Concentration (%)',
        waterConcentrationFactor: 'Conc. Factor Of Water',
        internalDilution: 'Internal Dilution (%)',
        internalDilutionFactor: 'Internal Dilution Factor',
        lotOrigin: 'Search for a lot',
        alternativeUnitOne: 'Alternative Unit 1',
        alternativeUnitTwo: 'Alternative Unit 2',
      },
      toolTips: {
        copy: 'Copy ID',
        copySuccess: 'Copied!',
        availableQuantity: 'Updated value according to stock movement.',
        originalManufacturingDate: 'Original manufacturing date:',
        originalExpirationDate: 'Original expiration date:',
        originalDensity: 'Original density:',
        originalCountryOfOrigin: 'Original country of origin:',
        originalDilution: 'Original dilution:',
        originalDilutionFactor: 'Original dilution factor:',
        originalWaterConcentration: 'Original water concentration:',
        originalWaterConcentrationFactor:
          'Original water concentration factor:',
        originalInternalDilution: 'Original internal dilution:',
        originalInternalDilutionFactor: 'Original internal dilution factor:',
        originalLot: 'Original lot:',
        originalAlternateUnit: 'Original Alternate Unit:',
        originalAlternateUnit2: 'Original Alternate Unit 2:',
      },
      modal: {
        cannotDelete: {
          title: 'Unable to delete batch “{{lot}}”',
          batchTitle: 'Unable to delete {{lot}} lots',

          subTitle: 'Unable to delete batch {{lot}} for product {{product}}!',
          batchSubTitle: 'Unable to delete batches:',

          description:
            'This batch has already been moved within the system and therefore it is not possible to delete it.',
          batchDescription:
            'These batches have already been moved within the system and therefore it is not possible to exclude them.',

          warningFooter:
            'If you no longer want to use this batch, change its status to “Inactive”.',
          batchWarningFooter:
            'If you no longer want to use these batches, change their status to “Inactive”.',
        },
      },
    },
  },

  balanceAdjustment: {
    detail: 'Details',
    subLabel: 'Code:',

    info: 'Information',
    adjustDate: 'Adjustment date',
    responsavel: 'Responsible',

    product: 'Product',
    supplyPlace: 'Stock Location',

    lot: 'Lot',
    provider: 'Provider',
    identifier: 'Invoice number',
    nfSeries: 'Invoice series',

    priceAdjust: 'Balance adjustment',

    currentQuantity: 'Current balance',
    moviment: 'Adjustment amount',
    newQuantity: 'New balance',
    goToChanges: 'Go to changes',

    createdWithSuccess: 'Balance adjustment created with success!',

    backModal: {
      title: 'Are you sure?',
      body: 'Are you sure that you want to leave without save? Your changes will not be saved.',
      okButton: 'Leave without save',
    },
    list: {
      searchPlaceHolder: 'Search for a product, batch, stock location, etc.',
      newButton: 'New balance adjustment',
      adjustmentDate: 'Adjustment date',
      product: 'Product',
      number: 'N.º Lot',
      supplyPlace: 'Stock Location',
      moviment: 'Movement',
      balance: 'Balance',
      toolTip: {
        balance: 'Balance after balance adjustment',
      },
    },
    details: {
      adjustmentDate: 'Adjustment date',
      responsible: 'Responsible',
      product: 'Product',
      stockLocation: 'Stock Location',
      lot: 'Lot',
      provider: 'Provider',
      identifier: 'Invoice number',
      nfSeries: 'NF series',
      balanceBeforeAdjustment: 'Balance before adjustment',
      adjustmentAmount: 'Adjustment amount',
      balanceAfterAdjustment: 'Balance after adjustment',
      toolTip: {
        balanceBeforeAdjustment:
          'Refers to the balance at the time of adjustment.',
        balanceAfterAdjustment:
          'Refers to the balance at the time of adjustment.',
      },
      errors: {
        newQnt: 'The new balance cannot be negative.',
      },
    },
    create: {
      placeHolder: {
        product: 'Search for a product',
        stockLocation: 'Select a stock location',
        lot: 'Search for a lot',
      },

      errors: {
        requiredName: 'Product Name is Required',
        localEstoqueId: 'Stock Location is Required',
        quantidade: 'Quantity of Movement is Required',
        requiredLoteExternalId: 'Lot Description is Required',
      },
    },
  },

  supplyPlaces: {
    create: {
      detail: 'Details',

      name: 'Name',
      namePlaceholder: 'Stock location name',

      company: 'Company',
      companyPlaceholder: 'Search for a company',

      stockType: {
        stockStorage: 'Stock for Storage',
        stockSale: 'Stock for Sale',
      },

      success: 'Registered successfully',

      footerButton: {
        cancel: 'Cancel',
        okButton: 'Register',
      },
    },
    details: {
      name: 'Name',
      namePlaceholder: 'Stock location name',
      changeStatusDescription:
        'Are you sure you want to inactivate the stock location {{storageLocation}}?',

      modal: {
        messageQuestionDelete:
          'Are you sure you want to delete the stock location',
      },
    },
    errors: {
      requiredName: 'Name is required.',
      requiredCompany: 'Company is required.',
      errorStorageLocationNameExist:
        'A stock location with that name already exists in the selected company',
    },

    editSupplyPlaces: {
      tab: {
        details: 'Details',
        inventory: 'Inventory',
        inventoryMovements: 'Inventory Movements',
      },

      buttonActions: {
        delete: 'Delete',
      },
    },
    edit: {
      status: 'Status',
      company: 'Company',
    },

    editStatus: {
      status: {
        inactivate: 'inactivate',
      },
    },

    toolTips: {
      nameOfStorageLocation: 'Original stock location name: ',
      nameOfCompany: 'Original company: ',
    },
    backModal: {
      title: 'Are you sure?',
      body: 'Are you sure that you want to leave without save? Your changes will not be saved.',
      okButton: 'Leave without save',
    },

    modal: {
      cannotDelete: {
        title: 'Unable to delete stock location “{{storagelocation}}”',
        batchTitle: 'Unable to delete {{storagelocation}} storage locations',
        subTitle:
          'Unable to delete stock location {{company}} company {{storagelocation}}.',
        batchSubTitle: 'Unable to delete inventory locations:',
        description:
          'This stock location has not been completely disused and therefore it is not possible to delete it.',
        batchDescription:
          'These storage locations have not been completely decommissioned and therefore cannot be deleted.',
        warningFooter:
          'If you no longer want to use this stock location, change its status to "Inactive".',
        batchWarningFooter:
          'If you no longer wish to use these storage locations, change their status to “Inactive”',
        ok: 'Close',
      },
    },
  },

  inventoryList: {
    searchPlaceholder: 'Search for a product',
    tableTitle: {
      code: 'Code',
      productName: 'Product name',
      group: 'Group',
      subGroup: 'Subgroup',
      quantity: 'Avaliable quantity',
    },
  },

  transfers: {
    info: 'Info',
    transfers: 'Transfers',
    newButton: 'New transfer',
    subLabel: 'Code:',
    searchPlaceHolder:
      'Search by transfer number, date, place of origin, destination, etc.',
    list: {
      transferNumber: 'Transfer No.',
      dateOfTransfer: 'Date of Transfer',
      placeOfOrigin: 'Place of Origin',
      destination: 'Destination',
      responsible: 'Responsible',
    },

    create: {
      errors: {
        requiredOrigin: 'Origin is required',
        requiredDestiny: 'Destiny is required',
        requiredProduct: 'Product is required',
        requiredLot: 'Lot is required',
        requiredAmountTransfers: 'Amount is required',
        minQuantity: 'Quantity cannot be zero'
      },
    },

    detailsForm: {
      titleInformation: 'Information',
      titleTransfer: 'Transfer',
      transferId: 'Transfer ID',
      dateOfTransfer: 'Date of transfer',
      responsible: 'Responsible',
      placeOfOrigin: 'Source Stock location',
      destination: 'Destination Stock location',
      comments: 'Comments',
      product: 'Product',
      lot: 'Lot',
      originBalance: 'Origin balance',
      transferAmount: 'Transfer amount',
      destinationBalance: 'Destination balance',
    },
    fields: {
      supplyPlaceLocationFrom: 'Origin Supply Place',
      supplyPlaceLocationTo: 'Destiny Supply Place',
      observation: 'Observations',

      product: 'Product',
      lot: 'Lot',
      originFunds: 'Origin Funds',
      qntTransfers: 'Transfers Quant.',
      destinyFunds: 'Destiny Funds',
    },
    placeHolders: {
      supplyPlaceLocationFrom: 'Select a stock location',
      supplyPlaceLocationTo: 'Select a stock location',
      observation: 'Notes on the transfer',
      product: 'Search for a product',
      lot: 'Search for a batch',
    },
    actions: {
      cancel: 'Cancel',
      save: 'Save',
      register: 'Register',
      registerLoading: 'Signing up',
      addItem: 'Add Item',
    },
    toolTips: {
      originBalance: 'Refers to the balance at the time of transfer.',
      destinationBalance: 'Regarding the balance at the end of the transfer.',
      currentBalanceNegative:
        'It is not possible to select a quantity greater than that available at origin.',
    },
    backModal: {
      title: 'Are you sure?',
      body: 'Are you sure that you want to leave without save? Your changes will not be saved.',
      okButton: 'Leave without save',
    },
    changeStorageLocationModal: {
      title: 'Do you want to change the Source Stock Location?',
      description:
        'Are you sure you want to change the **Origin Stock Location**? The data will be reset.',
    },
  },

  group: {
    modal: {
      cannotDelete: {
        title: '“{{group}}” cannot be deleted!',
        batchTitle: 'Unable to delete {{group}} groups!',

        subTitle: 'We cannot delete the group “{{group}}”.',
        batchSubTitle: 'Unable to delete groups:',

        description:
          'The group has **{{products}} product{{products_plural}}** and **{{subgroups}} sub-group{{subgroups_plural}}** attached to it. Remove all products and sub-groups to be able to delete this group.',
        batchDescription:
          'Groups have products and/or subgroups linked to them.\nUnlink all products and subgroups to be able to delete them.',

        ok: 'See products',
      },
      create: {
        required: 'Description must be informed',
      },
    },
  },
  subgroup: {
    modal: {
      cannotDelete: {
        title: '“{{subGroup}}” cannot be deleted!',
        batchTitle: 'Unable to delete {{subGroup}} subgroups!',

        subTitle: 'We cannot delete the subgroup “{{subGroup}}”.',
        batchSubTitle: 'Unable to delete subgroups:',

        descriptionOneProduct:
          'The subgroup has **{{products}} product** attached to it. Remove all products to be able to delete this subgroup.',
        descriptionManyProducts:
          'The subgroup has **{{products}} products** attached to it. Remove all products to be able to delete this subgroup.',
        batchDescription:
          'Subgroups have products linked to them. Unlink all products to be able to exclude subgroups.',
        ok: 'See products',
      },
    },
  },
  product: {
    modal: {
      cannotDelete: {
        title: 'Could not delete product “{{product}}”',
        batchTitle: 'Unable to delete {{product}} products',

        subTitle: 'It is not possible to delete the product “{{product}}”.',
        batchSubTitle: 'It is not possible to delete the products:',

        description:
          'This product has already been moved within the system and therefore it is not possible to delete it.',
        batchDescription:
          'These products have already been moved within the system and therefore it is not possible to exclude them.',

        warningFooter:
          'If you no longer want to use this product, change its status to “Inactive”.',
        batchWarningFooter:
          'If you no longer want to use these products, change their status to “Inactive”.',
      },
    },
  },

  productMessage: {
    list: {
      newButton: 'New Product Mensage',
      placeholder: 'Search for a product message',
      description: 'Product Mensage',
      products: 'Linked Products',
    },

    create: {
      label: 'Product Mensage',
      placeholder: 'Insert Product Mensage',
      primaryLabel: 'Display warning in the system',
      secondaryLabel: 'Display in print',
      checkBox1: 'Sale',
      checkBox2: 'Labeling',
      checkBox3: 'Weighing Sheet',
      checkBox4: 'Label',
      required: 'Product Message is Mandatory',

      erros: {
        requiredNameMessage: 'Product Message is required',
        errorMessageNameExist:
          'There is already a product message with this description',
      },
    },

    details: {
      deleteTitle: 'Delete',
      deleteSingleTitle: 'the product mensage',
      deleteManyTitle: 'the product messages',

      linkedListProduct: {
        code: 'Code',
        productName: 'Product Name',
        class: 'Class',
        group: 'Group',
        subGroup: 'Subgroup',
        status: 'Status',
      },

      modalRemoveLinked: {
        messageTitle: 'this product message',
        messageStartBody:
          'Are you sure you want to remove the product {{action}}',
        messageFinishBody: 'from the product message: ',
      },
    },

    modalCannotDelete: {
      messageTitle: 'the product mensage',
      messageSubtitle: 'the product mensage:',
      messageManySubtitle: 'product messages:',
      messageReasonCannotDelete:
        'This message has already had activity within the system or is associated with a product, and therefore, deletion is not possible.',
      messageWarningCannotDelete:
        'If you do not want to use this product message, change its status to "inactive".',
      messageManyTitles: 'the product messages',
      messageManySubtitleSecond: 'the product messages',
      messageManyReasonCannotDelete:
        'These messages have already had activity within the system or are associated with some product, so their deletion is not possible.',
      messageManyWarningCannotDelete:
        'If you do not want to use these product messages, change their status to "inactive".',
    },
  },

  inventory: {
    list: {
      newButton: 'New Inventory',
      code: 'Code',
      supplyPlaces: 'Supply placesS',
      creationDate: 'Creation date',
      status: 'Status',
      responsible: 'Responsible',

      released: 'Released',
      canceled: 'Canceled',
      waitingRelease: 'Waiting release',
      finished: 'Finished',
      conference: 'Conference',
    },
    create: {
      info: 'Information',
      supplyPlaces: 'Stock Locations',
      groups: 'Groups',
      subgroups: 'Subgroups',
      supplyPlacesPlaceholder: 'Select Stock Location',
      groupsPlaceholder: 'Select Group',
      subgroupsPlaceholder: 'Select Subgroup',
      hideAnotherValue: 'Hide Quantity Values in Recording',
      hiderAnotherValueTooltip:
        'Enable if you want the quantity values of products in inventoried locations to be hidden during recording',
      hideAnotherValueImpression: 'Hide Quantity Values in Printing',
      hiderAnotherValueImpressionTooltip:
        'Enable if you want the quantity values of products in inventoried locations to be hidden during printing',

      register: 'Register Inventory',
    },

    details: {
      deleteInventory: 'Delete inventory',
      areYouSureDeleteInventory:
        'Are you sure you want to delete the inventory {inventario}}?',

      dateCreation: 'Creation date',
      responsible: 'Responsible',
      code: 'Code',
    },

    release: {
      waitingRelease: 'Waiting Release',
      startRelease: 'Start Release',
      releaseDate: 'Release Date',
      cancellationDate: 'Cancellation Date',
      inventoryCanceled: 'Canceled',
      inventoryConference: 'Conference',
      responsible: 'Responsible',
      blocked: 'Blocked',
      previousReleases: 'Previous Releases',
      previousReleasesLabel: 'Release #',
      previousReleasesExtraLabel: '(Current)',
      startReleaseTextStart:
        'When starting the process, the stock locations will be',
      startReleaseTextEnd:
        'until the inventory is completed. Do you want to continue?',
      finish: 'Finish',
      release: 'Release',
      code: 'Code',
      product: 'Product',
      lot: 'Batch',
      invoice: 'Invoice',
      quantity: 'Qty.',
      count: 'Count',
      responsibleTitle: 'Responsible',
      difference: 'Difference',
      searchForAResponsibleOperator: 'Search for a responsible operator',
      responsibleOperator: 'Responsible Operator',
      addProduct: 'Add Product',
      dropdownOptions: {
        title: 'More Actions',
        save: 'Save Draft',
        print: 'Print All',
        cancel: 'Cancel Release'
      },
      modal: {
        cancel: {
          title: "Cancel Release",
          body: "Do you want to **cancel** the release? The inventory will return to the Awaiting Release status.",
          confirmButton: "Cancel Release"
        }
      },
    },
    dropdownOptions: {
      releaseOption: 'Cancel Inventory'
    },
    modal: {
      cancelInventory: {
        title: 'Cancel inventory #{{inventoryCode}}',
        bodyInitial: 'Are you sure you want to cancel inventory **#{{inventoryCode}}**?',
        bodyMiddle: 'This action will **allow** the movement of products in the storage locations:',
        footer: 'This action is **permanent** and cannot be undone!',
        confirmButton: 'Cancel Inventory'
      }
    }
  },
};

export const supplyPT = {
  places: {
    list: {
      code: 'Código',
      name: 'Nome',
      placeName: 'Nome do local',
      status: 'Status',
      company: 'Empresa',
      searchPlaceHolder: 'Pesquise por um local de estoque',
      statusTypes: {
        active: 'Ativo',
        inactive: 'Inativo',
      },
    },
  },

  losses: {
    newButton: 'Novo registro de perda',

    tooltips: {
      lossDate: 'Data da perda original: {{date}}',
      reason: 'Motivo da perda original: {{reason}}',
      comments: 'Observações original: {{comments}}',
      beforeAmount:
        'O saldo antes da perda é referente ao saldo no momento do ajuste.',
      afterAmount:
        'O saldo após a perda é referente ao saldo final no momento do ajuste.',
    },

    list: {
      searchPlaceholder:
        'Pesquise por um produto, lote, local de estoque, etc.',
      lossDate: 'Data da perda',
      product: 'Produto',
      number: 'N.º Lote',
      supplyPlace: 'Local de estoque',
      quantity: 'Quantidade',
      owner: 'Responsável',
    },

    create: {
      titleInformation: 'Informações',
      titleInformationLossRecord: 'Registro de Perda',
      product: 'Produto',
      productPlaceholder: 'Pesquise por um produto',

      stockLocation: 'Local de Estoque',
      stockLocationPlaceholder: 'Selecione um local de estoque',

      lot: 'Lote',
      lotPlaceholder: 'Pesquise por um lote',

      provider: 'Fornecedor',
      invoice: 'Número da NF',
      serialNumberInvoice: 'Série NF',

      dateLoss: 'Data da Perda',
      dateLossPlaceholder: 'dd/mm/yyyy',

      reasonloss: 'Motivo da Perda',
      reasonLossPlaceholder: 'Selecione um motivo',

      prevAmount: 'Saldo antes da perda',
      nextAmount: 'Saldo após a perda',
      currentBalance: 'Saldo Atual',
      amountLoss: 'Quantidade de Perda',
      newBalance: 'Novo Saldo',

      comments: 'Observações',
      commentsPlaceholder: 'Observações sobre a perda',

      codeSubLabel: 'Código:',
      invoiceSubLabel: 'NF:',

      success: 'Registro de Perda Cadastrado com Sucesso',

      errors: {
        requiredName: 'Nome do Produto é Obrigatório',
        localEstoqueId: 'Local de Estoque é Obrigatório',
        quantidade: 'Quantidade de Perda é Obrigatório',
        requiredLoteExternalId: 'Descrição do Lote é Obrigatório',
        dataPerda: 'Data da Perda é Obrigatório',
        dataPerdaIsHigher: 'Data da Perda é superior ao dia atual',
        motivoPerdaId: 'Motivo da Perda é Obrigatório',
      },

      toolTips: {
        currentQuantityNegative:
          'A quantidade de perda não pode ser maior que o saldo atual.',
        currentBalanceNegative: 'O novo saldo não pode ser negativo.',
      },

      backModal: {
        title: 'Deseja sair sem salvar?',
        body: 'Você tem certeza que deseja sair da página sem salvar? As alterações que você fez talvez não sejam salvas.',
        okButton: 'Sair sem salvar',
      },
    },
    detail: {
      title: 'Perda de Estoque',
      launchDate: 'Data de lançamento',
    },
    editLosse: {
      tab: {
        details: 'Detalhes',
      },
    },
  },

  edit: {
    success: 'Perda atualizada com sucesso!',
  },

  lot: {
    card: {
      notRawMaterialCard:
        'Informações técnicas disponíveis apenas para produtos da classe Matéria-Prima.',
    },
    list: {
      lot: 'N.º Lote',
      product: 'Produto',
      supplier: 'Fornecedor',
      date: 'Lançamento',
      validity: 'Validade',
      quantity: 'Qtd. Disponível',
      status: 'Status',
    },
    create: {
      save: 'Cadastrar',
      saveLoading: 'Cadastrando',
      cancel: 'Cancelar',
      titleInformation: 'Informações',
      titleTechnicalInformation: 'Informações Técnicas',
      titleInitialBalanceInformation: 'Saldo Inicial',
      titleInternalDilution: 'Diluição interna',
      titleConversionRates: 'Taxas de conversão',
      lot: 'Código do lote',
      class: 'Classe',
      product: 'Produto',
      quantity: 'Quantidade',
      un: 'Unidade de Medida',
      manufacturing: 'Data de Fabricação',
      validity: 'Data de Validade',
      provider: 'Fornecedor',
      corporateName: 'Razão Social',
      cnpj: 'CNPJ',
      numberInvoice: 'Número da NF',
      seriesInvoice: 'Série NF',
      density: 'Densidade',
      countryOfOrigin: 'País de Origem',
      dilution: 'Diluição',
      stockLocation: 'Local de Estoque',
      quantityInitial: 'Quantidade',
      batchCode: 'Código do Lote',
      availableQuantity: 'Quantidade disponível',
      supplierDilution: 'Diluição Fornecedor (%)',
      supplierDilutionFactor: 'Fator de Diluição Fornecedor',
      waterConcentration: 'Concentração de Água (%)',
      waterConcentrationFactor: 'Fator de Conc. de Água',
      internalDilution: 'Diluição Interna (%)',
      internalDilutionFactor: 'Fator de Diluição Interna',
      lotOrigin: 'Lote Origem',
      searchForLot: 'Pesquise por um lote',
      alternativeUnitOne: 'Unid. Alternativa 1',
      alternativeUnitTwo: 'Unid. Alternativa 2',
      code: 'Código',
      success: {
        lotCreated: 'Lote cadastrado!',
      },
      errors: {
        requiredProduct: 'Produto é obrigatório',
        requiredCodeLot: 'Código do lote é obrigatório',
        requiredManufacturingDate: 'Data de fabricação é obrigatória',
        higherManufacturingDate:
          'Fabricação não pode ser superior ao dia atual',
        requiredValidityDate: 'Data de validade é obrigatória',
        higherValidityDate: 'Validade não pode ser inferior ao dia atual',
        requiredProvider: 'Fornecedor é obrigatório',
        requiredNumberInvoice: 'Número da NF é obrigatório',
        requiredSeriesInvoice: 'Série NF é obrigatória',
        requiredDensity: 'Densidade é obrigatória',
        requiredSupplierDilution: 'Diluição fornecedor é obrigatório',
        requiredSupplierDilutionFactor:
          'Fator de diluição fornecedor é obrigatório',
        requiredWaterConcentration: 'Concentração de água é obrigatório',
        requiredWaterConcentrationFactor:
          'Fator de concentração de água é obrigatório',
      },
      toolTips: {
        supplierDilution:
          'Percentual de Diluição informado no Certificado de Análise do Fornecedor. Esse valor normalmente está entre 1% e 100%.',
        supplierDilutionFactor:
          'Fator resultante da Diluição do Fornecedor. Exemplo: Se a Diluição do Fornecedor é 40%, o Fator de Diluição será de 2,5, isto é 100 / 40.',
        waterConcentration:
          'Concentração/Teor de Água informado no Certificado de Análise do Fornecedor. Esse valor normalmente está entre 1% e 17%.',
        waterConcentrationFactor:
          'Fator resultante da Concentração de Água. Exemplo: Se a Concentração de Água for de 8,5%, o Fator de Concentração de Água será de 1,093, isto é 100 / (100 - 8,5).',
        internalDilution:
          'Diluição realizada na farmácia. Exemplos: Se a Diluição for 1:10 é 10%, 1:50 é 2%, 1:100 é 1% e 1:1000 é 0,1%.',
        internalDilutionFactor:
          'Fator resultante da Diluição realizada na farmácia. Exemplo: Se a Diluição 25% o Fator de Diluição Interna será 4, isto é 100/25.',
        lotOrigin:
          'Lote da matéria-prima que ao ser diluído deu origem a este lote.',
      },
    },
    placeholder: {
      lot: 'Ex: N1846214',
      product: 'Procure por um produto',
      quantity: 'Ex: 125,0000',
      un: 'Selecione uma un. de medida',
      provider: 'Procure por um fornecedor',
      numberInvoice: 'Ex: 123',
      seriesInvoice: 'Ex: 1',
      density: 'Ex: 1,000 g/cm',
      countryOfOrigin: 'Selecione um país',
      dilution: 'Ex: 100,000',
      stockLocation: 'Selecione um local de estoque',
      quantityInitial: 'Ex: 250,0000',
      searchPlaceholder: 'Pesquise por um lote, produto, fornecedor, etc.',
    },
    details: {
      goToProducts: 'Ir para produto',
      stockLocation: {
        searchPlaceholder: 'Pesquise por um local de estoque',
        code: 'Código',
        placeName: 'Nome do local',
        company: 'Empresa',
        availableQuantity: 'Quantidade disponível',
      },
      details: {
        titleInformation: 'Informações',
        batchCode: 'Código do Lote',
        launch: 'Data de Lançamento',
        availableQuantity: 'Quantidade disponível',
        product: 'Produto',
        class: 'Classe',
        manufacturing: 'Data de Fabricação',
        validity: 'Data de Validade',
        provider: 'Fornecedor',
        corporateName: 'Razão social',
        cnpj: 'CNPJ',
        numberInvoice: 'Número da NF',
        seriesInvoice: 'Série NF',
        technicalInformation: 'Informações Técnicas',
        density: 'Densidade',
        countryOfOrigin: 'País de Origem',
        supplierDilution: 'Diluição Fornecedor (%)',
        supplierDilutionFactor: 'Fator de Diluição Fornecedor',
        waterConcentration: 'Concentração de Água (%)',
        waterConcentrationFactor: 'Fator de Conc. de Água',
        internalDilution: 'Diluição Interna (%)',
        internalDilutionFactor: 'Fator de Diluição Interna',
        lotOrigin: 'Pesquise por um lote',
        alternativeUnitOne: 'Unid. Alternativa 1',
        alternativeUnitTwo: 'Unid. Alternativa 2',
      },
      toolTips: {
        copy: 'Copiar ID',
        copySuccess: 'Copiado!',
        availableQuantity: 'Valor atualizado conforme movimentação de estoque.',
        originalManufacturingDate: 'Data de fabricação original:',
        originalExpirationDate: 'Data de validade original:',
        originalDensity: 'Densidade original:',
        originalCountryOfOrigin: 'País de origem original:',
        originalDilution: 'Diluição original:',
        originalDilutionFactor: 'Fator de diluição original:',
        originalWaterConcentration: 'Concentração de água original:',
        originalWaterConcentrationFactor:
          'Fator de concentração de água original:',
        originalInternalDilution: 'Diluição interna original:',
        originalInternalDilutionFactor: 'Fator de diluição interna original:',
        originalLot: 'Lote original:',
        originalAlternateUnit: 'Unidade alternativa original:',
        originalAlternateUnit2: 'Unidade alternativa 2 original:',
      },
      modal: {
        cannotDelete: {
          title: 'Não foi possível excluir o lote “{{lot}}”',
          batchTitle: 'Não foi possível excluir {{lot}} lotes',

          subTitle:
            'Não é possível excluir o lote {{lot}} do produto {{product}}!',
          batchSubTitle: 'Não é possível excluir os lotes:',

          description:
            'Esse lote já teve movimentação dentro do sistema e por isso não é possível a sua exclusão.',
          batchDescription:
            'Esses lotes já tiveram movimentação dentro do sistema e por isso não é possível suas exclusões.',

          warningFooter:
            'Caso não queira mais utilizar esse lote, mude o status dele para “Inativo”.',
          batchWarningFooter:
            'Caso não queira mais utilizar esses lotes, mude os status deles para “Inativo”.',
        },
      },
    },
  },

  balanceAdjustment: {
    detail: 'Detalhes',
    subLabel: 'Código:',

    info: 'Informações',
    adjustDate: 'Data do ajuste',
    responsavel: 'Responsável',

    product: 'Produto',
    supplyPlace: 'Local de Estoque',

    lot: 'Lote',
    provider: 'Fornecedor',
    identifier: 'Número da NF',
    nfSeries: 'Série NF',

    priceAdjust: 'Ajuste de saldo',

    currentQuantity: 'Saldo Atual',
    moviment: 'Quantidade do Ajuste',
    newQuantity: 'Novo Saldo',
    goToChanges: 'Ir para movimentação',

    createdWithSuccess: 'Ajustes de saldo criado com sucesso!',

    backModal: {
      title: 'Deseja sair sem salvar?',
      body: 'Você tem certeza que deseja sair da página sem salvar? As alterações que você fez talvez não sejam salvas.',
      okButton: 'Sair sem salvar',
    },
    list: {
      searchPlaceHolder:
        'Pesquise por um produto, lote, local de estoque, etc.',
      newButton: 'Novo ajuste de saldo',
      adjustmentDate: 'Data do ajuste',
      product: 'Produto',
      number: 'N.º Lote',
      supplyPlace: 'Local de estoque',
      moviment: 'Movimentação',
      balance: 'Saldo',
      toolTip: {
        balance: 'Saldo após o ajuste de saldo',
      },
    },
    details: {
      adjustmentDate: 'Data do ajuste',
      responsible: 'Responsável',
      product: 'Produto',
      stockLocation: 'Local de Estoque',
      lot: 'Lote',
      provider: 'Fornecedor',
      identifier: 'Número da NF',
      nfSeries: 'Série NF',
      balanceBeforeAdjustment: 'Saldo Antes do Ajuste',
      adjustmentAmount: 'Quantidade do Ajuste',
      balanceAfterAdjustment: 'Saldo após o Ajuste',
      toolTip: {
        balanceBeforeAdjustment: 'Referente ao saldo no momento do ajuste.',
        balanceAfterAdjustment: 'Referente ao saldo no momento do ajuste.',
      },
      errors: {
        newQnt: 'O novo saldo não pode ser negativo.',
      },
    },
    create: {
      placeHolder: {
        product: 'Pesquise por um produto',
        stockLocation: 'Selecione um local de estoque',
        lot: 'Pesquise por um lote',
      },

      errors: {
        requiredName: 'Nome do Produto é Obrigatório',
        localEstoqueId: 'Local de Estoque é Obrigatório',
        quantidade: 'Quantidade de Movimentação é Obrigatório',
        requiredLoteExternalId: 'Descrição do Lote é Obrigatório',
      },
    },
  },

  supplyPlaces: {
    create: {
      detail: 'Detalhes',

      name: 'Nome',
      namePlaceholder: 'Nome do local de estoque',

      company: 'Empresa',
      companyPlaceholder: 'Procure por uma empresa',

      stockType: {
        stockStorage: 'Estoque para Armazenamento',
        stockSale: 'Estoque para Venda',
      },

      success: 'Cadastrado com sucesso',

      footerButton: {
        cancel: 'Cancelar',
        okButton: 'Cadastrar',
      },
    },
    details: {
      name: 'Nome',
      namePlaceholder: 'Nome do local de estoque',
      changeStatusDescription:
        'Você tem certeza que deseja inativar o local de estoque {{storageLocation}}?',

      modal: {
        messageQuestionDelete:
          'Você tem certeza que deseja excluir o local de estoque',
      },
    },
    errors: {
      requiredName: 'Nome é obrigatório.',
      requiredCompany: 'Companhia é obrigatória.',
      errorStorageLocationNameExist:
        'Já existe um local de estoque com esse nome na empresa selecionada',
    },

    editSupplyPlaces: {
      tab: {
        details: 'Detalhes',
        inventory: 'Estoque',
        inventoryMovements: 'Movimentações de Estoque',
      },
      buttonActions: {
        delete: 'Excluir',
      },
    },
    edit: {
      status: 'Status',
      company: 'Empresa',
    },

    editStatus: {
      status: {
        inactivate: 'Inativar',
      },
    },

    toolTips: {
      nameOfStorageLocation: 'Nome do Local de Estoque original: ',
      nameOfCompany: 'Empresa original: ',
    },

    backModal: {
      title: 'Deseja sair sem salvar?',
      body: 'Você tem certeza que deseja sair da página sem salvar? As alterações que você fez talvez não sejam salvas.',
      okButton: 'Sair sem salvar',
    },

    modal: {
      cannotDelete: {
        title:
          'Não foi possível excluir o local de estoque “{{storagelocation}}”',
        batchTitle:
          'Não foi possível excluir {{storagelocation}} locais de estoque',
        subTitle:
          'Não é possível excluir o local de estoque {{storagelocation}} da empresa {{company}}.',
        batchSubTitle: 'Não é possível excluir os locais de estoque:',
        description:
          'Esse local de estoque não foi totalmente desutilizado e por isso não é possível a sua exclusão.',
        batchDescription:
          'Esses locais de estoque não foram totalmente desutilizados e por isso não é possível suas exclusões.',
        warningFooter:
          'Caso não queira mais utilizar esse local de estoque, mude o status dele para "Inativo".',
        batchWarningFooter:
          'Caso não queira mais utilizar esses locais de estoque, mude os status deles para “Inativo”',
        ok: 'Fechar',
      },
    },
  },

  inventoryList: {
    searchPlaceholder: 'Pesquise por um produto',
    tableTitle: {
      code: 'Código',
      productName: 'Nome do Produto',
      group: 'Grupo',
      subGroup: 'Subgrupo',
      quantity: 'Quantidade disponível',
    },
  },

  transfers: {
    info: 'Informações',
    transfers: 'Transferência',
    newButton: 'Nova transferência',
    subLabel: 'Código:',
    searchPlaceHolder:
      'Pesquise pelo número da transferência, data, local de origem, destino, etc.',
    list: {
      transferNumber: 'N.º da Transf.',
      dateOfTransfer: 'Data da Transf.',
      placeOfOrigin: 'Local de Origem',
      destination: 'Local de Destino',
      responsible: 'Responsável',
    },

    create: {
      errors: {
        requiredOrigin: 'Origem é obrigatória',
        requiredDestiny: 'Destino é obrigatório',
        requiredProduct: 'Produto é obrigatório',
        requiredLot: 'Lote é obrigatório',
        requiredAmountTransfers: 'Quantidade é obrigatória',
        minQuantity: 'Quantidade não pode ser zero'
      },
    },

    detailsForm: {
      titleInformation: 'Informações',
      titleTransfer: 'Transferência',
      transferId: 'ID da transferência',
      dateOfTransfer: 'Data da transferência',
      responsible: 'Responsável',
      placeOfOrigin: 'Local de Estoque de origem',
      destination: 'Local de Estoque de destino',
      comments: 'Observações',
      product: 'Produto',
      lot: 'Lote',
      originBalance: 'Saldo Origem',
      transferAmount: 'Quant. Transf.',
      destinationBalance: 'Saldo Destino',
    },
    fields: {
      supplyPlaceLocationFrom: 'Local de Estoque de Origem',
      supplyPlaceLocationTo: 'Local de Estoque de Destino',
      observation: 'Observações',

      product: 'Produto',
      lot: 'Lote',
      originFunds: 'Saldo Origem',
      qntTransfers: 'Quant. Transf.',
      destinyFunds: 'Saldo Destino',
    },
    placeHolders: {
      supplyPlaceLocationFrom: 'Selecione um local de estoque',
      supplyPlaceLocationTo: 'Selecione um local de estoque',
      observation: 'Observações sobre a transferência',
      product: 'Pesquise por um produto',
      lot: 'Pesquise por um lote',
    },
    actions: {
      cancel: 'Cancelar',
      save: 'Salvar',
      register: 'Cadastrar',
      registerLoading: 'Cadastrando',
      addItem: 'Adicionar item',
    },
    toolTips: {
      originBalance: 'Referente ao saldo no momento da transferência.',
      destinationBalance: 'Referente ao saldo no final da transferência.',
      currentBalanceNegative:
        'Não é possível selecionar uma quantidade maior que a disponível na origem.',
    },
    backModal: {
      title: 'Deseja sair sem salvar?',
      body: 'Você tem certeza que deseja sair da página sem salvar? As alterações que você fez talvez não sejam salvas.',
      okButton: 'Sair sem salvar',
    },
    changeStorageLocationModal: {
      title: 'Deseja alterar o Local de Estoque de Origem?',
      description:
        'Você tem certeza que deseja alterar o **Local de Estoque de Origem**? Os dados serão reiniciados.',
    },
  },

  group: {
    modal: {
      cannotDelete: {
        title: '“{{group}}” não pode ser excluido!',
        batchTitle: 'Não foi possível excluir {{group}} grupos!',

        subTitle: 'Não é possível excluir o grupo “{{group}}”.',
        batchSubTitle: 'Não foi possível excluir os grupos: ',

        description:
          'O grupo tem **{{products}} produto{{products_plural}}** e **{{subgroups}} subgrupo{{subgroups_plural}}** vinculados a ele. Desvincule todos os produtos e subgrupos para conseguir excluir o grupo.',
        batchDescription:
          'Os grupos têm produtos e/ou subgrupos vinculados a eles.\nDesvincule todos os produtos e subgrupos para conseguir excluí-los.',

        ok: 'Ver produtos',
      },
      create: {
        required: 'Descrição deve ser informada',
      },
    },
  },

  subgroup: {
    modal: {
      cannotDelete: {
        title: '“{{subGroup}}” não pode ser excluido!',
        batchTitle: 'Não foi possível excluir {{subGroup}} subgrupos!',

        subTitle: 'Não é possível excluir o subgrupo “{{subGroup}}”.',
        batchSubTitle: 'Não foi possível excluir os subgrupos: ',
        descriptionOneProduct:
          'O subgrupo tem **{{products}} produto** vinculado a ele. Desvincule todos os produtos para conseguir excluir o subgrupo.',
        descriptionManyProducts:
          'O subgrupo tem **{{products}} produtos** vinculados a ele. Desvincule todos os produtos para conseguir excluir o subgrupo.',
        batchDescription:
          'Os subgrupos têm produtos vinculados a eles. Desvincule todos os produtos para conseguir excluir os subgrupos.',

        ok: 'Ver produtos',
      },
    },
  },

  product: {
    modal: {
      cannotDelete: {
        title: 'Não foi possível excluir o produto “{{product}}”',
        batchTitle: 'Não foi possível excluir {{product}} produtos',

        subTitle: 'Não é possível excluir o produto “{{product}}”.',
        batchSubTitle: 'Não é possível excluir os produtos:',

        description:
          'Esse produto já teve movimentação dentro do sistema e por isso não é possível a sua exclusão.',
        batchDescription:
          'Esses produtos já tiveram movimentação dentro do sistema e por isso não é possível suas exclusões.',

        warningFooter:
          'Caso não queira mais utilizar esse produto, mude o status dele para “Inativo”.',
        batchWarningFooter:
          'Caso não queira mais utilizar esses produtos, mude os status deles para “Inativo”.',
      },
    },
  },

  productMessage: {
    list: {
      newButton: 'Nova Mensagem do Produto',
      placeholder: 'Pesquise por uma mensagem do produto',
      description: 'Mensagem do Produto',
      products: 'Produtos Vinculados',
      deleteModal: 'A mensagem do produto foi excluida com sucesso',
    },

    create: {
      label: 'Mensagem do Produto',
      placeholder: 'Insira a Mensagem do Produto',
      primaryLabel: 'Exibir aviso no sistema',
      secondaryLabel: 'Exibir na impressão',
      checkBox1: 'Venda',
      checkBox2: 'Rotulagem',
      checkBox3: 'Ficha de pesagem',
      checkBox4: 'Rótulo',
      required: 'Mensagem do Produto é Obrigatório',

      erros: {
        requiredNameMessage: 'Mensagem do Produto é obrigatório',
        errorMessageNameExist:
          'Já existe uma mensagem do produto com essa descrição',
      },
    },

    details: {
      deleteTitle: 'Excluir',
      deleteSingleTitle: 'a mensagem do produto',
      deleteManyTitle: 'as mensagens do produto',

      linkedListProduct: {
        code: 'Código',
        productName: 'Nome do Produto',
        class: 'Classe',
        group: 'Grupo',
        subGroup: 'Subgrupo',
        status: 'Status',
      },

      modalRemoveLinked: {
        messageTitle: 'dessa mensagem do produto',
        messageStartBody:
          'Você tem certeza que deseja remover o produto {{action}}',
        messageFinishBody: 'da mensagem do produto: ',
      },
    },

    modalCannotDelete: {
      messageTitle: 'a mensagem do produto',
      messageSubtitle: 'a mensagem do produto:',
      messageManySubtitle: 'as mensagens do produto:',
      messageReasonCannotDelete:
        'Essa mensagem já teve movimentação dentro do sistema ou está associada a algum produto e por isso não é possível a sua exclusão.',
      messageWarningCannotDelete:
        'Caso não queira utilizar essa mensagem do produto, mude o status dela para "inativa".',
      messageManyTitles: 'as mensagens do produto',
      messageManySubtitleSecond: 'as mensagens do produto',
      messageManyReasonCannotDelete:
        'Essas mensagens já tiveram movimentação dentro do sistema ou estão associadas a algum produto e por isso não é possível a sua exclusão.',
      messageManyWarningCannotDelete:
        'Caso não queira utilizar essas mensagens do produto, mude o status delas para "inativa".',
    },
  },

  inventory: {
    list: {
      newButton: 'Novo Inventário',
      code: 'Código',
      supplyPlaces: 'Locais de Estoque',
      creationDate: 'Data de Criação',
      status: 'Status',
      responsible: 'Responsável',

      released: 'Lançamento',
      canceled: 'Cancelado',
      waitingRelease: 'Aguardando Lançamento',
      finished: 'Finalizado',
      conference: 'Conferência',
    },
    create: {
      info: 'Informações',
      supplyPlaces: 'Locais de Estoque',
      groups: 'Grupos',
      subgroups: 'Subgrupos',
      supplyPlacesPlaceholder: 'Selecione o Local de Estoque',
      groupsPlaceholder: 'Selecione o Grupo',
      subgroupsPlaceholder: 'Selecione o Subgrupo',
      hideAnotherValue: 'Ocultar Valores de Quantidades no Lançamento',
      hiderAnotherValueTooltip:
        'Ativar caso deseje que os valores de quantidades dos produtos nos locais iventariados sejam ocultados no lançamento',
      hideAnotherValueImpression: 'Ocultar Valores de Quantidades na Impressão',
      hiderAnotherValueImpressionTooltip:
        'Ativar caso deseje que os valores de quantidades dos produtos nos locais iventariados sejam ocultados na impressão',

      register: 'Cadastrar Inventário',
    },

    details: {
      deleteInventory: 'Excluir o inventário',
      areYouSureDeleteInventory:
        'Você tem certeza que deseja excluir o inventário {{inventario}}?',

      dateCreation: 'Data de criação',
      responsible: 'Responsável',
      code: 'Código',
    },

    release: {
      waitingRelease: 'Aguardando Lançamento',
      startRelease: 'Iniciar Lançamento',
      inventoryCanceled: 'Cancelado',
      inventoryConference: 'Conferência',
      releaseDate: 'Data de Lançamento',
      cancellationDate: 'Data de Cancelamento',
      responsible: 'Responsável pelo Lançamento',
      blocked: 'bloqueados',
      previousReleases: 'Lançamentos Anteriores',
      previousReleasesLabel: 'Lançamento #',
      previousReleasesExtraLabel: '(Atual)',
      startReleaseTextStart:
        'Ao iniciar o lançamento, os locais de estoque serão',
      startReleaseTextEnd: 'até a finalização do inventário. Deseja continuar?',
      finish: 'Finalizar',
      release: 'Lançamento',
      searchForAResponsibleOperator: 'Procure por um operador responsável',
      code: 'Cód.',
      product: 'Produto',
      lot: 'Lote',
      invoice: 'Nota Fiscal',
      quantity: 'Qtd.',
      count: 'Contagem',
      responsibleTitle: 'Resp.',
      difference: 'Diferença',
      responsibleOperator: 'Operador Responsável',
      addProduct: 'Adicionar Produto',
      quanty: 'Quantidade',
      dropdownOptions: {
        title: 'Mais ações',
        save: 'Salvar Rascunho',
        print: 'Imprimir Tudo',
        cancel: 'Cancelar Lançamento',
      },
      modal: {
        cancel: {
          title: 'Cancelar Lançamento',
          body: 'Deseja **cancelar** o lançamento? O inventário retornará ao status Aguardando Lançamento.',
          confirmButton: 'Cancelar Lançamento',
        }
      }
    },
    dropdownOptions: {
      releaseOption: 'Cancelar Inventário'
    },
    modal: {
      cancelInventory: {
        title: 'Cancelar o inventário #{{inventoryCode}}',
        bodyInitial: 'Você tem certeza que deseja cancelar o inventário **#{{inventoryCode}}**?',
        bodyMiddle: 'Essa ação irá **liberar** a movimentação de produtos nos locais de estoque:',
        footer: 'Essa ação é **permanente** e não poderá ser desfeita!',
        confirmButton: 'Cancelar Inventário'
      }
    }
  },
};
