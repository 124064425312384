import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_INVENTORY } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { IGetInventory } from '@/Data/Interfaces/response/Inventory/InventoryResponse';

export const useGetInventory = (inventoryId: string) => {
  const { data: inventory } = useControllerQueryApiHook<IGetInventory>({
    uniqId: FETCH_GET_INVENTORY,
    entityApi: InventoryAPI.getInventory,
    externalIds: [inventoryId],
  });

  return { inventory };
};
