import { FC } from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';

export const BirthDay: FC = () => {
  const { t } = useTranslation();

  return (
    <Col flex="15%">
      <TextInputWithMask
        name="dataNascimento"
        placeHolder={`${t('common.exampleAbbreviation')}: 01/03/1988`}
        label={t('prescribers.createPage.info.birthday')}
        mask="dataMask"
        leftIcon="calendar"
      />
    </Col>
  );
};
