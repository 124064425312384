import { useTranslation } from 'react-i18next';
import { Button, Form } from '@/Components';
import { FinanceSaleCard } from '../../../Components/FinanceProductCard';
import { FinanceProductSchema } from './FinanceProduct.schema';
import { FinanceInitialValues } from './InitialValues';
import { IFinanceProductTab } from './interfaces';
import { useFinanceCallAPI } from './Hooks/useFinanceCallAPI';
import { useFinanceFunctions } from './Hooks/useFinanceFunctions';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';
import { App } from 'antd';

import styles from './Finance.module.scss';

export const FinanceTab: React.FC<IFinanceProductTab> = ({
  disabledForm,
  toogleDisabled,
}) => {
  const { t } = useTranslation();
  const app = App.useApp();
  
  const {
    editFinancialProduct,
    product
  } = useFinanceCallAPI();

  const {
    onSubmit
  } = useFinanceFunctions({
    product,
    editFinancialProduct,
    toogleDisabled,
    disabledForm
  });

  return (
    <>
      <Form
        initialValues={(product && FinanceInitialValues(product)) || undefined}
        onSubmit={onSubmit}
        schema={FinanceProductSchema}
      >
        {(prop) => {
          return (
            <>
              <FinanceSaleCard
                disabledForm={disabledForm}
                product={product || undefined}
              />
              {!disabledForm && (
                <div className={styles['footer-button']}>
                  <Button
                    type="secondary"
                    children={t('products.create.cancel')}
                    onClick={() => {
                      handleCancelEdit(prop, app, () => toogleDisabled(!disabledForm))
                    }}
                  />
                  <Button
                    type="primary"
                    children={
                      editFinancialProduct.isLoading
                        ? t('products.create.saveLoading')
                        : t('products.create.save')
                    }
                    loading={editFinancialProduct.isLoading}
                    htmlType="submit"
                    disabled={!prop.dirty}
                  />
                </div>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};
