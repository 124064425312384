import * as yup from 'yup';
import i18n from '../../../../../../../Components/i18n';

export const StandardFormulaDetailSchema = (
   validateName: (
     produtoExternalId: string | null | undefined,
     callback: (success: boolean) => void
   ) => void,
   t: (key: string) => string
 ) => yup.object().shape({
  produtoExternalId: yup
  .string()
  .nullable()
  .required(
    i18n.t('production.standardFormula.create.errors.requiredProduct')
  )
  .test(
    'checkDuplications',
    t('production.standardFormula.details.errors.errorStandardFormulaNameExist'),
    function (value) {
      return new Promise((resolve) => {
        validateName(
          value, 
          (success) => {
            resolve(success);
          }
        );
      });
    }
  ),

  quantidadePadrao: yup
    .string()
    .required(
      i18n.t('production.standardFormula.details.errors.requiredAmount')
    ),
  formulaPadraoDesmembramento: yup
    .string()
    .required(
      i18n.t(
        'production.standardFormula.details.errors.requiredDismembermentType'
      )
    ),
  produtos: yup.array().of(
    yup.object().shape({
      tipoItem: yup
        .string()
        .required(
          i18n.t(
            'production.standardFormula.details.errors.requiredProductType'
          )
        ),
      quantidade: yup
        .string()
        .required(
          i18n.t(
            'production.standardFormula.details.errors.requiredAmountProduct'
          )
        ),
    })
  ),
});
