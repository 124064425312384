import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { BodyHandler, TitleHandler } from '../ProductForm.utils';
import { IDeleteModalTable } from '@/Components/Table';
import { Placements } from '@/Components/Tooltip';

export const useProductFormMemorizations = ({
  editProductStatus,
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addProductHotkey = getShortcut('addProduct');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteProductHotkey = getShortcut('deleteSelected');
  const editProductHotkey = getShortcut('editSelected');

  const headerOptions = {
    nameKey: 'descricao',
    hasSearch: true,
    searchPlaceholder: t('products.productList.searchPlaceholder'),
    newButton: {
      label: t('products.productList.newButton'),
      onClick: () => history.push(`/inventory/products/create`),
      permission: {
        permission: 3003,
        tooltip: 'noPermission.supply.products.create',
      },
      dataTestId: 'create-product',
      tooltip: {
        title: addProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    deleteButton: {
      permission: {
        permission: 3012,
        tooltip: 'noPermission.supply.groups.delete',
      },
      dataTestId: 'delete-product-button',
      tooltip: {
        title: deleteProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    editButton: {
      tooltip: {
        title: editProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'edit-product-status-button',
      options: [
        {
          label: t('common.status'),
          isStatusOnly: true,
          dataTestId: 'edit-status-button',
          key: 's',
        },
      ],
      permission: {
        permission: 3011,
        tooltip: 'noPermission.supply.groups.edit',
      },
    },
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(`/inventory/products/${x.externalId}/details`),
    permission: {
      permission: 3002,
      tooltip: 'noPermission.supply.products.detail',
    },
  };

  const editModal = useMemo(
    () => ({
      okButtonName: editProductStatus.isLoading ? t('common.changing') : '',
      okButtonDataTestId: 'submit-product-status',
    }),
    [editProductStatus, t]
  );

  const deleteModal: IDeleteModalTable | undefined = useMemo(
    () => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
        okButtonDataTestId: 'submit-delete-product',
      },
    }),
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    editModal,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
