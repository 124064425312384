import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { history } from '@/Store';
import { useMemo } from 'react';
import { DeleteModalBody } from '../Modal/DeleteModalBody';
import { IDeleteModalTable } from '@/Components/Table';
import { Placements } from '@/Components/Tooltip';

export const useAssociatedProductMemorizations = ({
  selectedRowsOnTable,
  currentProduct,
  productExternalId,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addNewAssociatedProductHotkey = getShortcut('addNewAssociatedProduct');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteAssociatedProductHotkey = getShortcut('deleteAssociatedProduct');

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      newButton: {
        onClick: () => history.push('associated/createAssociated'),
        label: t('products.associated.newAssociatedProduct'),
        permission: {
          permission: PermissionEnum.Estoque_ProdutosDiluido_Cadastrar,
          tooltip: 'noPermission.supply.products.createDiluted',
        },
        dataTestId: 'new-associated-product-button',
        tooltip: {
          title: addNewAssociatedProductHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      deleteButton: {
        label: t('common.remove'),
        permission: {
          permission: PermissionEnum.Estoque_ProdutosDiluido_Excluir,
          tooltip: 'noPermission.supply.products.removeDiluted',
        },
        dataTestId: 'delete-associated-product-button',
        tooltip: {
          title: deleteAssociatedProductHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      searchPlaceholder: t(
        'products.associated.searchForAssociatedProductPlaceholder'
      ),
    }),
    [t]
  );

  const deleteModal: IDeleteModalTable | undefined = useMemo(
    () => ({
      title:
        selectedRowsOnTable.length === 1
          ? t('modal.deleteModal.single.associatedProductTitle')
          : t('modal.deleteModal.many.associatedProductTitle'),
      body: (
        <DeleteModalBody
          selectedRowsOnTable={selectedRowsOnTable}
          currentProduct={currentProduct ? currentProduct.descricao : ''}
        />
      ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      okButtonName: t('common.remove'),
    }),
    [selectedRowsOnTable, currentProduct]
  );

  const rowOptions = {
    onRowDoubleClick: (x: any) => {
      history.push(
        `/inventory/products/${productExternalId}/associated/${x.externalId}/associatedDetails`
      );
    },
  };

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    deleteModal,
    rowOptions,
    tableHeaderHotKeyTooltip,
  };
};
