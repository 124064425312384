export const adminEN = {
  user: 'Users',

  createUserPage: {
    personalData: 'Personal data',
    personalDataCard: {
      name: 'Name',
      namePlaceholder: 'First name',

      lastName: 'Last name',
      lastNamePlaceholder: 'Last name',

      errors: {
        requiredName: 'Name is required',
        requiredLastName: 'Last name is required',
      },
    },

    Access: 'Access',
    AccessDataCard: {
      email: 'E-mail',
      emailPlaceholder: 'email@example.com',

      alternativeEmail: 'Alternative e-mail',
      alternativeEmailPlaceholder: 'email@example.com',

      group: 'Groups',
      groupPlaceholder: 'Search for a group',

      errors: {
        requiredEmail: 'E-mail is required',
        invalidEmail: 'Invalid e-mail',
        invalidAlternativeEmail: 'Cannot be the same as the main e-mail',
        requiredGroup: 'Group is required',
      },
    },

    footerButton: {
      cancel: 'Cancel',
      submit: 'Create user',
    },

    success: 'Created user!',
  },

  editUserPage: {
    shortcuts: {
      editStatus: 'Edit status',
      moreActions: 'More actions',
      goToUserDetails: 'Go to user details',
      goToUserPermissions: 'Go to user permissions',
      editUserDetails: 'Edit user details',
      denyPermission: 'Deny permission',
      allowPermission: 'Allow permission',
      inheritedPermission: 'Set permission as inherited',
    },
    tab: {
      details: 'Details',
      permissions: 'Permissions',

      buttonActions: {
        title: 'More actions',
        changePassword: 'Change Password',
        changeStatus: 'Change status',
        delete: 'Delete',
      },
    },
    personalData: 'Personal data',
    personalDataForm: {
      name: 'Name',
      namePlaceholder: 'Name',

      lastName: 'Last name',
      lastNamePlaceholder: 'Last name',

      birthDate: 'Birth date',
      birthDatePlaceholder: 'Birth date',

      telephone: 'Telephone',
      telephonePlaceholder: 'Telephone',

      email: 'Email',
      emailPlaceholder: 'Email',

      alternativeEmail: 'Alternative email',
      alternativeEmailPlaceholder: 'Alternative email',

      group: 'Groups',
      groupPlaceholder: 'Groups',

      errors: {
        requiredName: 'Name is required',
        requiredLastName: 'Last name is required',

        invalidDateOfBirth: 'Invalid date of birth',

        requiredEmail: 'Email is required',
        invalidEmail: 'Invalid email',
        invalidAlternativeEmail: 'Cannot be the same as the main emai',

        requiredGroup: 'Group is required',

        inalidBirthdate: 'Invalid birthdate',
      },
    },
    footerButtons: {
      editDetails: 'Edit details',

      cancel: 'Cancel',
      save: 'Save',
    },

    modal: {
      changePasswordModal: {
        title: 'Change password',

        newPassword: 'New password',
        newPasswordPlaceholder: 'New password',
        confirmPassword: 'Confirm password',
        confirmPasswordPlaceholder: 'Confirm password',

        okButton: 'Change',
        cancelButton: 'Cancel',
      },
      deleteUserModal: {
        title: 'Delete',
        initialDescription:
          'Are you sure that you want to delete the user {{user}}',
        finalDescription: 'This action is permanent, and cannot be undone!',
        inativeButton: 'Delete',
      },
    },

    permission: {
      sucessAlert: 'Permission updated with sucess!',
      inactivactedWithSuccess: 'Permission deactivated',
      activatedWithSuccess: 'Permission activated',
      descriptionInactivatedWithSuccess: 'The permission “{x}” is deactivated.',
      descriptionActivatedWithSuccess: 'The permission “{x}” is activated.',

      descriptionManyActivatedWithSuccess:
        'The permission “{x}” and other {num} required permission was activated.',
      descriptionManyInactivatedWithSuccess:
        'The permission “{x}” and other {num} required permission was deactivated.',
      modal: {
        modalTitle: 'Required Permissions',
        title1: 'The permission ',
        title2: ' needs another',
        permissions: 'permissions:',
        footer:
          'If you enable this permission, all the required permissions will be enabled. Do you want to proceed?',
        altFooter:
          'By deactivating this permission, the permissions that require this permission will also be automatically deactivated. Do you want to proceed?',
        okButton: 'Active Permission',
        okActiveButton: 'Active Permission',
        okInactiveButton: 'Inactive Permission',
      },
    },

    message: {
      success: 'User edited!',
    },
  },

  createUserGroupPage: {
    details: 'Details',
    detaildCard: {
      name: 'Name',
      namePlaceholder: 'Group name',

      description: 'Description',
      descriptionPlaceholder: 'Group description',

      users: 'Users',
      usersPlaceholder: `Search for a user's name or email`,
    },

    errors: {
      requiredName: 'Name is required',
    },

    footerButton: {
      cancel: 'Cancel',
      save: 'Save',
    },
  },

  userList: {
    shortcuts: {
      addUser: 'Add user',
      selectAll: 'Select all',
      search: 'Search in the list',
      deleteSelected: 'Delete selected items',
      editSelected: 'Edit selected items',
      editStatus: 'Edit status',
    },
    searchPlaceholder: 'Search by username, email, status or groups',
    tableTitle: {
      name: 'Name',
      email: 'E-mail',
      status: 'Status',
      statusType: {
        active: 'Active',
        inactive: 'Inactive',
      },
      group: 'Group',
    },

    deleteUserModal: {
      title: {
        delete: 'Delete',
        users: 'users',
      },

      bodyDescription:
        'Are you sure you want to **{{deleteColorChange}}** the user {{user}}?',
      bodyDescriptionManyUsers:
        'Are you sure you want to **{{deleteColorChange}}** {{amount}} users**?',

      deleteColorChange: 'DELETE',

      success: 'User deleted!',
    },
    editUserModal: {
      title: 'Inactivate',
      users: 'users',
      initialDescription: 'Are you sure that you want to inactivate',
      finalDescription:
        'This action will prevent the user from accessing the system!',
      finalDescriptionManyUsers:
        'This action will prevent the users from accessing the system!',

      inativeButton: 'Inactivate',
    },
  },

  userGroupList: {
    deleteUserGroupModal: {
      description:
        'Are you sure you want to delete the user group {{userGroup}}?',
    },
    shortcuts: {
      addUser: 'Add user group',
      selectAll: 'Select all',
      search: 'Search in the list',
      deleteSelected: 'Delete selected items',
      editSelected: 'Edit selected items',
      editStatus: 'Edit status',
    },
  },

  editUserGroups: {
    shortcuts: {
      editStatus: 'Edit status',
      moreActions: 'More actions',
      goToUserDetails: 'Go to user group details',
      goToUserUsers: 'Go to user group users',
      goToUserPermissions: 'Go to user group permissions',
      editUserDetails: 'Edit user group',
      addUserToGroup: 'Add user to group',
      removeSelectedUsers: 'Remove selected users from group',
    },
    searchPlaceholder: 'Search for a user group',
    tab: {
      details: 'Detail',
      user: 'Users',
      permissions: 'Permissions',
      buttonActions: {
        title: 'More actions',
        delete: 'Delete',
      },
    },
    detailForm: {
      name: 'Name',
      namePlaceholder: 'Group name',
      description: 'Description',
      descriptionPlaceholder: 'Description of the group',
    },

    userForm: {
      add: 'Add',
      searchPlaceholder: 'Search for an user',
      nameColumns: 'Name',
      emailColumns: 'Email',

      placeholder: `Search for a user's name or email`,
    },

    deleteModalBody: {
      deleteUserGroup:
        'Are you sure you want to delete the operator group {{userGroup}}?',
      deleteUser:
        'Are you sure you want to remove {{userName}} from the group {{financeName}}?',
    },

    message: {
      statusSuccess: 'Updated status',
      deleteSuccess: 'Successfully deleted!',
    },
  },

  companyList: {
    searchPlaceholder: 'Search by company name, CNPJ, City/UF or Type',
    tableTitle: {
      fantasyName: 'Fantasy name',
      cnpj: 'CNPJ',
      city: 'City',
      type: 'Type',
      responsible: 'Responsible',
    },
    changeResponsibleUserSuccess: 'Responsible updated!',
  },

  editCompany: {
    shortcuts: {
      editInfo: 'Edit data',
      editAddress: 'Edit address',
      editUser: 'Edit user',
    },
  },
};

export const adminPT = {
  user: 'Usuarios',

  createUserPage: {
    personalData: 'Dados pessoais',
    personalDataCard: {
      name: 'Nome',
      namePlaceholder: 'Primeiro nome',

      lastName: 'Sobrenome',
      lastNamePlaceholder: 'Sobrenome',

      errors: {
        requiredName: 'Nome é obrigatório',
        requiredLastName: 'Sobrenome é obrigatório',
      },
    },

    Access: 'Acesso',
    AccessDataCard: {
      email: 'E-mail',
      emailPlaceholder: 'email@example.com',

      alternativeEmail: 'E-mail alternativo',
      alternativeEmailPlaceholder: 'email@example.com',

      group: 'Grupos',
      groupPlaceholder: 'Pesquise por um grupo',

      errors: {
        requiredEmail: 'E-mail é obrigatório',
        invalidEmail: 'E-mail inválido',
        invalidAlternativeEmail: 'Não pode ser igual ao e-mail principal',
        requiredGroup: 'Grupo é obrigatório',
      },
    },

    footerButton: {
      cancel: 'Cancelar',
      submit: 'Cadastrar usuário',
    },

    success: 'Usuário criado!',
  },

  editUserPage: {
    shortcuts: {
      editStatus: 'Editar status',
      moreActions: 'Mais ações',
      goToUserDetails: 'Ir para detalhes do usuário',
      goToUserPermissions: 'Ir para permissões do usuário',
      editUserDetails: 'Editar detalhes do usuário',
      denyPermission: 'Negar permissão',
      allowPermission: 'Permitir permissão',
      inheritedPermission: 'Definir permissão como herdada',
    },
    tab: {
      details: 'Detalhes',
      permissions: 'Permissões',

      buttonActions: {
        title: 'Mais ações',
        changeStatus: 'Alterar status',
        changePassword: 'Alterar Senha',
        delete: 'Excluir',
      },
    },
    personalData: 'Dados pessoais',
    personalDataForm: {
      name: 'Nome',
      namePlaceholder: 'Nome',

      lastName: 'Sobrenome',
      lastNamePlaceholder: 'Sobrenome',

      birthDate: 'Data de Nascimento',
      birthDatePlaceholder: 'Data de Nascimento',

      telephone: 'Telefone',
      telephonePlaceholder: 'Telefone',

      email: 'E-mail',
      emailPlaceholder: 'E-mail',

      alternativeEmail: 'E-mail alternativo',
      alternativeEmailPlaceholder: 'E-mail alternativo',

      group: 'Grupos',
      groupPlaceholder: 'Grupos',

      errors: {
        requiredName: 'Nome é obrigatório',
        requiredLastName: 'Sobrenome é obrigatório',

        invalidDateOfBirth: 'Data de nascimento inválida',

        requiredEmail: 'Email é obrigatorio',
        invalidEmail: 'Email inválido',
        invalidAlternativeEmail: 'Não pode ser igual ao email principal',

        requiredGroup: 'Grupo é obrigatório',

        inalidBirthdate: 'Data de nascimento inválida',
      },
    },
    footerButtons: {
      editDetails: 'Editar detalhes',

      cancel: 'Cancelar',
      save: 'Salvar',
    },

    modal: {
      changePasswordModal: {
        title: 'Alterar senha',

        newPassword: 'Nova senha',
        newPasswordPlaceholder: 'Nova senha',

        confirmPassword: 'Confirma a senha',
        confirmPasswordPlaceholder: 'Confirma a senha',

        okButton: 'Alterar',
        cancelButton: 'Cancelar',
      },
      deleteUserModal: {
        title: 'Excluir',
        initialDescription:
          'Você tem certeza que deseja excluir o usuário {{user}}',
        finalDescription: 'Essa ação é permanente e não pode ser desfeita!',
        inativeButton: 'Excluir',
      },
    },

    permission: {
      sucessAlert: 'Permissão alterada com sucesso!',
      inactivactedWithSuccess: 'Permissão inativada',
      activatedWithSuccess: 'Permissão ativada',
      descriptionInactivatedWithSuccess: 'A permissão de “{x}” foi inativada.',
      descriptionActivatedWithSuccess: 'A permissão de “{x}” foi ativada.',
      descriptionManyActivatedWithSuccess:
        'A permissão de “{x}” e {num} outras permissões requeridas foram ativadas.',
      descriptionManyInactivatedWithSuccess:
        'A permissão de “{x}” e {num} outras permissões requeridas foram inativadas.',
      modal: {
        modalTitle: 'Permissões requeridas',
        title1: 'A permissão de ',
        title2: ' requer outras',
        permissions: 'permissões:',
        footer:
          'Ao ativar essa permissão, as permissões requeridas serão automaticamente ativadas também. Você deseja continuar?',
        altFooter:
          'Ao inativar essa permissão, as permissões que requerem essa permissão serão automaticamente inativadas também. Você deseja continuar?',
        okButton: 'Ativar permissões',
        okActiveButton: 'Ativar permissões',
        okInactiveButton: 'Inativar permissões',
      },
    },

    message: {
      success: 'Usuário editado!',
    },
  },

  createUserGroupPage: {
    details: 'Detalhes',
    detaildCard: {
      name: 'Nome',
      namePlaceholder: 'Nome do grupo',

      description: 'Descrição',
      descriptionPlaceholder: 'Descrição do grupo',

      users: 'Usuários',
      usersPlaceholder: `Pesquise o nome ou o e-mail de um usuário`,
    },

    errors: {
      requiredName: 'Nome é obrigatório',
    },

    footerButton: {
      cancel: 'Cancelar',
      save: 'Salvar',
    },
  },

  userList: {
    shortcuts: {
      addUser: 'Adicionar novo usuário',
      selectAll: 'Selecionar todos',
      search: 'Pesquisar na lista',
      deleteSelected: 'Excluir selecionados',
      editSelected: 'Editar selecionados',
      editStatus: 'Editar status',
    },
    searchPlaceholder:
      'Pesquise pelo nome do usuário, e-mail, status ou grupos',
    tableTitle: {
      name: 'Nome',
      email: 'E-mail',
      status: 'Status',
      statusType: {
        active: 'Ativo',
        inactive: 'Inativo',
      },
      group: 'Grupo',
    },

    deleteUserModal: {
      title: {
        delete: 'Excluir',
        users: 'usuários',
      },

      bodyDescription:
        'Você tem certeza que deseja **{{deleteColorChange}}** o(a) usuário(a) {{user}}?',
      bodyDescriptionManyUsers:
        'Você tem certeza que deseja **{{deleteColorChange}}** **{{amount}} usuários**?',

      deleteColorChange: 'EXCLUIR',

      success: 'Usuário deletado!',
    },
    editUserModal: {
      title: 'Inativar',
      users: 'usuários',
      initialDescription: 'Você tem certeza que deseja inativar',
      finalDescription:
        'Essa ação impedirá o(a) usuário(a) de acessar o sistema!',
      finalDescriptionManyUsers:
        'Essa ação impedirá os usuários de acessar o sistema!',

      inativeButton: 'Inativar',
    },
    couldNotExcludeModal: {
      manyTitle1: 'Não foi possível excluir ',
      manyTitle2: ' empresas!',
      singleTitle: 'Não foi possível excluir ',
      singleContent1:
        'A empresa que você tentou excluir já teve movimentações no sistema e por isso não foi possível concluir a ação.',
      singleContent2:
        'Para desativar o acesso da empresa, mude o status dela para',
      inactived: 'Inativo',

      manyContent1:
        'As seguintes empresas que você tentou excluir já tiveram movimentações no sistema e por isso não foi possível concluir a ação:',
      manyContent2:
        'Para desativar o acesso da empresa, mude o status dela para',
    },
  },

  userGroupList: {
    deleteUserGroupModal: {
      description:
        'Você tem certeza que deseja excluir o grupo de usuários {{userGroup}}?',
    },
    shortcuts: {
      addUser: 'Adicionar novo grupo de usuários',
      selectAll: 'Selecionar todos',
      search: 'Pesquisar na lista',
      deleteSelected: 'Excluir selecionados',
      editSelected: 'Editar selecionados',
      editStatus: 'Editar status',
    },
  },

  editUserGroups: {
    shortcuts: {
      editStatus: 'Editar status',
      moreActions: 'Mais ações',
      goToUserDetails: 'Ir para detalhes do grupo de usuários',
      goToUserUsers: 'Ir para usuários do grupo de usuários',
      goToUserPermissions: 'Ir para permissões do grupo de usuários',
      editUserDetails: 'Editar grupo de usuários',
      addUserToGroup: 'Adicionar usuário ao grupo',
      removeSelectedUsers: 'Remover usuários selecionados do grupo',
    },
    searchPlaceholder: 'Pesquise por um grupo de usuários',
    tab: {
      details: 'Detalhes',
      user: 'Usuários',
      permissions: 'Permissões',
      buttonActions: {
        title: 'Mais Ações',
        delete: 'Excluir',
      },
    },

    detailForm: {
      name: 'Nome',
      namePlaceholder: 'Nome do grupo',
      description: 'Descrição',
      descriptionPlaceholder: 'Descrição do grupo',
    },

    userForm: {
      add: 'Adicionar',
      searchPlaceholder: 'Pesquise por um usuário',
      nameColumns: 'Nome',
      emailColumns: 'E-mail',

      placeholder: 'Pesquise o nome ou o e-mail de um usuário',
    },

    deleteModalBody: {
      deleteUserGroup:
        'Você tem certeza que deseja excluir o grupo de operadores {{userGroup}}?',
      deleteUser:
        'Você tem certeza que deseja remover {{userName}} do grupo {{financeName}}?',
    },

    message: {
      statusSuccess: 'Status atualizado',
      deleteSuccess: 'Excluído com sucesso!',
    },
  },

  companyList: {
    searchPlaceholder: 'Pesquise pelo nome de uma empresa, CNPJ ou Cidade/UF',
    tableTitle: {
      fantasyName: 'Nome Fantasia',
      cnpj: 'CNPJ',
      city: 'Cidade/UF',
      type: 'Tipo',
      responsible: 'Responsável',
    },
    changeResponsibleUserSuccess: 'Responsável atualizado!',
  },

  editCompany: {
    shortcuts: {
      editInfo: 'Editar dados',
      editAddress: 'Editar endereço',
      editUser: 'Editar usuário',
    },
  },
};
