import { App, Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form } from '@/Components';
import _ from 'lodash';
import { IDetailsForm } from './interfaces';
import { InputDescriptionGroup } from './Components/InputDescriptionGroup';
import { useDetailsFormCallAPI } from './Hooks/useDetailsFormCallAPI';
import { useDetailsFormFunctions } from './Hooks/useDetailsFormFunctions';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';

import styles from './DetailsForm.module.scss';

export const DetailProductGroupForm: FC<IDetailsForm> = ({
  disabled,
  toogleDisabled,
}) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const { data, editGroup } = useDetailsFormCallAPI();

  const { onSubmit } = useDetailsFormFunctions({
    data,
    editGroup,
    toogleDisabled,
    disabled,
  });

  return (
    <>
      <Form
        initialValues={{
          ...data,
        }}
        onSubmit={onSubmit}
      >
        {(props) => {
          return (
            <>
              <Card title={t('common.details')} withoutCollapse>
                <Row justify="space-between">
                  <Col span={24}>
                    <InputDescriptionGroup
                      disabled={disabled}
                      initialDescription={data?.descricao}
                    />
                  </Col>
                </Row>
                {!disabled && (
                  <div className={styles['footer-button']}>
                    <Button
                      type="secondary"
                      children={t('admin.editUserPage.footerButtons.cancel')}
                      onClick={
                        () => handleCancelEdit(props, app, () => toogleDisabled(!disabled))
                      }
                    />
                    <Button
                      type="primary"
                      children={t('admin.editUserPage.footerButtons.save')}
                      htmlType="submit"
                      disabled={!props.dirty}
                      dataTestId="submit-edit-group-button"
                    />
                  </div>
                )}
              </Card>
            </>
          );
        }}
      </Form>
    </>
  );
};
