import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { subGroupCollums } from './SubGroupForm.columns';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { CreateSubGroupModal } from './Modal/SubGroupModal';
import { PageLoading } from '@/Layouts/PageLoading';
import { useSubGroupFormStates } from './Hooks/useSubGroupFormStates';
import { useSubGroupFormCallAPI } from './Hooks/useSubGroupFormCallAPI';
import { useSubGroupFormMemorizations } from './Hooks/useSubGroupFormMemorizations';
import { useSubGroupFormFunctions } from './Hooks/useSubGroupFormFunctions';
import { useSubGroupModalCallAPI } from './Modal/SubGroupModal/Hooks/useSubGroupModalCallAPI';
import { useGroupSubGroupsListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/groups/subgroups';

export const SubGroupForm: React.FC = () => {
  const { t } = useTranslation();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleModal,
    setVisibleModal,
    visibleEditModal,
    setVisibleEditModal,
    selectedItems,
  } = useSubGroupFormStates();

  const {
    deleteSubGroup,
    subGroupList,
    isLoading,
    productGroup,
    refetchGroupList,
  } = useSubGroupFormCallAPI();

  const { getShortcut } = useGroupSubGroupsListShortcuts(t, {});

  const { createSubGroup, editSubGroup } = useSubGroupModalCallAPI();

  const { onDelete, changeVisibleModal, changeVisibleEditModal } =
    useSubGroupFormFunctions({
      deleteSubGroup,
      setVisibleModal,
      setVisibleEditModal,
      setSelectedRowsOnTable,
    });

  const { headerOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useSubGroupFormMemorizations({
      setVisibleModal,
      setVisibleEditModal,
      selectedRowsOnTable,
      getShortcut,
    });

  return (
    <>
      <PageLoading loading={isLoading}>
        <ContainerPage>
          <Table
            columns={subGroupCollums(t)}
            headerOptions={headerOptions}
            loading={isLoading}
            hasSelection
            pagination={subGroupList}
            onDelete={onDelete}
            onRefetch={refetchGroupList}
            editModalLoading={editSubGroup?.isLoading}
            deleteModal={deleteModal}
            dataTestId="inventory-subgroups-of-groups"
            selectedRowsOnTable={selectedRowsOnTable}
            setSelectedRowsOnTable={setSelectedRowsOnTable}
            tableHeaderTooltip={tableHeaderHotKeyTooltip}
            keyToSelect="externalId"
            initialRowsSelected={selectedItems}
          />
          {productGroup && (
            <CreateSubGroupModal
              createSubGroup={createSubGroup}
              editSubGroup={editSubGroup}
              productGroup={productGroup}
              changeVisible={changeVisibleModal}
              visible={visibleModal}
              group={{
                externalId: productGroup.externalId,
                groupName: productGroup.descricao,
              }}
            />
          )}
          {productGroup && (
            <CreateSubGroupModal
              changeVisible={changeVisibleEditModal}
              createSubGroup={createSubGroup}
              editSubGroup={editSubGroup}
              productGroup={productGroup}
              visible={visibleEditModal}
              group={{
                externalId: productGroup.externalId,
                groupName: productGroup.descricao,
              }}
              title={`${t('common.edit')} "${
                selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
              }"`}
              selectedRowsOnTable={selectedRowsOnTable}
            />
          )}
        </ContainerPage>
      </PageLoading>
    </>
  );
};
