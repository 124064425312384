import { useMemo } from 'react';
import { AddManipulatedRecipeModalBody } from '../Modals/AddManipulatedRecipe';
import { DetailsManipulatedRecipeModalBody } from '../Modals/DetailsManipulatedRecipe/DetailsManipulatedRecipeModalBody';
import { IUseManipulatedRecipeMemorizations } from './interfaces';

export const useManipulatedRecipeMemorizations = ({
  componentsEditing,
  setComponentsEditing,
  validatingComponents,

  client,
}: IUseManipulatedRecipeMemorizations) => {
  const steps = useMemo(
    () => [
      <AddManipulatedRecipeModalBody
        setComponentsEditing={setComponentsEditing}
        componentsEditing={componentsEditing}
        validatingComponents={validatingComponents}
      />,
      <DetailsManipulatedRecipeModalBody />,
    ],
    [componentsEditing, validatingComponents, setComponentsEditing]
  );

  const initialValues = {
    pacienteExternalId: client?.externalId,
    itens: [{ id: 1 }],
    pacienteExternalIdInitialValue: {
      id: client?.externalId,
      label: client?.name,
      avatar: { name: client?.name },
    },
  };

  return { steps, initialValues };
};
