import { Field, FormikValues, useFormikContext } from 'formik';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from '../..';

import styles from './PermissionToggle.module.scss';
import { BootysShortcut } from '@/Components/Shortcuts';

type PermissionType = boolean | 'inherited' | 'not-inherited';

interface IPermissionToggle {
  name: string;
  hasUndetermined?: boolean;
  disabled?: boolean;
  inheritedFrom?: string[];
  onChange?: (
    name: string,
    prevValue: PermissionType,
    newValue: PermissionType
  ) => void;
  denyPermissionHotkey?: BootysShortcut;
  inheritedPermissionHotkey?: BootysShortcut;
  allowPermissionHotkey?: BootysShortcut;
}

export const PermissionToggle: FC<IPermissionToggle> = ({
  name,
  disabled,
  inheritedFrom = [],
  hasUndetermined = true,
  onChange,
  denyPermissionHotkey,
  inheritedPermissionHotkey,
  allowPermissionHotkey,
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<FormikValues>();

  const currentValue = form.values[name];
  const isDeny = currentValue === false;
  const isInherited = currentValue === 'inherited';
  const isAllow = currentValue === true;

  const onClick = (value: PermissionType) => {
    if (!disabled) {
      const previousValue = form.values[name];

      if (previousValue === value) return;

      form.setFieldValue(name, value);
      onChange && onChange(name, previousValue, value);
    }
  };

  return (
    <Field as="span" name={name}>
      <div
        className={`${styles['Container']} ${
          styles[`value-${form.values[name]}`]
        } ${!hasUndetermined && styles['Undetermined']}`}
      >
        <Tooltip
          title={
            !!isDeny
              ? t('common.permissionType.deny')
              : denyPermissionHotkey?.tooltip
          }
          showMe={!disabled}
        >
          <div
            className={`${styles['item']} ${styles['false']}`}
            data-testid="permission-toggle-deny"
            onClick={() => onClick(false)}
          >
            <Icon icon="close-x" color="text-300" size="SM" />
          </div>
        </Tooltip>

        {hasUndetermined && (
          <Tooltip
            title={
              form.values[name] === 'inherited' ||
              form.values[name] === 'not-inherited'
                ? `${t('common.permissionType.inherited')} ${inheritedFrom.join(
                    ', '
                  )}`
                : inheritedPermissionHotkey?.tooltip
            }
            showMe={!disabled}
          >
            <div
              className={`${styles['item']} ${styles['inherited']}`}
              data-testid="permission-toggle-inherited"
              onClick={() => onClick('inherited')}
            >
              <p>/</p>
            </div>
          </Tooltip>
        )}

        <Tooltip
          title={
            !!isAllow
              ? t('common.permissionType.allow')
              : allowPermissionHotkey?.tooltip
          }
          showMe={!disabled}
        >
          <div
            data-testid="permission-toggle-allow"
            className={`${styles['item']} ${styles['true']}`}
            onClick={() => onClick(true)}
          >
            <Icon icon="check" color="text-300" size="SM" />
          </div>
        </Tooltip>
      </div>
    </Field>
  );
};
