import { FC } from 'react';
import { Modal } from '@/Components';
import { useTranslation } from 'react-i18next';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { EditDeliveryModalBody } from './Body';
import { useEditAddressCallAPI } from './Hooks/useEditAddressCallAPI';
import { useEditAddressMemorizations } from './Hooks/useEditAddressMemorizations';

interface IEditDeliveryModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrder: IGetSaleOrderResponse;
  selectedAddressExternalId?: string;
}

export const EditDeliveryModal: FC<IEditDeliveryModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrder,
  selectedAddressExternalId,
}) => {
  const { t } = useTranslation();

  const { clientAddress, editAddress, updateDeliverySaleOrder } =
    useEditAddressCallAPI({
      changeVisibleModal,
      selectedAddressExternalId,
    });

  const { initialValues } = useEditAddressMemorizations({ clientAddress });

  return (
    <>
      <Modal
        title={t('common.editDelivery')}
        body={<EditDeliveryModalBody />}
        visible={visibleModal}
        okButtonName={
          updateDeliverySaleOrder.isLoading
            ? t('common.saving')
            : t('common.save')
        }
        onCancelClick={() => changeVisibleModal(false)}
        onClose={() => changeVisibleModal(false)}
        widthModal={800}
        withForm
        initialValues={initialValues}
        onOkClickForm={async (x) =>
          clientAddress?.clienteEnderecoExternalId &&
          (await editAddress({
            ...x,
            clienteEnderecoExternalId: clientAddress?.clienteEnderecoExternalId,
          }))
        }
        loading={updateDeliverySaleOrder.isLoading}
        htmlType="submit"
      />
    </>
  );
};
