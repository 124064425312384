import { Card, Icon } from '@/Components';
import { ReleaseTable } from '../ReleaseTable';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AddProductModal } from '../AddProductModal';

interface ITableCardProps {
  index: number;
  item: any;
  inventory: any;
  inventoryDetails: any;
  startRelease: boolean;
  hideQuantityColumns: boolean;
}

export const TableCard: React.FC<ITableCardProps> = ({
  index,
  inventory,
  inventoryDetails,
  item,
  startRelease,
  hideQuantityColumns,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Card
        key={index}
        title={item.localEstoqueDescricao}
        showButtonTitle
        buttonTittleText={
          <div style={{ display: 'flex', gap: '2px' }}>
            <Icon color="text-50" icon="printer" /> {t('common.print')}
          </div>
        }
        icon="drag"
        showLeftButton
        leftButtonText={
          <div style={{ display: 'flex', gap: '2px' }}>
            <Icon color="text-50" icon="plus" />{' '}
            {t('supply.inventory.release.addProduct')}
          </div>
        }
        onButtonLeftClick={() => setVisible(!visible)}
      >
        <ReleaseTable
          inventoryItem={item}
          startRelease={startRelease}
          tableItems={item.itens}
          showQuantity={!inventory.ocultarQuantidadeLancamento}
          initialIndexCount={
            index === 0
              ? 0
              : inventoryDetails.especificacao
                  .slice(0, index)
                  .reduce(
                    (acc: number, curr: any) => acc + curr.itens.length,
                    0
                  )
          }
          hideQuantityColumns={hideQuantityColumns}
        />
      </Card>
      <AddProductModal
        visible={visible}
        changeVisibility={() => setVisible(!visible)}
      />
    </>
  );
};
