import styles from './styles.module.scss';

interface IProgressBarProps {
  progress: number;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({ progress = 0 }) => {
  return (
    <div className={styles['progress-bar']}>
      <div
        style={{ width: `${progress}%` }}
        className={styles['progress-bar--success']}
      ></div>
    </div>
  );
};
