export const GET_CLIENT = '[CLIENT] GET_CLIENT';
export const GET_CLIENT_ADDRESS = '[CLIENT] GET_CLIENT_ADDRESS';
export const GET_SIMPLIFIED_CLIENT = '[CLIENT] GET_SIMPLIFIED_CLIENT';

export const FETCH_LIST_DETAILED_CLIENT = '[CLIENT] FETCH_LIST_DETAILED_CLIENT';
export const FETCH_LIST_CLIENT = '[CLIENT] FETCH_LIST_CLIENT';
export const FETCH_ADDRESS_CLIENT_LIST = '[CLIENT] FETCH_ADDRESS_CLIENT_LIST';

export const CREATE_CLIENT = '[CLIENT] CREATE_CLIENT';
export const DELETE_CLIENT = '[CLIENT] DELETE_CLIENT';
export const EDIT_INFO_CLIENT = '[CLIENT] EDIT_INFO_CLIENT';
export const ADD_CONTACT_CLIENT = '[CLIENT] ADD_CONTACT_CLIENT';
export const EDIT_CONTACT_CLIENT = '[CLIENT] EDIT_CONTACT_CLIENT';
export const DELETE_CONTACT_CLIENT = '[CLIENT] DELETE_CONTACT_CLIENT';
export const ADD_ADRESS_CLIENT = '[CLIENT] ADD_ADRESS_CLIENT';
export const EDIT_ADRESS_CLIENT = '[CLIENT] EDIT_ADRESS_CLIENT';
export const DELETE_ADRESS_CLIENT = '[CLIENT] DELETE_ADRESS_CLIENT';
export const EDIT_DOCUMENT_CLIENT = '[CLIENT] EDIT_DOCUMENT_CLIENT';
export const ADD_DOCUMENT_CLIENT = '[CLIENT] ADD_DOCUMENT_CLIENT';
export const DELETE_DOCUMENT_CLIENT = '[CLIENT] DELETE_DOCUMENT_CLIENT';

export const CHANGE_CLIENT_STATUS = '[CLIENT] CHANGE_CLIENT_STATUS';
export const CHANGE_FAVORITE_CONTACT_CLIENT =
  '[CLIENT] CHANGE_FAVORITE_CONTACT_CLIENT';
export const CHANGE_FAVORITE_ADRESS_CLIENT =
  '[CLIENT] CHANGE_FAVORITE_ADRESS_CLIENT';

export const VALIDATION_CPF_CLIENT = '[CLIENT] VALIDATION_CPF_CLIENT';
export const VALIDATION_CNPJ_CLIENT = '[CLIENT] VALIDATION_CNPJ_CLIENT';
