import { i18n, Link, Status } from '../../../../../../../../Components';
import { currencyFormater } from '../../../../../../../../Utils/Currency';

export const productCollums = (t: any) => [
  {
    title: t('products.productList.tableTitle.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    width: '7%',
    isHighlightable: true,
  },
  {
    title: t('products.productList.tableTitle.productName'),
    dataIndex: 'descricao',
    key: 'descricao',
    sortColumn: 'descricao',
    sorter: true,
    width: '25%',
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/inventory/products/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('products.productList.tableTitle.subGroup'),
    dataIndex: 'subGrupoDescricao',
    key: 'subGrupoDescricao',
    isHighlightable: true,
  },
  {
    title: t('products.productList.tableTitle.costValue'),
    dataIndex: 'valorCusto',
    key: 'valorCusto',
    render: (x: any, row: any) => currencyFormater(x, row.unidadeMedida),
  },
  {
    title: t('products.productList.tableTitle.saleValue'),
    dataIndex: 'valorVenda',
    key: 'valorVenda',
    render: (x: any, row: any) => currencyFormater(x, row.unidadeMedida),
  },
  {
    title: t('products.productList.tableTitle.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    render: (x: any) => (
      <Status type={x === true ? 'success' : 'disabled'}>
        {i18n.t(
          `userGroups.list.statusTypes.${x === true ? 'active' : 'inactive'}`
        )}
      </Status>
    ),
  },
];
