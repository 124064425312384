import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { IUseFunctions } from './interfaces';
import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';

export const useInputProductFunctions = ({
  refetchSearchProductPage,
  productList,
  product,
  setClassificationProductId,
  setUnidadeMedidaId,
}: IUseFunctions) => {
  const onSearch = (search: string | undefined) => {
    refetchSearchProductPage({
      search: search,
    });
  };

  const onChange = useCallback(
    (product: string) => {
      const currentProduct = productList?.data?.find(
        (x: IProductGroupListData) => x.externalId === product
      );

      if (currentProduct?.externalId) {
        setClassificationProductId?.(currentProduct.classeProdutoId);
        setUnidadeMedidaId?.(currentProduct.unidadeMedidaId);
      }
    },
    [
      productList,
      setClassificationProductId,
      setUnidadeMedidaId,
    ]
  );

  useEffect(() => {
    if (product) {
      setClassificationProductId?.(product.classeProdutoId);
      setUnidadeMedidaId?.(product.unidadeEstoqueId);
    }
  }, [product]);

  return {
    onSearch,
    onChange,
  };
};
