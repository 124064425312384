import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { PageLoading } from '@/Layouts/PageLoading';
import { prescribersCollums } from './prescribers.columns';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { useParams } from 'react-router';
import { usePrescribersStates } from './Hooks/usePrescribersStates';
import { usePrescribersCallAPI } from './Hooks/usePrescribersCallAPI';
import { usePrescribersFunctions } from './Hooks/usePrescribersFunctions';
import { usePrescribersMemorizations } from './Hooks/usePrescribersMemorizations';

export const PrescribersTab: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const { selectedRowsOnTable, setSelectedRowsOnTable } =
    usePrescribersStates();

  const {
    changeStatus,
    prescribersDataList,
    prescribersLoadingList,
    deletePrescriber,
    refetchPrescribersList,
  } = usePrescribersCallAPI({ externalId });

  const { onDelete, onEdit, selectRowsOnTable } = usePrescribersFunctions({
    changeStatus,
    deletePrescriber,
    setSelectedRowsOnTable,
  });

  const { headerOptions, rowsOptions, deleteModal } =
    usePrescribersMemorizations({
      selectedRowsOnTable,
    });

  return (
    <PageLoading loading={prescribersLoadingList}>
      <Table
        columns={prescribersCollums(t)}
        headerOptions={headerOptions}
        onDelete={onDelete}
        onEdit={onEdit}
        editStatusModalLoading={changeStatus.isLoading}
        setSelectedRowsOnTable={selectRowsOnTable}
        rowsOptions={rowsOptions}
        editOptions={editTableStatus()}
        loading={prescribersLoadingList}
        hasSelection
        fieldStatus="ativo"
        pagination={prescribersDataList}
        onRefetch={refetchPrescribersList}
        deleteModal={deleteModal}
      />
    </PageLoading>
  );
};
