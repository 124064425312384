import { ICreatePrescriberRequest } from '@/Data/Interfaces/request/Prescribers/IPrescribersRequest';
import { useCallback } from 'react';
import DateUtils from '@/Utils/DateUtils';
import { IUseFunctions } from './interfaces';
import { history } from '@/Store';

export const useCreateFunctions = ({
  preferences,
  createPrescriber,
}: IUseFunctions) => {
  const onSubmit = useCallback(
    (values: ICreatePrescriberRequest) => {
      var birthdayFormated;
      if (values.dataNascimento && preferences)
        birthdayFormated = DateUtils.convertDateToApi(
          values.dataNascimento,
          preferences.padraoData
        );
      createPrescriber({ ...values, dataNascimento: birthdayFormated });
    },
    [preferences, createPrescriber]
  );

  return {
    onSubmit,
  };
};
