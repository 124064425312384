import { useState } from 'react';

export const useProductFormStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);

  const [selectedItems, setSelectedItems] = useState<string[] | undefined>(
    undefined
  );

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    selectedItems,
    setSelectedItems,
  };
};
