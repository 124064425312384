import { FC } from 'react';
import { Button } from '@/Components';
import { useTranslation } from 'react-i18next';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';
import { App } from 'antd';

import styles from './Create.module.scss';

interface IFooterButtons {
  isLoadingEditPosology: boolean;
  disabled: boolean;
  form: any;
  onChangeDisabled: (x: boolean) => void;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditPosology,
  disabled,
  form,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const app = App.useApp();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => 
          handleCancelEdit(form, app, () => onChangeDisabled(!disabled))
        }
      />
      <Button
        type="primary"
        children={isLoadingEditPosology ? t('common.saving') : t('common.save')}
        loading={isLoadingEditPosology}
        htmlType="submit"
      />
    </div>
  );
};
