//@ts-nocheck
import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';
import { cnpj, cpf } from 'cpf-cnpj-validator';

let lastValidatedValue = '';
let lastValidationResult = true;

export const ProviderLegalSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) =>
  yup.object().shape({
    tipoFornecedorId: yup
      .number()
      .required(
        i18n.t('purchasing.provider.create.errors.requiredProviderType')
      ),
    fantasyName: yup
      .string()
      .required(i18n.t('purchasing.provider.create.errors.requiredName')),
    cnpj: yup
      .string()
      .nullable()
      .required(i18n.t('common.requiredCNPJ'))
      .test(
        'is-valid-cnpj',
        i18n.t('purchasing.provider.create.errors.requiredCnpj'),
        (value) => (value ? cnpj.isValid(value) : false)
      )
      .test(
        'checkDuplications',
        t('purchasing.provider.create.errors.cnpjAlreadyUsed'),
        (value) => {
          return new Promise((resolve) => {
            if (value === lastValidatedValue) {
              resolve(lastValidationResult);
              return;
            }
            lastValidatedValue = value;
            validateName(value, (success) => {
              lastValidationResult = success;
              resolve(success);
            });
          });
        }
      ),
  });
