import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { productCollums } from './product.columns';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { PageLoading } from '@/Layouts/PageLoading';
import { useProductFormStates } from './Hooks/useProductFormStates';
import { useProductFormCallAPI } from './Hooks/useProductFormCallAPI';
import { useProductFormMemorizations } from './Hooks/useProductFormMemorizations';
import { useProductFormFunctions } from './Hooks/useProductFormFunctions';
import { useSubGroupProductsListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/subGroups/products';

export const List: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useProductFormStates();

  const { getShortcut } = useSubGroupProductsListShortcuts(t, {});

  const {
    preference,
    editProductStatus,
    deleteProduct,
    subGroup,
    subGroupProductList,
    isLoading,
    refetchSubGroupList,
  } = useProductFormCallAPI();

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useProductFormMemorizations({
      subGroup,
      selectedRowsOnTable,
      getShortcut,
    });

  const { onEdit, onDelete } = useProductFormFunctions({
    deleteProduct,
    setSelectedRowsOnTable,
    editProductStatus,
  });

  return (
    <>
      <PageLoading loading={isLoading}>
        <ContainerPage>
          <Table
            columns={productCollums(t, preference.tipoMoedaId)}
            headerOptions={headerOptions}
            rowsOptions={rowOptions}
            editOptions={editTableStatus()}
            hasSelection
            fieldStatus="ativo"
            pagination={subGroupProductList}
            editModal={{ okButtonDataTestId: 'submit-product-status' }}
            onEdit={onEdit}
            onRefetch={refetchSubGroupList}
            editStatusModalLoading={editProductStatus.isLoading}
            deleteModal={deleteModal}
            onDelete={onDelete}
            dataTestId="inventory-products-of-subgroups"
            loading={isLoading}
            selectedRowsOnTable={selectedRowsOnTable}
            setSelectedRowsOnTable={setSelectedRowsOnTable}
            tableHeaderTooltip={tableHeaderHotKeyTooltip}
            keyToSelect="externalId"
            initialRowsSelected={selectedItems}
          />
        </ContainerPage>
      </PageLoading>
    </>
  );
};
