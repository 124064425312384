import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormikContextType, useFormikContext } from 'formik';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';
import { LaboratoryName } from '../laboratoryName';
import { InputWithSearch } from '@/Components';
import { ICreateLaboratory } from '@/Data/Interfaces/request/Laboratory/ILaboratoryRequest';

export const RowDrescription = () => {
  const form: FormikContextType<ICreateLaboratory> = useFormikContext();
  const { t } = useTranslation();

  const {
    setShowSupplyPlace,
    showSupplyPlace,
    setCompanyExternalId,
    isErrorValidateName,
  } = useCreateStates();

  const {
    companyList,
    isLoadingCompany,
    supplyPlaceList,
    fetchNewPageSupplyPlace,
    isLoadingSupplyPlace,
    refetchSearchCompanyPage,
    refetchSupplyPlace,
    fetchInputCompany,
  } = useCreateCallAPI({
    form,
  });

  const { supplyPlacesList, companyListlFormList } = useCreateMemorizations({
    supplyPlaceList,
    companyList,
  });

  return (
    <Row gutter={[16, 0]}>
      <Col span={7}>
        <LaboratoryName />
      </Col>
      <Col span={7}>
        <InputWithSearch
          items={companyListlFormList}
          name="empresaExternalId"
          placeHolder={t('production.laboratory.create.companyPlaceholder')}
          label={t('production.laboratory.create.company')}
          required
          withoutMarginBottom
          isLoading={isLoadingCompany}
          onScrollEnd={fetchInputCompany}
          onSearch={(search) =>
            refetchSearchCompanyPage({
              search: search,
            })
          }
          onChange={(value: string) => {
            form.setFieldValue('localEstoqueExternalId', undefined);
            form.setFieldValue("empresaExternalId", value);
            setTimeout(() => {
              form.validateField("nomeLaboratorio");
            }, 0);
            setShowSupplyPlace(true);
            setCompanyExternalId(value);
          }}
          onClear={() => form.setFieldValue('localEstoqueExternalId', undefined)}
          dataTestId="laboratory-company-dropdown"
        />
      </Col>
      <Col span={7}>
        <InputWithSearch
          items={showSupplyPlace ? supplyPlacesList : []}
          name="localEstoqueExternalId"
          placeHolder={t(
            'production.laboratory.create.supplyPlacesPlaceholder'
          )}
          label={t('production.laboratory.create.supplyPlaces')}
          required
          withoutSearchIcon
          withoutMarginBottom
          disabled={
            !form.values.empresaExternalId || !form.values.nomeLaboratorio
          }
          isLoading={isLoadingSupplyPlace}
          onScrollEnd={fetchNewPageSupplyPlace}
          onSearch={(search) =>
            refetchSupplyPlace({
              search: search,
            })
          }
          onChange={fetchInputCompany}
          dataTestId="laboratory-supplyplace-dropdown"
        />
      </Col>
    </Row>
  );
};
