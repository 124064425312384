import { useState } from 'react';

export const useUserFormStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [visible, setVisible] = useState(false);

  const [selectedItems, setSelectedItems] = useState<string[] | undefined>([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visible,
    setVisible,
    setSelectedItems,
    selectedItems,
  };
};
