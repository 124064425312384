import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Table } from '../../../../../Components/Table';
import { productMensageColumns } from './productList.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';

export const ProductMensageList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    productMessageList,
    isLoadingProductMessageList,
    deleteProductMessage,
    refetchProductMessageList,
  } = useListCallAPI();

  const { onDelete } = useListFunctions({ deleteProductMessage });

  const { headerOptions, rowOptions, deleteModal } = useListMemorizations({
    selectedRowsOnTable,
  });

  return (
    <PageLoading>
      <Table
        columns={productMensageColumns(t)}
        loading={isLoadingProductMessageList}
        headerOptions={headerOptions}
        hasSelection
        pagination={productMessageList}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        rowsOptions={rowOptions}
        onRefetch={refetchProductMessageList}
        onDelete={onDelete}
        deleteModal={deleteModal}
      />
    </PageLoading>
  );
};
export default ProductMensageList;
