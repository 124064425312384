import React from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import FormGroupModal from './Modal/FormGroupModal';
import { groupCollums } from './group.columns';
import { PageLoading } from '@/Layouts/PageLoading';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { useGroupListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/groups/list';

export const List: React.FC = () => {
  const { t } = useTranslation();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleModal,
    setVisibleModal,
    visibleEditModal,
    setVisibleEditModal,
    selectedItems,
  } = useListStates();

  const { deleteGroup, groupList, isLoading, refetchGroupList } =
    useListCallAPI();

  const { getShortcut } = useGroupListShortcuts(t, {});

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      setVisibleModal,
      selectedRowsOnTable,
      setVisibleEditModal,
      getShortcut,
    });

  const { onDelete, onVisibleModal, onVisibleEditModal } = useListFunctions({
    deleteGroup,
    setSelectedRowsOnTable,
    setVisibleModal,
    setVisibleEditModal,
  });

  return (
    <>
      <PageLoading loading={isLoading}>
        <Table
          dataTestId="inventory-groups"
          columns={groupCollums(t)}
          loading={isLoading}
          headerOptions={headerOptions}
          hasSelection
          onDelete={onDelete}
          deleteModal={deleteModal}
          pagination={groupList}
          rowsOptions={rowOptions}
          onRefetch={refetchGroupList}
          selectedRowsOnTable={selectedRowsOnTable}
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          tableHeaderTooltip={tableHeaderHotKeyTooltip}
          keyToSelect="externalId"
          initialRowsSelected={selectedItems}
        />
        <FormGroupModal changeVisible={onVisibleModal} visible={visibleModal} />
        <FormGroupModal
          changeVisible={onVisibleEditModal}
          visible={visibleEditModal}
          title={`${t('common.edit')} "${
            selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
          }"`}
          selectedRowsOnTable={selectedRowsOnTable}
        />
      </PageLoading>
    </>
  );
};
