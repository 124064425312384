import { IListMessageProductDropdownData } from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import { IStaticOptions, IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';

export const useRadioButtonMessageTypeMemorizations = ({
  listMessageProductDropdown,
  messageSearch,
  createMessageProductLinked,
  setSelectedOptionsMessageType,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: FormikContextType<FormikValues> = useFormikContext();

  const items = listMessageProductDropdown?.data?.map(
    (item: IListMessageProductDropdownData) => {
      return {
        id: item.externalId,
        label: item.descricao,
      };
    }
  );

  const staticOptions: IStaticOptions[] = [
    {
      name: `${t(
        'products.productList.productMessage.modalProductLinkedMessage.newMessageDropdown'
      )}  ${messageSearch ? `"${messageSearch}"` : ''}`,
      icon: 'plus',
      onClick: () => {
        setSelectedOptionsMessageType(2);
        form.setFieldValue('mensagem', messageSearch);
      },
    },
  ];

  return {
    items,
    staticOptions,
  };
};
