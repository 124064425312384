import React, { FC, useMemo, useState } from 'react';
import { ITextInput } from '../TextInput';
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Icon } from '../..';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';
import en from 'react-phone-number-input/locale/en.json';
import pt from 'react-phone-number-input/locale/pt.json';
import flags from 'react-phone-number-input/flags';
import { Dropdown, Menu } from 'antd';
import { InputLabel } from '../Label';
import StorageUtils from '@/Utils/StorageUtils';

import styles from './TextInputPhoneMask.module.scss';
import './TextInputPhoneMask.override.scss';

export interface ITextInputPhone extends ITextInput {
  value?: string;
  countriesOrdered?: boolean;
}

interface ICountrySelect {
  value: string;
  onChange: (value?: string) => void;
  labels: any;
  disabled?: boolean;
  ordered: boolean;
}

export const CountrySelect: FC<ICountrySelect> = ({
  value,
  onChange,
  disabled,
  ordered = false,
  ...rest
}) => {
  const flag = flags as any;
  const Flag = flag[value];
  const [visible, setVisible] = useState(false);

  const countries = useMemo(
    () =>
      getCountries().map((country) => ({
        formattedValue: `${
          StorageUtils.getPreference().idioma === 'pt-BR'
            ? pt[country]
            : en[country]
        } +${getCountryCallingCode(country)}`,
        value: country,
      })),
    []
  );

  if(ordered)
    countries.sort((a, b) => a.formattedValue.localeCompare(b.formattedValue))

  return (
    <div
      className={`${visible ? styles['click'] : ''} ${styles['div-dropdown']} ${
        disabled ? styles['div-dropdown-disabled'] : ''
      }`}
    >
      <Dropdown
        disabled={disabled}
        onOpenChange={() => !disabled && setVisible(!visible)}
        overlay={
          visible ? (
            <Menu>
              {countries.map(({ formattedValue, value }) => {
                return (
                  <Menu.Item
                    onClick={() => {
                      onChange(value);
                      setVisible(false);
                    }}
                    key={value}
                  >
                    {formattedValue}
                  </Menu.Item>
                );
              })}
            </Menu>
          ) : (
            <></>
          )
        }
        trigger={['click']}
      >
        <div style={{ display: 'flex' }}>
          <div
            className={`${disabled ? styles['disabled-flag'] : ''} 
						${styles['flag']}`}
          >
            {value && <Flag />}
          </div>
          {!disabled && (
            <Icon
              icon="chevron-down"
              size="L"
              color="text-400"
              style={{ marginRight: '5px' }}
            />
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export const TextInputPhoneMask: React.FC<ITextInputPhone> = ({
  name,
  label,
  placeHolder,
  required,
  disabled,
  className,
  withoutMarginBottom,
  error,
  success,
  value,
  withStatusIcon,
  dataTestId,
  onChange,
  countriesOrdered = false
}) => {
  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              props.meta.touched &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );
          const classNames = className;

          return (
            <div
              className={`
              	${styles['input-container']} 
              	${classNames}
								`}
            >
              <InputLabel
                label={label}
                props={props}
                required={required}
                withStatusIcon={withStatusIcon}
              />
              <PhoneInput
                onChange={(x) => {
                  props.form.setFieldValue(name, x);
                  onChange && onChange(x as any);
                }}
                value={value || props.field.value}
                className={`${
                  disabled || props.form.isSubmitting
                    ? styles['disabled-input-phone']
                    : ''
                } ${styles['PhoneInput']}`}
                placeholder={placeHolder}
                countrySelectComponent={(selectProps) => (
                  <CountrySelect {...selectProps} ordered={countriesOrdered} />
                )}
                disabled={disabled || props.form.isSubmitting}
                limitMaxLength={true}
                defaultCountry="BR"
                initialValueFormat="national"
                data-testid={dataTestId}
              />
              {hasError ? (
                <Icon
                  className={styles['Icon']}
                  icon="exclamation"
                  size="M"
                  color="status-danger-base"
                />
              ) : null}

              {success ? (
                <Icon
                  className={styles['Icon']}
                  icon="check"
                  size="M"
                  color="status-success-base"
                />
              ) : null}
              {props.form &&
              props.form.errors &&
              props.meta.touched &&
              LocalizaPropriedadePorPath(props.form.errors, name) ? (
                <p className={styles['errorText']}>
                  {LocalizaPropriedadePorPath(props.form.errors, name)}
                </p>
              ) : null}
            </div>
          );
        }}
      </Field>
    </div>
  );
};
