import { useMemo } from 'react';
import { IuseEditAddressMemorizations } from './interfaces';

export const useEditAddressMemorizations = ({
  clientAddress,
}: IuseEditAddressMemorizations) => {
  const initialValues = useMemo(
    () => ({
      paisExternalId: clientAddress?.paisExternalId,
      estadoExternalId: clientAddress?.estadoExternalId,
      cidadeExternalId: clientAddress?.cidadeExternalId,
      bairro: clientAddress?.bairro,
      cep: clientAddress?.cep,
      logradouro: clientAddress?.logradouro,
      numero: clientAddress?.numero?.toString(),
      complemento: clientAddress?.complemento,
      principal: clientAddress?.principal,
      descricao: clientAddress?.descricao,
      cidadeExternalIdInitialValue: {
        id: clientAddress?.cidadeExternalId,
        label: clientAddress?.cidadeDescricao,
      },
      estadoExternalIdInitialValue: {
        id: clientAddress?.estadoExternalId,
        label: clientAddress?.estadoAbreviacao,
      },
    }),
    [clientAddress]
  );

  return { initialValues };
};
