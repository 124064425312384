import { useControllerMutateApiHook } from "@/ControllerApiHook/Controller";
import { CANCEL_INVENTORY } from "@/ControllerApiHook/UniqIds/Inventory/Inventory";
import { InventoryAPI } from "@/Data/API/Supply/InventoryApit";
import { messageErrorToast } from "@/Utils/MessageErrorToast";

export const useInventoryCallAPI = () => {
  const cancelInventory = useControllerMutateApiHook({
    uniqId: CANCEL_INVENTORY,
    entityApi: InventoryAPI.cancelInventory,
    options: {
      onError: (x) => messageErrorToast(x),
    },
  });

  return {
    cancelInventory
  };
};
