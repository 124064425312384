import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { GET_USER_LIST } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';

export const useListUser = () => {
  const usersList = useControllerQueryListApiHook({
    uniqId: GET_USER_LIST,
    entityApi: UserAPI.listUsers,
    autoIncrement: true,
    pagination: {
      pageIndex: 1,
      pageSize: 10,
    },
  });

  return { usersList };
};
