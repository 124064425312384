import { FC } from 'react';
import { Checkbox, InputWithSearch, TextInput, Text } from '@/Components';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IGetCityResponse } from '@/Data/Interfaces/response/Locality/ICityResponse';
import { IGetStateResponse } from '@/Data/Interfaces/response/Locality/IStateResponse';
import { IEditAdressClientRequest } from '@/Data/Interfaces/request/Client/IClientRequest';
import { useBodyCallAPI } from './Hooks/useBodyCallAPI';
import { useBodyMemorizations } from './Hooks/useBodyMemorizations';
import { useBodyStates } from './Hooks/useBodyStates';
import { Divflex } from '@/Layouts/DivWhithFlex';

export const EditDeliveryModalBody: FC = () => {
  const { t } = useTranslation();
  const form: FormikContextType<IEditAdressClientRequest> = useFormikContext();
  const { CEP, setCEP } = useBodyStates();

  const {
    listCity,
    cityListLoading,
    fetchNewCityPage,
    refetchCityList,
    listState,
    fetchNewStatePage,
    refetchStateList,
    stateListLoading,
    cepLoading,
  } = useBodyCallAPI({ form, CEP });

  const { changeCEP } = useBodyMemorizations({
    setCEP,
  });

  return (
    <>
      <div style={{ padding: '20px' }}>
        <Row gutter={[16, 0]} justify="start">
          <Col flex="26%">
            <PermissionWrapper
              permission={PermissionEnum.Vendas_Clientes_EditarEndereco}
              tooltip={t('noPermission.sale.client.editAddress')}
            >
              <TextInput
                name="descricao"
                label={t('address.modal.description')}
                placeHolder={t('address.modal.descriptionPlaceholder')}
                required
              />
            </PermissionWrapper>
          </Col>
          <Col flex="26%">
            <PermissionWrapper
              permission={PermissionEnum.Vendas_Clientes_EditarEndereco}
              tooltip={t('noPermission.sale.client.editAddress')}
            >
              <TextInputWithMask
                name="cep"
                label={t('address.modal.CEP')}
                mask="cepMask"
                placeHolder={'00000-000'}
                loading={cepLoading}
                onChange={changeCEP}
              />
            </PermissionWrapper>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={9}>
            <PermissionWrapper
              permission={PermissionEnum.Vendas_Clientes_EditarEndereco}
              tooltip={t('noPermission.sale.client.editAddress')}
            >
              <InputWithSearch
                name="estadoExternalId"
                label={t('address.modal.state')}
                placeHolder={t('address.modal.statePlaceholder')}
                items={listState?.data?.map((x: IGetStateResponse) => ({
                  id: x.externalId,
                  label: x.descricao,
                }))}
                onScrollEnd={fetchNewStatePage}
                isLoading={stateListLoading}
                onSearch={(search) =>
                  refetchStateList({
                    search: search,
                  })
                }
                withoutSearchIcon
                onChange={() =>
                  form.setFieldValue('cidadeExternalId', undefined)
                }
                selectedItemFromForm="estadoExternalIdInitialValue"
              />
            </PermissionWrapper>
          </Col>
          <Col span={9}>
            <PermissionWrapper
              permission={PermissionEnum.Vendas_Clientes_EditarEndereco}
              tooltip={t('noPermission.sale.client.editAddress')}
            >
              <InputWithSearch
                name="cidadeExternalId"
                label={t('address.modal.city')}
                placeHolder={t('address.modal.cityPlaceholder')}
                items={listCity?.data?.map((x: IGetCityResponse) => ({
                  id: x.externalId,
                  label: x.descricao,
                }))}
                selectedItemFromForm="cidadeExternalIdInitialValue"
                onScrollEnd={fetchNewCityPage}
                isLoading={cityListLoading}
                onSearch={(search) =>
                  refetchCityList({
                    search: search,
                  })
                }
                withoutSearchIcon
                disabled={!form.values.estadoExternalId}
              />
            </PermissionWrapper>
          </Col>
          <Col span={6}>
            <PermissionWrapper
              permission={PermissionEnum.Vendas_Clientes_EditarEndereco}
              tooltip={t('noPermission.sale.client.editAddress')}
            >
              <TextInput
                name="bairro"
                label={t('address.modal.district')}
                placeHolder={t('address.modal.districtPlaceholder')}
              />
            </PermissionWrapper>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col flex="60%">
            <PermissionWrapper
              permission={PermissionEnum.Vendas_Clientes_EditarEndereco}
              tooltip={t('noPermission.sale.client.editAddress')}
            >
              <TextInput
                name="logradouro"
                label={t('address.modal.street')}
                placeHolder={t('address.modal.streetPlaceholder')}
              />
            </PermissionWrapper>
          </Col>
          <Col flex="20%">
            <PermissionWrapper
              permission={PermissionEnum.Vendas_Clientes_EditarEndereco}
              tooltip={t('noPermission.sale.client.editAddress')}
            >
              <TextInput
                name="numero"
                label={t('address.modal.streetNumber')}
                placeHolder={t('address.modal.streetNumberPlaceholder')}
              />
            </PermissionWrapper>
          </Col>
          <Col flex="20%">
            <PermissionWrapper
              permission={PermissionEnum.Vendas_Clientes_EditarEndereco}
              tooltip={t('noPermission.sale.client.editAddress')}
            >
              <TextInput
                name="complemento"
                label={t('address.modal.complement')}
                placeHolder={t('address.modal.complementPlaceholder')}
              />
            </PermissionWrapper>
          </Col>
        </Row>
        <Divflex style={{ alignItems: 'center' }}>
          <Checkbox
            name="principal"
            style={{ marginRight: 12 }}
            checked={form.values.principal}
          />
          <Text
            type="ui-tiny-content"
            children={t('address.modal.saveAsFavorite')}
          />
        </Divflex>
      </div>
    </>
  );
};
