import { IListCompanyData } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import { useState } from 'react';

export const useListStates = () => {
  const [rowSelected, setRowSelected] = useState<IListCompanyData[]>();

  const [selectedItems, setSelectedItems] = useState<string[] | undefined>([]);

  return {
    rowSelected,
    setRowSelected,

    selectedItems,
    setSelectedItems,
  };
};
