import { FormikContextType, useFormikContext } from 'formik';
import { IUseFunctions } from './interfaces';
import { 
  IPurchaseOrderGetResponse 
} from '@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse';

export const useProviderFunctions = ({
  setExternalIdProvider,
  refetchSearchProviderPage,
}: IUseFunctions) => {
  const form: FormikContextType<IPurchaseOrderGetResponse> = useFormikContext();

  const onChange = (value: string) => {
    setExternalIdProvider(value);
  };

  const onSearch = (search: string | undefined) => {
    refetchSearchProviderPage({
      search: search,
    });
  };

  const onClear = () => {
    form?.setFieldValue('razaoSocial', undefined);
    form?.setFieldValue('cnpj', undefined);
    form?.setFieldValue('fornecedorContatoPrincipal', undefined);
  };

  return {
    onChange,
    onSearch,
    onClear
  };
};
