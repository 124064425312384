import { Button } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useCalculateProgress } from '../../hooks/useCalculateProgress';

interface IFinishButtonProps {
  startRelease: boolean;
  setIsVisibleModal: (value: boolean) => void;
}

export const FinishButton: React.FC<IFinishButtonProps> = ({
  setIsVisibleModal,
  startRelease,
}) => {
  const { t } = useTranslation();
  const { calculateProgress } = useCalculateProgress();

  const disabled = startRelease
    ? startRelease && calculateProgress() < 100
    : false;

  return (
    <Button
      leftIcon={startRelease ? 'check' : 'start'}
      leftIconColor="white"
      type="primary"
      style={{ width: '100%' }}
      status="success"
      onClick={async () => {
        setIsVisibleModal(true);
      }}
      disabled={disabled}
    >
      {!startRelease
        ? t('supply.inventory.release.startRelease')
        : t('supply.inventory.release.finish')}
    </Button>
  );
};
