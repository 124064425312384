import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../../../../Components';
import { TransferLine } from './TransfersRows/transferLine';
import { v4 as uuidV4 } from 'uuid';

export interface ITransferCard {
  originSupplyPlaceId?: string;
  destinySupplyPlaceId?: string;
}

export const TransferCard: FC<ITransferCard> = ({
  originSupplyPlaceId,
  destinySupplyPlaceId,
}) => {
  const { t } = useTranslation();

  const [transfersRowAmount, setTransfersRowAmount] = useState([uuidV4()]);

  return (
    <Card title={t('supply.transfers.transfers')} withoutCollapse={true}>
      {transfersRowAmount.map((uuid, index) => (
        <TransferLine
          key={uuid}
          index={index}
          originSupplyPlaceId={originSupplyPlaceId}
          destinySupplyPlaceId={destinySupplyPlaceId}
          setProductRowAmount={setTransfersRowAmount}
          totalRows={transfersRowAmount.length}
          uuidV4={uuidV4}
        />
      ))}
    </Card>
  );
};
