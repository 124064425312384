import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { IUseFunctions } from "./interfaces";
import {
  FETCH_GET_INVENTORY,
  FETCH_GET_INVENTORY_DETAILS,
  FETCH_LIST_INVENTORY,
} from '@/ControllerApiHook/UniqIds/Inventory/Inventory';

export const useReleaseFunctions = ({
  externalId,
  setIsVisibleCancelModal,
  setStartRelease,
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onOkClickCancelReleaseModal = async (
    cancelRelease: UseMutationResult<unknown>
  ) => {
    await cancelRelease.mutateAsync({ externalId });
    queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
    queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
    queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
    setIsVisibleCancelModal(false);
    setStartRelease(false);
  };

  return {
    onOkClickCancelReleaseModal
  };
};