import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const CreateTransferSchema = yup.object().shape({
  origin: yup
    .string()
    .required(i18n.t('supply.transfers.create.errors.requiredOrigin')),
  destiny: yup
    .string()
    .required(i18n.t('supply.transfers.create.errors.requiredDestiny')),
  transfers: yup
    .array()
    .of(
      yup.object().shape({
        product: yup
          .string()
          .required(i18n.t('supply.transfers.create.errors.requiredProduct')),
        lot: yup
          .string()
          .required(i18n.t('supply.transfers.create.errors.requiredLot')),
        qntTransfers: yup
          .string()
          .required(
            i18n.t('supply.transfers.create.errors.requiredAmountTransfers')
          )
          .test(
            'is-greater-than-zero',
            i18n.t('supply.transfers.create.errors.minQuantity'),
            (value: string | null | undefined) => {
              const numberValue = value ? parseFloat(value) : 0;
              return !isNaN(numberValue) && numberValue > 0;
            }
          ),
      })
    ),
});
