import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/Components';
import {
  IPermissionWrapper,
  PermissionWrapper,
} from '@/Components/PermissionWrapper';
import { Placements, Tooltip } from '@/Components/Tooltip';

import styles from './FooterButton.module.scss';

interface IFooterButton {
  disabled?: boolean;
  editLabel?: string;
  onChange?: (value: boolean) => void;
  permission?: IPermissionWrapper;
  cancelButtonClick?: () => void;
  loading?: boolean;
  dataTestId?: string;
  id?: string;

  tooltip?: {
    title: ReactNode | string;
    placement?: Placements;
    targetOffset?: number[];
  };
}

export const FooterButton: React.FC<IFooterButton> = ({
  disabled,
  editLabel,
  onChange,
  permission,
  cancelButtonClick,
  loading,
  dataTestId,
  id,
  tooltip,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['div-footer-button']}>
      {disabled ? (
        <PermissionWrapper
          permission={permission?.permission}
          tooltip={permission?.tooltip || 'noPermission.default'}
        >
          <Tooltip
            title={tooltip?.title}
            showMe={!!tooltip}
            placement={tooltip?.placement}
            targetOffset={tooltip?.targetOffset}
            delay={0.5}
          >
            <Button
              type="secondary"
              children={
                editLabel || t('accountManager.accountDetailsPage.edit')
              }
              onClick={() => onChange && onChange(false)}
              dataTestId={dataTestId}
              id={id}
            />
          </Tooltip>
        </PermissionWrapper>
      ) : (
        <div className={styles['buttons']}>
          <Button
            type="secondary"
            children={t('accountManager.accountDetailsPage.cancel')}
            onClick={() => {
              onChange && !cancelButtonClick && onChange(true);
              cancelButtonClick && cancelButtonClick();
            }}
          />
          <Button
            className={styles['save-button']}
            type="primary"
            children={t('accountManager.accountDetailsPage.save')}
            htmlType="submit"
            loading={loading}
            dataTestId={dataTestId}
          />
        </div>
      )}
    </div>
  );
};
