import { IButtonDropDownItems } from "@/Components/Button/ButtonDropdown";
import { IconName } from "@/Components/Icon/IconClasses";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";

export const useReleaseMemorizations = ({
  setIsVisibleCancelModal,
  releaseHistory
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const CONFERENCE_STATUS = 3;
  const CANCELED_STATUS = 5;

  const dropdownReleaseOptionsItems: IButtonDropDownItems = [
    {
      label: t('supply.inventory.release.dropdownOptions.save'),
      icon: 'diskette' as IconName,
      targetOffsetTooltip: [35, -15],
    },
    {
      label: t('supply.inventory.release.dropdownOptions.print'),
      icon: 'printer' as IconName,
      targetOffsetTooltip: [35, -15],
    },
    {
      label: t('supply.inventory.release.dropdownOptions.cancel'),
      icon: 'close-x' as IconName,
      targetOffsetTooltip: [35, -15],
      onClick: () => setIsVisibleCancelModal(true),
    },
  ];

  const releaseHistoryReverted = releaseHistory?.reverse();

  return {
    dropdownReleaseOptionsItems,
    releaseHistoryReverted,
    CONFERENCE_STATUS,
    CANCELED_STATUS,
  };
};