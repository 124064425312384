import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../Components/Modal';
import { Footer as ModalFooter } from '../../Components/Modal/Components/Footer';
import { Icon, Text } from '../../Components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NotificationActions } from '../../Store/Notification/Notification.actions';
import { history } from '../../Store';
import { FormikContextType } from 'formik';

const Content: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <BodyModal
        body={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text type="ui-tiny-content" color="text-50">
              {t('modal.leaveWithoutSave.body')}
            </Text>
          </div>
        }
        style={{ padding: 20 }}
      />
    </>
  );
};

const Title: FC = () => {
  const { t } = useTranslation();

  return (
    <Text
      color="text-50"
      type="heading4"
      children={t('modal.leaveWithoutSave.title')}
    />
  );
};

const Footer: FC<{
  params: { 
    location?: any; 
    action?: string; 
    form?: FormikContextType<any>;
    onOkClick?: () => void;
  };
  instance: any;
}> = ({ instance, params }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <ModalFooter
      onCancelClick={() => instance.destroy()}
      okButtonName={t('modal.leaveWithoutSave.okButton')}
      onOkClick={() => {
        params?.form?.resetForm();
        dispatch(NotificationActions.setCurrentFormIsDirty(false));
        instance.destroy();

        if(params.location) {
          setTimeout(() => history.push(params.location), 1);
        } else {
          params.onOkClick && params?.onOkClick();
        }
      }}
    />
  );
};

export const LeaveWithoutSave: (
  params: { 
    location?: any; 
    action?: string; 
    form?: FormikContextType<any>;
    onOkClick?: () => void; 
  },
  instance: any
) => ModalFuncProps = (params, instance) => ({
  closable: true,
  icon: <></>,
  title: <Title />,
  content: <Content />,
  footer: <Footer params={params} instance={instance} />,
  width: '400px',
  closeIcon: <Icon size="XXL" color="text-400" icon="close-x" />,
});
