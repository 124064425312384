import { Dropdown, Menu } from 'antd';
import React, { FC, ReactElement, ReactNode } from 'react';
import { Button, ButtonType, IButton } from '../';
import { Text, Icon, Tooltip } from '../..';
import { IconName } from '../../Icon/IconClasses';
import {
  hasPermission,
  IPermissionWrapper,
  PermissionWrapper,
} from '../../PermissionWrapper';

import './ButtonDropdown.override.scss';
import style from './ButtonDropdown.module.scss';
import { Color } from '@/Utils/ColorUtils';
import { BadgeType } from '@/Components/Badge';
import { Hotkey, PossibleKey } from '@/Components/Shortcuts/Hotkey';

export type IButtonDropDownItems = {
  icon?: IconName;
  label: string;
  disabled?: boolean;
  messageTooltipDisabled?: string;
  targetOffsetTooltip?: number[];
  permission?: IPermissionWrapper;
  onClick?: () => void;
  dataTestId?: string;
  key?: PossibleKey;
}[];

export interface IButtonDropdown {
  type: ButtonType;
  dropdownTitle?: string;
  dangerStatus?: boolean;
  dropdownItens?: IButtonDropDownItems;
  moreActionsButton?: {
    title?: ReactNode | string;
    targetOffsetTooltip?: number[];
  };
  dropdownDirection?:
    | 'topRight'
    | 'topLeft'
    | 'topCenter'
    | 'bottomRight'
    | 'bottomLeft'
    | 'bottomCenter';

  htmlType?: 'submit' | 'button' | 'reset';

  disabled?: boolean;

  leftIcon?: IconName;
  leftIconColor?: Color;

  rightIcon?: IconName;

  badge?: { amount: number; badgeType?: BadgeType };
  children?: string | ReactElement;
  onClick?: () => void;

  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  loading?: boolean;

  className?: string;

  dataTestId?: string;
  id?: string;
}

export const ButtonDropdown: FC<IButtonDropdown> = ({
  dropdownTitle,
  dropdownItens,
  dropdownDirection,
  type,
  htmlType,
  disabled,
  leftIcon,
  rightIcon,
  badge,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  loading,
  className,
  dangerStatus,
  dataTestId,
  leftIconColor,
  id,
  moreActionsButton,
}) => {
  const newResource = (
    <Menu className={style['divDropdown']}>
      {dropdownTitle && (
        <div className={style['title']}>
          <Text type="small-text" color="text-300">
            {dropdownTitle}
          </Text>
        </div>
      )}
      {dropdownItens &&
        dropdownItens.map((link, index) => (
          <PermissionWrapper
            permission={link.permission?.permission}
            tooltip={link.permission?.tooltip || 'noPermission.default'}
            style={{ display: 'flex' }}
            key={index}
          >
            {link.disabled ? (
              <Tooltip
                title={link.messageTooltipDisabled}
                showMe={!!link.messageTooltipDisabled}
                placement="topRight"
                targetOffset={link.targetOffsetTooltip}
              >
                <Menu.Item
                  id={`${id}-${index}`}
                  className={style['dropdownLabel']}
                  onClick={link.onClick}
                  key={index}
                  disabled={
                    !hasPermission(link.permission?.permission) || link.disabled
                  }
                  data-testid={`${dataTestId || 'table-edit'}-item-${
                    link.label
                  }`}
                >
                  {link.icon ? (
                    <Icon
                      className={style['icon']}
                      icon={link.icon}
                      color="text-300"
                    />
                  ) : null}
                  <Text type="ui-tiny-content" color="text-50">
                    {link.label}
                  </Text>
                </Menu.Item>
              </Tooltip>
            ) : (
              <Menu.Item
                id={`${id}-${index}`}
                className={style['dropdownLabel']}
                onClick={link.onClick}
                key={index}
                disabled={!hasPermission(link.permission?.permission)}
                data-testid={link.dataTestId}
              >
                {link.icon ? (
                  <Icon
                    className={style['icon']}
                    icon={link.icon}
                    color={dangerStatus ? 'status-danger-base' : 'text-300'}
                  />
                ) : null}
                <Text
                  type="ui-tiny-content"
                  color={dangerStatus ? 'status-danger-base' : 'text-50'}
                >
                  {link.label}
                </Text>
                {!!link.key && (
                  <span style={{ position: 'absolute', right: 10 }}>
                    <Hotkey
                      hotKey={link.key}
                      width={link.key === 'backspace' ? 27 : 20}
                      height={link.key === 'backspace' ? 18 : 20}
                      fontSize={11}
                    />
                  </span>
                )}
              </Menu.Item>
            )}
          </PermissionWrapper>
        ))}
    </Menu>
  );
  return (
    <div className={className}>
      <Tooltip
        title={moreActionsButton?.title}
        showMe={!!moreActionsButton?.title}
        placement="topRight"
        targetOffset={[0, 3]}
      >
        <Dropdown
          disabled={disabled}
          dropdownRender={() => newResource}
          placement={dropdownDirection}
          trigger={['click']}
        >
          <Button
            id={id}
            type={type}
            htmlType={htmlType}
            disabled={disabled}
            leftIcon={leftIcon && leftIcon}
            leftIconColor={leftIconColor}
            rightIcon={rightIcon && rightIcon}
            badge={badge && badge}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            loading={loading}
            dataTestId={dataTestId}
          >
            {children}
          </Button>
        </Dropdown>
      </Tooltip>
    </div>
  );
};
