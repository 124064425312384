import React from 'react';
import { Table } from '../../../../../Components';
import { supplyPlacesColumns } from './StorageLocation.columns';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';

const List: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    deleteSupplyPlace,
    editSupplyPlaceStatus,
    supplyPlaceList,
    isLoadingSupplyPlaceList,
    refetchSupplyPlaceList,
  } = useListCallAPI();

  const { headerOptions, rowOptions, deleteModal, editModal } =
    useListMemorizations({
      selectedRowsOnTable,
    });

  const { onSelectedRowsOnTable, onEdit, onDelete } = useListFunctions({
    setSelectedRowsOnTable,
    editSupplyPlaceStatus,
    deleteSupplyPlace,
  });

  return (
    <PageLoading loading={isLoadingSupplyPlaceList}>
      <Table
        columns={supplyPlacesColumns(t)}
        loading={isLoadingSupplyPlaceList}
        headerOptions={headerOptions}
        fieldStatus="ativo"
        setSelectedRowsOnTable={onSelectedRowsOnTable}
        hasSelection
        rowsOptions={rowOptions}
        pagination={supplyPlaceList}
        editStatusModalLoading={editSupplyPlaceStatus.isLoading}
        editOptions={editTableStatus()}
        onEdit={onEdit}
        onRefetch={refetchSupplyPlaceList}
        onDelete={onDelete}
        editModal={editModal}
        deleteModal={deleteModal}
      />
    </PageLoading>
  );
};

export default List;
