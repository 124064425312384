import React, { ForwardRefExoticComponent, forwardRef } from 'react';
import { Field } from 'formik';
import { Text } from '../..';
import CurrencyFormat from 'react-currency-format';
import StorageUtils from '../../../Utils/StorageUtils';
import { isNumeric } from '@/Utils/IsNumericUtils';
import { INumberInput } from './types';
import { InnerNumberInput } from './VisualInput';
import { IField } from '../interfaces/IField';

import styles from './NumberInput.module.scss';
import './NumberInput.override.scss';


export type { INumberInput } from './types';

export const NumberInput: ForwardRefExoticComponent<
  INumberInput & React.RefAttributes<unknown>
> = forwardRef(
  (
    {
      name,
      prefix,
      decimalScale,
      withCurrencyPrefix,
      withThousandSeparator,
      withDecimalSeparator,
      fixedDecimalScale,
      suffix,
      onChange,
      onBlur,
      subText,
      subTextTestId,
      acceptOnlyNumbers,
      allowNegative,
      ...props
    },
    ref
  ) => {
    const preference = StorageUtils.getPreference();

    return (
      <Field name={name}>
        {(propsForm: IField) => {
          let thousandSeparator;
          let decimalSeparator;
      
          if (withThousandSeparator) {
            thousandSeparator = preference?.idioma === 'pt-BR' ? '.' : ',';
          }
      
          if (withDecimalSeparator) {
            decimalSeparator = preference?.idioma === 'pt-BR' ? ',' : '.';
          }
          
          return (
            <>
              <CurrencyFormat
                name={name}
                value={propsForm.field.value}
                disabled={props?.disabled}
                decimalScale={decimalScale}
                thousandSeparator={thousandSeparator}
                decimalSeparator={decimalSeparator}
                fixedDecimalScale={fixedDecimalScale}
                customInput={InnerNumberInput}
                prefix={prefix}
                suffix={suffix}
                onBlur={onBlur}
                onValueChange={(x) => {
                  const newValue = isNumeric(x.floatValue) ? x.floatValue : 0;
                  propsForm.form.setFieldValue(name, newValue);
                  if (onChange) onChange(newValue);
                }}
                allowNegative={allowNegative}
                {...({
                  ...props,
                  name,
                  outsideRef: ref,
                  separators: {
                    decimalScale,
                    decimalSeparator,
                    thousandSeparator,
                  },
                  acceptOnlyNumbers,
                } as any)}
              />
              {subText && (
                <Text
                  id={subTextTestId}
                  type="ui-tiny-content"
                  color="text-300"
                  children={subText}
                  className={styles['sub-text']}
                />
              )}
            </>
          )
        }
        }
      </Field>
    );
  }
);
