import { FC } from 'react';
import { IFooterButton } from './interfaces';
import { Button } from '@/Components';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';

import styles from './FooterButton.module.scss';

export const FooterButton: FC<IFooterButton> = ({
  createClient,
}: IFooterButton) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => history.push('/sales/customers')}
      />
      <Button
        type="primary"
        htmlType="submit"
        children={
          createClient.isLoading
            ? `${t('common.creating')}...`
            : t('common.create')
        }
        loading={createClient.isLoading}
      />
    </div>
  );
};
