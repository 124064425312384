import React from 'react';
import styles from './styles.module.scss';
import { InputWithSearch } from '@/Components';
import { useListUser } from '../../ReleaseTable/components/TableRow/components/SelectPersonModal/hooks/useListUser';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

interface ISelectPersonModalProps {
  index: number;
}

export const SelectPersonModal: React.FC<ISelectPersonModalProps> = ({
  index,
}) => {
  const { usersList } = useListUser();
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <div className={styles['modal-container']}>
      <InputWithSearch
        name={`product.${index}.responsible`}
        placeHolder={t(
          'supply.inventory.release.searchForAResponsibleOperator'
        )}
        items={usersList?.data?.data?.map((x: any) => ({
          id: x.externalId,
          label: x.nome,
          avatar: {
            name: x.nome,
            lastName: x.sobrenome,
            email: x.email,
          },
          allItem: x,
        }))}
        onScrollEnd={usersList.fetchNewPage}
        onChange={(value) => {
          const user = usersList.data.data.find(
            (x: any) => x.externalId === value
          );

          form.setFieldValue(`userName`, user.nome);
          form.setFieldValue(`userLastName`, user.sobrenome);
        }}
        isLoading={usersList.isLoading}
        onSearch={(search) => {
          usersList.refetch({
            search: search,
          });
        }}
      />
    </div>
  );
};
