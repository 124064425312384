import { Modal } from '@/Components';
import { useTranslation } from 'react-i18next';

interface IStartReleaseModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  loading: boolean;
}

export const StartReleaseModal: React.FC<IStartReleaseModalProps> = ({
  onCancel,
  onOk,
  visible,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${t('supply.inventory.release.startRelease')}?`}
      visible={visible}
      body={
        <div style={{ padding: '20px' }}>
          {t('supply.inventory.release.startReleaseTextStart')}{' '}
          <b>{t('supply.inventory.release.blocked')}</b>{' '}
          {t('supply.inventory.release.startReleaseTextEnd')}
        </div>
      }
      onCancelClick={onCancel}
      onClose={onCancel}
      onOkClick={onOk}
      okButtonColor="status-success-base"
      okButtonName="supply.inventory.release.startRelease"
      loading={loading}
    />
  );
};
