import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { useParams } from 'react-router';
import { PageLoading } from '@/Layouts/PageLoading';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { linkedProductMessageCollumn } from './Linked.column';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';

interface ILinkedMessage {
  messageLinked?: string;
}

export const ProductLinkedMessage: FC<ILinkedMessage> = ({ messageLinked }) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    productLinkedByMessageList,
    isLoadingProductLinkedByMessageList,
    deleteProductLinkedByMessage,
    refetchProductLinkedByMessageList,
  } = useListCallAPI();

  const { onDelete } = useListFunctions({
    deleteProductLinkedByMessage,
    externalId,
  });

  const { deleteModal, headerOptions } = useListMemorizations({
    selectedRowsOnTable,
    messageLinked,
  });

  return (
    <PageLoading loading={isLoadingProductLinkedByMessageList}>
      <ContainerPage>
        <Table
          columns={linkedProductMessageCollumn(t)}
          headerOptions={headerOptions}
          pagination={productLinkedByMessageList}
          onRefetch={refetchProductLinkedByMessageList}
          loading={isLoadingProductLinkedByMessageList}
          hasSelection
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          onDelete={onDelete}
          deleteModal={deleteModal}
          paginationCustomExternalId="produtoExternalId"
        ></Table>
      </ContainerPage>
    </PageLoading>
  );
};
