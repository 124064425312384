import { IProductSubGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useState } from 'react';

export const useListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IProductSubGroupListData[]
  >([]);

  const [visibleModal, setVisibleModal] = useState(false);

  const [visibleEditModal, setVisibleEditModal] = useState(false);

  const [selectedItems, setSelectedItems] = useState<string[] | undefined>(
    undefined
  );

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleModal,
    setVisibleModal,
    visibleEditModal,
    setVisibleEditModal,
    selectedItems,
    setSelectedItems,
  };
};
