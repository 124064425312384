import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';
import { FormikContextType, useFormikContext } from 'formik';

import styles from './../../Laboratory.module.scss';
import { App } from 'antd';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';

interface IFooterButtons {
  isLoadingEditLaboratory: boolean;
  onChangeDisabled: (x: boolean) => void;
}

interface IFormValues {
  nomeLaboratorio: string;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditLaboratory,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<any> = useFormikContext();
  const app = App.useApp();
  const { errors, isSubmitting } = useFormikContext<IFormValues>();
  const isFieldInError = !!errors.nomeLaboratorio;

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => 
          handleCancelEdit(form, app, () => onChangeDisabled(true))
        }
      />
      <Button
        type="primary"
        children={t('common.save')}
        htmlType="submit"
        loading={isLoadingEditLaboratory}
        disabled={isFieldInError || isSubmitting}
      />
    </div>
  );
};
