import {
  ICreateCompanyRequest,
  IChangeResponsibilityUserRequest,
  IChangeCompanyRequest,
  IChangeCompanyAdressRequest,
  IChangeCompanyPayingRequest,
  IDeteleCompany,
  IPatchCompanyStatusRequest,
} from '../../Interfaces/request/Company/ICompanyRequest';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetCurrencyResponse,
  IListCompanyResponse,
  IGetCompanyResponse,
  IListCompanyForDropdownResponse,
  IListManagerCompanyResponse,
  IGetCompanyConfigResponse,
} from '../../Interfaces/response/Company/ICompanyResponse';
import { QueryFunctionContext } from '@tanstack/react-query';

export class CompanyAPI {
  public static async listCompanyAdmin(
    context: QueryFunctionContext
  ): Promise<IListCompanyResponse> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Empresa/ListarDetalhadoAdmin',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listCompany(
    context: QueryFunctionContext
  ): Promise<IListManagerCompanyResponse> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Empresa/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listCompanyAdminForDropdown(
    context: any
  ): Promise<IListCompanyForDropdownResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Empresa/ListarAdmin',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listCompanyForDropdown(
    context: any
  ): Promise<IListCompanyForDropdownResponse | null> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Empresa/ListarNomeFantasia',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getCurrency(): Promise<IGetCurrencyResponse> {
    return await Http.fetcher<IGetCurrencyResponse>(
      '/pharma/accountmanager/v1/Moeda/Listar'
    );
  }

  public static async getCompanyAdmin(
    context: QueryFunctionContext
  ): Promise<IGetCompanyResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetCompanyResponse>(
      `/pharma/accountmanager/v1/Empresa/Admin/${context.queryKey[1]}`
    );
  }
  public static async getCompany(
    context: QueryFunctionContext
  ): Promise<IGetCompanyResponse> {
    return await Http.fetcher<IGetCompanyResponse>(
      `/pharma/accountmanager/v1/Empresa/${context.queryKey[1]}`
    );
  }
  public static async getCompanyConfig(): Promise<IGetCompanyConfigResponse> {
    return await Http.fetcher<IGetCompanyConfigResponse>(
      `/pharma/accountmanager/v1/Empresa/ObterConfiguracao`
    );
  }
  public static async createCompany(body: ICreateCompanyRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Conglomerado/filial', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async patchCompanyStatus(body: IPatchCompanyStatusRequest) {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Empresa/AtualizarStatus',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
  public static async deleteCompany(body: IDeteleCompany) {
    return await Http.fetcher('/pharma/accountmanager/v1/Empresa', {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }
  public static async changeInfoCompany(body: IChangeCompanyRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Empresa', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
  public static async changeAdressCompany(body: IChangeCompanyAdressRequest) {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Empresa/AtualizarEndereco',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async changeResponsibilytiUser(
    body: IChangeResponsibilityUserRequest
  ) {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Empresa/AtualizarUsuarioResponsavel',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async changeCompanyPaying(body: IChangeCompanyPayingRequest) {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Empresa/AtualizarEmpresaPagadora',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async cnpjCompanyValidation(cnpj: string): Promise<boolean> {
    if (!cnpj) return false;
    try {
      const result = await Http.fetcher(
        '/pharma/accountmanager/v1/Empresa/ValidarCnpj',
        {
          method: 'POST',
          body: JSON.stringify({ cnpj }),
        }
      );
      return result.success;
    } catch (e) {
      return false;
    }
  }
}
