import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '../../../../../../../Components';
import { getPermissionInitialValues } from '../../../../../../../Components/CollapseList';
import { RequiredPermissionModal } from '../../Modals/RequiredPermissionModal';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { CardsPermissions } from './Components/Cards';
import { usePermissionFormCallAPI } from './Hooks/usePermissionFormCallAPI';
import { usePermissionFormFunctions } from './Hooks/usePermissionFormFunctions';
import { usePermissonFormMemorizations } from './Hooks/usePermissionFormMemorizations';
import { usePermissionFormStates } from './Hooks/usePermissionFormStates';
import { useUserPermissionsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/user/permissions';

export const PermissionForm: FC = () => {
  const { visibleModal, setVisibleModal } = usePermissionFormStates();

  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { getShortcut } = useUserPermissionsShortcuts(t, {});

  const denyPermissionHotkeyTooltip = getShortcut('denyPermission');
  const allowPermissionHotkeyTooltip = getShortcut('allowPermission');
  const inheritedPermissionHotkeyTooltip = getShortcut('inheritedPermission');

  const params: Readonly<Partial<{ externalId: string | undefined }>> =
    useParams();

  const { updateUserPermission, user } = usePermissionFormCallAPI({
    changeModalVisible: (value) => setVisibleModal(value),
    queryClient,
    t,
    userExternalId: params.externalId,
  });

  const { transformModule } = usePermissionFormFunctions();

  const { modules } = usePermissonFormMemorizations({
    transformModule,
    user: user.data,
  });

  return (
    <Form initialValues={getPermissionInitialValues(modules || [])}>
      <CardsPermissions
        t={t}
        updateUserPermission={updateUserPermission}
        modules={modules}
        user={user.data}
        denyPermissionHotkey={denyPermissionHotkeyTooltip}
        inheritedPermissionHotkey={inheritedPermissionHotkeyTooltip}
        allowPermissionHotkey={allowPermissionHotkeyTooltip}
      />
      {updateUserPermission.variables && (
        <RequiredPermissionModal
          show={visibleModal}
          error={updateUserPermission.error}
          payloadRequest={updateUserPermission.variables}
          changeVisibleModal={() => {
            setVisibleModal((x) => !x);
            user.refetch();
          }}
        />
      )}
    </Form>
  );
};
