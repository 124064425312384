import { Modal } from '@/Components';
import { ModalBody } from './components/ModalBody';
import { useTranslation } from 'react-i18next';

interface IAddProductModalProps {
  visible: boolean;
  changeVisibility: () => void;
}

export const AddProductModal: React.FC<IAddProductModalProps> = ({
  changeVisibility,
  visible,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('supply.inventory.release.addProduct')}
      visible={visible}
      onCancelClick={changeVisibility}
      onClose={changeVisibility}
      body={<ModalBody />}
      widthModal={900}
      withForm
    />
  );
};
