import { FC } from 'react';
import { PageLoading } from '@/Layouts/PageLoading';
import { Button, Form } from '@/Components';
import { StandardFormulaDetailForm } from './Components/StandardFormulaDetailForm';
import { useParams } from 'react-router';
import { DilutionRatio } from '@/Utils/DilurionRatio';
import { Col, Row } from 'antd';
import { ProductCard } from './Components/ProductCard';
import { StandardFormulaSpecificationsDetails } from './Components/StandardFormulaSpecificationsDetails';
import { useTranslation } from 'react-i18next';
import { StandardFormulaDetailSchema } from './StandardFormulaDetail.schema';
import { IProductStandardFormulaResquest } from '@/Data/Interfaces/request/StandardFormula/IStandardFormulaRequest';
import { IDetailsForm } from './interfaces';
import { useDetailCallAPI } from './Hooks/useDetailCallAPI';
import { useDetailFunctions } from './Hooks/useDetailFunctions';
import { useDetailMemorizations } from './Hooks/useDetailMemorizations';
import { useCheckNameDuplicationCallAPI } from './Components/StandardFormulaDetailForm/Components/InputProduct/Hooks/useCheckNameDuplicationsCallAPI';

export const StandardFormulaDetail: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  let reason: string;

  const { standardFormula, editStandardFormula } = useDetailCallAPI({
    externalId,
  });

  const {
    checkNameDuplicationsDebounced
  } = useCheckNameDuplicationCallAPI({
    initialProduct: standardFormula?.produtoExternalId
  });

  const diluitionStandardFormula = standardFormula?.diluicao;

  if (diluitionStandardFormula) {
    reason = DilutionRatio(diluitionStandardFormula);
  } else {
    reason = '';
  }

  let produtoExtenalIdGet = standardFormula?.produtoExternalId
    ? standardFormula?.produtoExternalId
    : undefined;

  const { onSubmit, onClickCancelButton } = useDetailFunctions({
    editStandardFormula,
    externalId,
    onChangeDisabled,
  });

  const { initialValues } = useDetailMemorizations({
    standardFormula,
    reason,
  });

  return (
    <PageLoading>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        schema={StandardFormulaDetailSchema(checkNameDuplicationsDebounced, t)}
        validateOnChange
      >
        {(form) => (
          <>
            <StandardFormulaDetailForm
              disabled={disabled}
              onChangeDisabled={onChangeDisabled}
              produtoExternalId={produtoExtenalIdGet}
            />

            <Row justify="space-between" gutter={[16, 0]}>
              <Col span={24}>
                <ProductCard
                  disabled={disabled}
                  onChangeDisabled={onChangeDisabled}
                  products={standardFormula ? standardFormula.produtos : []}
                  pharmaceuticalFormExternalId={
                    standardFormula
                      ? standardFormula.formaFarmaceuticaExternalId
                      : ''
                  }
                />
              </Col>
            </Row>

            <StandardFormulaSpecificationsDetails
              disabled={disabled}
              onChangeDisabled={onChangeDisabled}
            />

            {!disabled && (
              <div style={{ display: 'flex', marginBottom: '20px' }}>
                <Button
                  type="secondary"
                  children={t('common.cancel')}
                  style={{ marginRight: '10px' }}
                  onClick={() => onClickCancelButton(form)}
                />
                <Button
                  type="primary"
                  children={t('common.save')}
                  htmlType="submit"
                  loading={editStandardFormula.isLoading}
                />
              </div>
            )}
          </>
        )}
      </Form>
    </PageLoading>
  );
};
