import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { IUseFunctions } from "./interfaces";
import {
  FETCH_GET_INVENTORY,
  FETCH_GET_INVENTORY_DETAILS,
  FETCH_INVENTORY_RELEASE,
  FETCH_LIST_INVENTORY,
} from '@/ControllerApiHook/UniqIds/Inventory/Inventory';

export const useInventoryFunctions = ({
  externalId,
  setVisibleCancelModal
}: IUseFunctions) => {
  const queryClient = useQueryClient();

  const onOkClickCancelInventoryModal = async (
    cancelInventory: UseMutationResult<unknown>
  ) => {
    await cancelInventory.mutateAsync({ externalId });
    queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
    queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
    queryClient.invalidateQueries([FETCH_INVENTORY_RELEASE]);
    queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
    setVisibleCancelModal(false);
  };

  return {
    onOkClickCancelInventoryModal
  };
};