import {
  BootysShortcuts,
  defaultShortcuts,
  DefaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'group-subgroup-list';

export interface GroupSubgroupListTabListCallbacks extends DefaultShortcuts {}

const shortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: GroupSubgroupListTabListCallbacks
): BootysShortcuts[] => [
  ...defaultShortcuts(t, callbacks),
  {
    id: 'addSubGroup',
    name: t('products.groupList.shortcuts.addSubGroup'),
    keys: 'n',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="create-subgroup-of-groups-button"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'selectAll',
    name: t('products.productList.shortcuts.selectAll'),
    keys: 'alt+x',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="table-select-all"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'search',
    name: 'search',
    keys: 'p',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="table-search"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.focus();
      }
    },
  },
  {
    id: 'deleteSelected',
    name: t('products.productList.shortcuts.deleteSelected'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('#delete-select-items');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editSelected',
    name: t('products.groupList.shortcuts.editSubGroup'),
    keys: 'e',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('#edit-selected-items');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useGroupSubGroupsListShortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: GroupSubgroupListTabListCallbacks
) => {
  const pageShortcuts = shortcuts(t, callbacks);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
