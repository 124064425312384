import { Modal } from '@/Components';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Text } from '@/Components';

import styles from './CancelInventoryModal.module.scss';

interface ICancelInventoryModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  loading: boolean;
  inventoryCode: string;
  supplyPlacesNames: string[];
}

export const CancelInventoryModal: React.FC<ICancelInventoryModalProps> = ({
  onCancel,
  onOk,
  visible,
  loading,
  inventoryCode,
  supplyPlacesNames,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={`${t(
        'supply.inventory.modal.cancelInventory.title',
        {inventoryCode: inventoryCode}
      )}?`}
      visible={visible}
      body={
        <div className={styles['modal-body']}>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t(
                  'supply.inventory.modal.cancelInventory.bodyInitial',
                  {inventoryCode: inventoryCode}
                )}  
              </ReactMarkdown>
            </Text>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.inventory.modal.cancelInventory.bodyMiddle')}  
              </ReactMarkdown>
            </Text>
            <ul className={styles['supply-places-list']}>
              {
                supplyPlacesNames.map((supplyPlaceName, key) => (
                  <li key={key}><b>{supplyPlaceName}</b></li>
                ))
              }
            </ul>
            <Text color="text-50" type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.inventory.modal.cancelInventory.footer')}  
              </ReactMarkdown>
            </Text>
        </div>
      }
      onCancelClick={onCancel}
      onClose={onCancel}
      onOkClick={onOk}
      cancelButtonName={t('common.back')}
      okButtonColor="status-danger-base"
      okButtonName="supply.inventory.modal.cancelInventory.confirmButton"
      loading={loading}
    />
  );
};
