import { useFormikContext } from 'formik';

export const useCalculateProgress = () => {
  const form: any = useFormikContext();

  const calculateProgress = () => {
    let completedItems = 0;
    const totalItems = form.values.itens ? form.values.itens.length : 0;
    if (totalItems === 0) return 0;

    form.values.itens.forEach((item: any) => {
      if (
        typeof item.quantidadeInventariada === 'number' &&
        item.usuarioExternalId !== null
      ) {
        completedItems += 1;
      }
    });

    return (completedItems / totalItems) * 100;
  };

  return { calculateProgress };
};
