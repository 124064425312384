import {
  BootysShortcuts,
  defaultShortcuts,
  DefaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'product-incompatible-products';

export interface ProductIncompatibleProductsTabCallbacks
  extends DefaultShortcuts {}

const shortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: ProductIncompatibleProductsTabCallbacks
): BootysShortcuts[] => [
  ...defaultShortcuts(t, callbacks),
  {
    id: 'addNewIncompatibleProduct',
    name: t('products.productList.shortcuts.linkNewProductIncompatible'),
    keys: 'n',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="new-incompatible-product-button"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'selectAll',
    name: t('products.productList.shortcuts.selectAll'),
    keys: 'alt+x',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="table-select-all"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'search',
    name: 'search',
    keys: 'p',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="table-search"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.focus();
      }
    },
  },
  {
    id: 'deleteIncompatibleProduct',
    name: t('products.productList.shortcuts.deleteSelected'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="delete-incompatible-product-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useProductsIncompatibleProductShortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: ProductIncompatibleProductsTabCallbacks
) => {
  const pageShortcuts = shortcuts(t, callbacks);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
