import React, { FC } from 'react';
import { Tag as AntdTag } from 'antd';
import { Text } from '../';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

import styles from './Tag.module.scss';
import './Tag.override.scss';

export type TagType = 'primary' | 'secondary' | 'tertiary';

export interface ITag {
  type: TagType;
  children: any;
  onClose?: any;
  closable?: boolean;
  className?: string;

  tooltip?: string;

  id?: string;

  disabled?: boolean;
  maxCharacters?: number;
  dataTestId?: string;

  maxWidth?: number;
}

export const Tag: FC<ITag> = ({
  type,
  children,
  onClose,
  closable,
  className,
  tooltip,
  id,
  disabled,
  dataTestId,
  maxWidth,
}) => (
  <div id={id} data-testid={dataTestId} style={{ maxWidth: maxWidth }}>
    <Tooltip title={tooltip} showMe={!!tooltip}>
      <AntdTag
        className={`${disabled ? 'disabled-tag' : ''} ${type}-tag ${
          styles['Tag']
        } ${styles[closable ? 'Tag-with-close-x' : 'Tag-without-close-x']} ${
          className ? className : ''
        }`}
        closeIcon={
          closable && <Icon className={'icon-x-tag'} icon="close-x" size="S" />
        }
        children={
          <Text
            type="small-text-bold"
            children={children}
            maxWidth={maxWidth}
            style={{ whiteSpace: 'nowrap' }}
          />
        }
        closable={closable}
        onClose={onClose && onClose}
        style={{ maxWidth: maxWidth }}
      />
    </Tooltip>
  </div>
);
