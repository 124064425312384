import React, { FC, ReactNode } from 'react';
import { Button, Tooltip } from '../../..';
import { TextInputState } from './TextInput';

import styles from './HeaderTable.module.scss';
import { useTranslation } from 'react-i18next';
import { IconName } from '../../../Icon/IconClasses';
import {
  IPermissionWrapper,
  PermissionWrapper,
} from '../../../PermissionWrapper';
import { ButtonDropdown } from '../../../Button/ButtonDropdown';
import { motion } from 'framer-motion';
import { Placements } from '@/Components/Tooltip';

export interface IOptions {
  label: string;
  icon?: IconName;
  permission?: IPermissionWrapper;
  isStatusOnly?: boolean;
  onClick?: () => void;
}

export interface IHeaderTableOptions {
  hasSearch?: boolean;
  searchPlaceholder?: string;
  nameKey?: string;
  lastNameKey?: string;

  newButton?: {
    icon?: IconName;
    label?: string;
    permission?: IPermissionWrapper;
    onClick: () => void;
    dataTestId?: string;
    tooltip?: {
      title: ReactNode | string;
      placement?: Placements;
      targetOffset?: number[];
    };
  };

  editButton?: {
    dataTestId?: string;
    permission?: IPermissionWrapper;
    options?: IOptions[];
    editButtonWithoutDropdown?: boolean;
    editButtonWithoutDropdownClick?: () => void;
    tooltip?: {
      title: ReactNode | string;
      placement?: Placements;
      targetOffset?: number[];
    };
  };

  deleteButton?: {
    icon?: IconName;
    label?: string;
    permission?: IPermissionWrapper;
    dataTestId?: string;
    onClick?: () => void;
    tooltip?: {
      title: ReactNode | string;
      placement?: Placements;
      targetOffset?: number[];
    };
  };
}

export interface IHeader extends IHeaderTableOptions {}

export interface IHeaderTable extends IHeaderTableOptions {
  hasSomeItemSelected: boolean;
  searchPlaceholder?: string;

  onRefetch: (search: string) => void;
  loading: boolean;
}

export const TableHeader: FC<IHeaderTable> = ({
  onRefetch,
  hasSearch,
  hasSomeItemSelected,
  searchPlaceholder,
  newButton,
  editButton,
  deleteButton,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['Container']}>
      <div className={styles['search']}>
        {hasSearch ? (
          <TextInputState
            isLoading={loading}
            leftIcon="search"
            iconColor="text-400"
            dataTestId={`table-search`}
            placeHolder={searchPlaceholder}
            onChange={onRefetch}
            hotKeyRightIcon
          />
        ) : null}
      </div>

      <div className={styles['buttons']}>
        <motion.div
          style={{ overflow: 'hidden' }}
          initial={{ width: 'auto' }}
          animate={{
            width: newButton && hasSomeItemSelected ? 0 : 'auto',
          }}
        >
          {newButton && (
            <Tooltip
              title={newButton.tooltip?.title}
              showMe={!!newButton.tooltip}
              placement={newButton.tooltip?.placement}
              targetOffset={newButton.tooltip?.targetOffset}
              delay={0.5}
            >
              <PermissionWrapper
                permission={newButton.permission?.permission}
                tooltip={
                  newButton.permission?.tooltip || 'noPermission.default'
                }
              >
                <Button
                  type="primary"
                  leftIcon={newButton.icon ? newButton.icon : 'plus'}
                  leftIconColor="white"
                  onClick={newButton.onClick}
                  style={{
                    whiteSpace: 'nowrap',
                    wordBreak: 'keep-all',
                    overflow: 'hidden',
                  }}
                  dataTestId={newButton.dataTestId}
                  children={
                    newButton.label
                      ? t(newButton.label)
                      : t('table.headerTable.new')
                  }
                />
              </PermissionWrapper>
            </Tooltip>
          )}
        </motion.div>

        {editButton && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ width: 0 }}
            animate={{
              width:
                editButton && hasSomeItemSelected
                  ? editButton.editButtonWithoutDropdown
                    ? '90px'
                    : 'auto'
                  : 0,
            }}
          >
            <PermissionWrapper
              permission={editButton.permission?.permission}
              tooltip={editButton.permission?.tooltip || 'noPermission.default'}
            >
              <Tooltip
                title={editButton.tooltip?.title}
                showMe={!!editButton.tooltip}
                placement={editButton.tooltip?.placement}
                targetOffset={editButton.tooltip?.targetOffset}
                delay={0.5}
              >
                {editButton.editButtonWithoutDropdown ? (
                  <Button
                    id="edit-selected-items"
                    dataTestId={editButton.dataTestId}
                    style={{ width: '200px' }}
                    leftIcon="edit"
                    type="secondary"
                    children={t('table.headerTable.edit')}
                    onClick={editButton.editButtonWithoutDropdownClick}
                  />
                ) : (
                  <ButtonDropdown
                    id="edit-selected-items"
                    dataTestId={editButton.dataTestId}
                    leftIcon="edit"
                    type="secondary"
                    dropdownItens={editButton.options}
                    children={t('table.headerTable.edit')}
                    rightIcon="chevron-down"
                  />
                )}
              </Tooltip>
            </PermissionWrapper>
          </motion.div>
        )}

        {deleteButton && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ width: 0 }}
            animate={{
              width: deleteButton && hasSomeItemSelected ? 'auto' : 0,
            }}
          >
            <Tooltip
              title={deleteButton.tooltip?.title}
              showMe={!!deleteButton.tooltip}
              placement={deleteButton.tooltip?.placement}
              targetOffset={deleteButton.tooltip?.targetOffset}
              delay={0.5}
            >
              <PermissionWrapper
                permission={deleteButton.permission?.permission}
                tooltip={
                  deleteButton.permission?.tooltip || 'noPermission.default'
                }
              >
                <Button
                  type="secondary"
                  id="delete-select-items"
                  dataTestId={deleteButton.dataTestId}
                  leftIcon={deleteButton.icon ? deleteButton.icon : 'trash'}
                  onClick={deleteButton.onClick}
                  children={
                    deleteButton.label
                      ? deleteButton.label
                      : t('table.headerTable.delete')
                  }
                />
              </PermissionWrapper>
            </Tooltip>
          </motion.div>
        )}
      </div>
    </div>
  );
};
