import { FC } from 'react';
import { App, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Tooltip } from '@/Components';
import { TextArea } from '@/Components/Inputs/TextArea';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { useParams } from 'react-router';
import { EditSchema } from './EditForm.schema';
import { useDetailFormCallAPI } from './Hooks/useDetailFormCallAPI';
import { IDetailForm } from './interfaces';
import { DescriptionRow } from './DescriptionRow';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { useCheckNameDuplicationAPI } from '../../../CreateForm/Components/DetailsCard/Components/UserGroupNameField/Hooks/useCheckNameDuplicationAPI';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';

import styles from './DetailForm.module.scss';
import { useUserGroupDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/userGroups/details';

export const DetailForm: FC<IDetailForm> = ({
  userGroup,
  disabledForm,
  onDisabledForm,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();
  const app = App.useApp();

  const { checkNameDuplications } = useCheckNameDuplicationAPI({
    initialUserGroup: userGroup?.nome,
  });

  const { editUserGroup } = useDetailFormCallAPI({ onDisabledForm });

  const { getShortcut } = useUserGroupDetailsShortcuts(t, {});

  const editUserGroupDetailsHotkey = getShortcut('editUserGroupDetails');

  return (
    <Form
      initialValues={userGroup}
      className={styles['Container']}
      onSubmit={async (values) =>
        await editUserGroup.mutateAsync({
          grupoExternalId: externalId,
          data: {
            descricao: values.descricao.trim(),
            nome: values.nome.trim(),
          },
        })
      }
      schema={EditSchema(checkNameDuplications, t)}
      validateOnChange
    >
      {(form) => (
        <>
          <ConnectedFocusError />
          <Card
            title={t('admin.editUserGroups.tab.details')}
            status="default"
            withoutCollapse
          >
            <DescriptionRow disabledForm={disabledForm} />
            <Row justify="space-between">
              <Col span={24}>
                <TextArea
                  name="descricao"
                  label={t('admin.editUserGroups.detailForm.description')}
                  placeholder={t(
                    'admin.editUserGroups.detailForm.descriptionPlaceholder'
                  )}
                  maxLength={200}
                  rows={8}
                  disable={disabledForm}
                />
              </Col>
            </Row>
            <Row>
              <div className={styles['footer']}>
                {disabledForm ? (
                  <PermissionWrapper
                    permission={1012}
                    tooltip="noPermission.administrative.userGroups.edit"
                  >
                    <Tooltip title={editUserGroupDetailsHotkey.tooltip} showMe>
                      <Button
                        type="secondary"
                        children={t(
                          'admin.editUserPage.footerButtons.editDetails'
                        )}
                        dataTestId="edit-user-group"
                        onClick={() => onDisabledForm(false)}
                      />
                    </Tooltip>
                  </PermissionWrapper>
                ) : (
                  <>
                    <Button
                      type="secondary"
                      children={t('admin.editUserPage.footerButtons.cancel')}
                      onClick={() =>
                        handleCancelEdit(form, app, () => onDisabledForm(true))
                      }
                    />
                    <Button
                      type="primary"
                      children={t('admin.editUserPage.footerButtons.save')}
                      htmlType="submit"
                      loading={editUserGroup.isLoading}
                    />
                  </>
                )}
              </div>
            </Row>
          </Card>
        </>
      )}
    </Form>
  );
};
