import { Label } from '@/Components';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_PRODUCT_PRESCRIPTION } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Col, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SortableList } from './ComponentLine/Components/SortableList';
import { ComponentsLine } from './ComponentLine';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';

import styles from './ComponentSelection.module.scss';


interface IComponentSelection {
  setComponentsEditing: Dispatch<SetStateAction<number[]>>;
  componentsEditing: number[];

  validatingComponents: boolean;
}

export const ComponentSelection: FC<IComponentSelection> = ({
  setComponentsEditing,
  componentsEditing,
  validatingComponents,
}) => {
  const productPrescriptionList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_PRODUCT_PRESCRIPTION,
    entityApi: ProductAPI.getProductPrescriptionList,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'descricao', direction: 'DESC' },
      filter: [{ filterName: 'classeProdutoIds', value: [0, 1] }],
    },
  });

  const { t } = useTranslation();

  const form: FormikContextType<IPostManipulatedRecipeRequest> =
    useFormikContext();

  const componenti18n = useMemo(
    () => 'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components',
    []
  );

  const thereAreSomeComponentSelected = useMemo(() => {
    return form?.values?.itens?.length > 1;
  }, [form?.values?.itens?.length]);

  const dummy: MutableRefObject<any> = useRef(null);
  //when selecting a new component, the focus is redirected to the end of the list
  useEffect(() => {
    dummy.current && dummy.current.scrollIntoView({ behavior: 'smooth' });
  }, [form?.values?.itens?.length]);

  return (
    <div className={styles['container-components']}>
      <div className={styles['horizontal-divider']} />
      <Row gutter={[6, 0]} className={styles['header-components']}>
        <Col flex={'78%'}>
          <Divflex style={{ justifyContent: 'space-between' }}>
            <Label
              type="ui-tiny-content"
              children={t(componenti18n + '.componentCollum')}
              required
              color="text-50"
            />
            {thereAreSomeComponentSelected && (
              <Label
                type="ui-tiny-content"
                children={`${form.values?.itens.length - 1} ${
                  form.values?.itens?.length < 2
                    ? t(componenti18n + '.componentAmount')
                    : t(componenti18n + '.componentsAmount')
                }`}
                color="text-300"
              />
            )}
          </Divflex>
        </Col>
        <Col flex={'22%'}>
          <Label
            type="ui-tiny-content"
            children={t(componenti18n + '.amountCollum')}
            required
            color="text-50"
          />
        </Col>
      </Row>
      <div className={styles['horizontal-divider-bottom']} />
      <div className={styles['component-line']}>
        <SortableList
          items={form?.values?.itens}
          onChange={(x) => form.setFieldValue('itens', x)}
          renderItem={(item, index) => (
            <SortableList.Item id={item.id}>
              <ComponentsLine
                setItems={(x) => form.setFieldValue('itens', x)}
                index={index}
                productPrescriptionList={productPrescriptionList}
                setComponentsEditing={setComponentsEditing}
                componentsEditing={componentsEditing}
                validatingComponents={validatingComponents}
              />
            </SortableList.Item>
          )}
        />
        <div ref={dummy} />
      </div>
    </div>
  );
};
