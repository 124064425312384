import { Skeleton } from '@/Components/Skeleton';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_INVENTORY } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { useTranslation } from 'react-i18next';
import { statusTypes } from '@/Utils/StatusUtils';
import { Status } from '@/Components/Status';

const waitingRelease = 1;
const released = 2;
const conference = 3;
const finished = 4;
const canceled = 5;

const filterStateColor = (state: number): statusTypes => {
  if (state === waitingRelease) return 'default';
  if (state === released) return 'attention';
  if (state === conference) return 'primary';
  if (state === finished) return 'success';
  if (state === canceled) return 'danger';

  return 'default';
};

const filterStateText = (state: number): string => {
  if (state === waitingRelease) return 'supply.inventory.list.waitingRelease';
  if (state === released) return 'supply.inventory.list.released';
  if (state === conference) return 'supply.inventory.list.conference';
  if (state === finished) return 'supply.inventory.list.finished';
  if (state === canceled) return 'supply.inventory.list.canceled';

  return 'supply.inventory.list.waitingRelease';
};

export const DynamicInventoryBreadcrumb = ({ match }: any) => {
  const { data: inventory } = useControllerQueryApiHook<any>({
    uniqId: FETCH_GET_INVENTORY,
    entityApi: InventoryAPI.getInventory,
    externalIds: [match.params.externalId],
  });
  const { t } = useTranslation();

  if (!inventory) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {t('breadCrumb.inventory.inventory')} | #
      {String(inventory.codigo).padStart(5, '0')}
      <div style={{ marginLeft: '12px' }}>
        <Status
          iconColor="status-disabled-base"
          type={filterStateColor(inventory.statusInventarioId)}
        >
          {t(filterStateText(inventory.statusInventarioId))}
        </Status>
      </div>
    </div>
  );
};

export const DynamicInventoryBreadCrumb = ({ match }: any) => {
  const { data: inventory } = useControllerQueryApiHook<any>({
    uniqId: FETCH_GET_INVENTORY,
    entityApi: InventoryAPI.getInventory,
    externalIds: [match.params.externalId],
  });
  const { t } = useTranslation();

  if (!inventory) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <span style={{ display: 'flex' }}>
      {String(inventory.codigo).padStart(5, '0')}
    </span>
  );
};
