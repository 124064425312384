import { isVisible } from '@/Utils/DOMUtils';
import { TFunction } from 'react-i18next';
import { HotkeyShortcuts } from 'reakeys';

export interface DefaultShortcuts {}

export interface BootysShortcuts extends HotkeyShortcuts {
  id: string;
  withText?: boolean;
}

export const defaultShortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: DefaultShortcuts
): BootysShortcuts[] => [
  {
    id: 'openSectors',
    name: t('sideMenu.shortcuts.openSectors'),
    keys: 'alt+s',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('#sector-menu');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];
