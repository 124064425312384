import { App } from "antd";
import { IUseFunctions } from "./interfaces";
import { handleCancelEdit } from "@/Utils/BehaviorUtils";

export const usePersonalDataCardFunctions = ({
  refetchGroupList,
  changeEditDisabled,
  form
}: IUseFunctions) => {
  const app = App.useApp();

  const onSearch = (search: string | undefined) => {
    refetchGroupList({
      search: search,
    });
  }; 

  const actionOnFocus = () => refetchGroupList({ search: '' });

  const onClickCancel = () => handleCancelEdit(form, app, changeEditDisabled);

  return {
    onSearch,
    actionOnFocus,
    onClickCancel
  };
};