import { IUpdatePermissionRequest } from '@/Data/Interfaces/request/User/IUpdatePermissionRequest';
import { ErrorModalData } from '../..';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from '@/Components/CollapseList/Content';
import { IconName } from '@/Components/Icon/IconClasses';
import { Form, Text } from '@/Components';

import styles from './ModalBody.module.scss';

export const ModalBody: FC<{
  modalData: ErrorModalData;
  payloadRequest: IUpdatePermissionRequest;
}> = ({ modalData, payloadRequest }) => {
  const { t } = useTranslation();
  const defaultValues: any = {};

  modalData?.dependencias?.forEach((dependency) => {
    defaultValues[dependency.permissaoId] = payloadRequest.ativo;
  });

  return (
    <div className={styles.container}>
      <Text
        type="paragraph2"
        color="text-50"
        style={{ marginBottom: 12, display: '-webkit-box' }}
      >
        {t('admin.editUserPage.permission.modal.title1')}
        <b>“{modalData?.nome}”</b>{' '}
        {t('admin.editUserPage.permission.modal.title2')}{' '}
        {modalData?.dependencias?.length}{' '}
        {t('admin.editUserPage.permission.modal.permissions')}
      </Text>

      <Form initialValues={defaultValues}>
        {modalData?.dependencias?.map((dependency) => (
          <div className={styles.permissionContainer}>
            <Content
              name={dependency?.permissaoId}
              displayName={dependency?.nome}
              icon={
                dependency?.modulo
                  ? {
                      icon: dependency.modulo.icone as IconName,
                      backgroundColor: dependency.modulo.corDeFundo,
                      color: 'white',
                    }
                  : undefined
              }
              secondaryIcon={
                dependency?.subModulo
                  ? {
                      icon: dependency.subModulo.icone as IconName,
                      backgroundColor: dependency.subModulo.corDeFundo,
                      color: 'text-50',
                    }
                  : undefined
              }
              hasInherited={false}
              disabled
            />
          </div>
        ))}
      </Form>

      <Text type="paragraph2" color="text-50">
        {
          payloadRequest.ativo! ? 
          t('admin.editUserPage.permission.modal.footer') :
          t('admin.editUserPage.permission.modal.altFooter')
        }
      </Text>
    </div>
  );
};
