import { FC } from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@/Components/StateInputs/RadioButton/RadioGroup';
import { RadioButton } from '@/Components/StateInputs/RadioButton';
import { useParams } from 'react-router';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { FormikContextType } from 'formik';

import styles from '../../../../Details/DetailsForm.module.scss';

interface IOtherRow {
  setSelectedOptionsStock: React.Dispatch<React.SetStateAction<number>>;
  selectedOptionsStock: number;
  disabled: boolean;
  form: FormikContextType<any>;
}

export const OtherRow: FC<IOtherRow> = ({
  setSelectedOptionsStock,
  selectedOptionsStock,
  disabled,
  form
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { storageLocation } = useEditCallAPI({ externalId, t });

  return (
    <Row gutter={[16, 0]}>
      <RadioGroup
        className={styles['radio-button']}
        valueChecked={
          !selectedOptionsStock || !form.dirty
            ? storageLocation?.tipoEstoque
            : selectedOptionsStock
        }
      >
        <RadioButton
          value={1}
          label={t('supply.supplyPlaces.create.stockType.stockStorage')}
          onChange={(value: number) => {
            setSelectedOptionsStock(1);
            form.setFieldValue('tipoEstoque', value)
          }}
          disabled={disabled}
        />
        <RadioButton
          value={2}
          label={t('supply.supplyPlaces.create.stockType.stockSale')}
          onChange={(value: number) => {
            setSelectedOptionsStock(2);
            form.setFieldValue('tipoEstoque', value)
          }}
          disabled={disabled}
        />
      </RadioGroup>
    </Row>
  );
};
