import { Icon, Text } from '@/Components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

interface ITableHeaderProps {
  startRelease: boolean;
  orientation: string;
  toggleOrientation: () => void;
  hideQuantityColumns: boolean;
}

export const TableHeader: React.FC<ITableHeaderProps> = ({
  startRelease,
  orientation,
  toggleOrientation,
  hideQuantityColumns,
}) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 0]} style={{ paddingLeft: '10px' }}>
      <Col span={2}>
        <Text type="paragraph-bold">{t('supply.inventory.release.code')}</Text>
      </Col>
      <Col span={startRelease ? 8 : 11}>
        <Text type="paragraph-bold">
          {t('supply.inventory.release.product')} {'    '}
          <div style={{ cursor: 'pointer' }} onClick={toggleOrientation}>
            <Icon
              color="black"
              icon={`arrow-${orientation === 'asc' ? 'up' : 'down'}`}
              size="ML"
            />
          </div>
        </Text>
      </Col>
      <Col span={startRelease ? 3 : 4}>
        <Text type="paragraph-bold">{t('supply.inventory.release.lot')}</Text>
      </Col>
      <Col span={startRelease ? 3 : 4}>
        <Text type="paragraph-bold">
          {t('supply.inventory.release.invoice')}
        </Text>
      </Col>
      {
        hideQuantityColumns == false &&
        <Col span={startRelease ? 1 : 3}>
          <Text type="paragraph-bold">
            {t('supply.inventory.release.quantity')}
          </Text>
        </Col>
      }
      {startRelease && (
        <>
          <Col span={2}>
            <Text type="paragraph-bold">
              {' '}
              {t('supply.inventory.release.count')}
            </Text>
          </Col>
          {
            hideQuantityColumns == false &&
            <Col span={2}>
              <Text type="paragraph-bold">
                {t('supply.inventory.release.difference')}
              </Text>
            </Col>
          }
          <Col span={2}>
            <Text type="paragraph-bold">
              {' '}
              {t('supply.inventory.release.responsibleTitle')}
            </Text>
          </Col>
        </>
      )}
    </Row>
  );
};
