import React, { useState } from 'react';
import { CompanyResponsibleUserCard } from '..';
import { Form, i18n } from '../../../../Components';
import { IChangeResponsibilityUserRequest } from '../../../../Data/Interfaces/request/Company/ICompanyRequest';
import { IGetCompanyResponse } from '../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { initialValues } from '../CompanyResponsibleUserCard.InitialValues';
import { CompanyResponsibleUserCardSchema } from '../CompanyResponsibleUserCard.schema';
import { CHANGE_RESPONSIBLE_USER_TYPE } from '../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { FormikHelpers, FormikValues } from 'formik';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { GET_ACTIVE_USER_LIST_TYPE } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { messageSucessToast } from '@/Utils/MessageErrorToast';
import { BootysShortcut } from '@/Components/Shortcuts';

interface ICompanyResponsibleUserCard {
  company: IGetCompanyResponse;
  radioCompanyGroup?: boolean;
  radioUser: boolean;
  onChangeRadioUser: (value: boolean) => void;
  getShortcut?: (id: string) => BootysShortcut;
}

export const CompanyResponsibleUserCardWrapper: React.FC<
  ICompanyResponsibleUserCard
> = ({
  company,
  radioCompanyGroup,
  radioUser,
  onChangeRadioUser,
  getShortcut,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { mutateAsync, isLoading } = useControllerMutateApiHook({
    uniqId: CHANGE_RESPONSIBLE_USER_TYPE,
    entityApi: CompanyAPI.changeResponsibilytiUser,
    options: {
      onSuccess: (data, variables, context) => {
        messageSucessToast(
          i18n.t('admin.companyList.changeResponsibleUserSuccess')
        );
      },
    },
  });

  const userList = useControllerQueryListApiHook({
    uniqId: GET_ACTIVE_USER_LIST_TYPE,
    entityApi: UserAPI.listActiveUser,
    autoIncrement: true,
  });

  return (
    <Form
      onSubmit={async (
        values: IChangeResponsibilityUserRequest,
        formikHelpers: FormikHelpers<FormikValues>
      ) => {
        try {
          await mutateAsync(values);
        } finally {
          formikHelpers.setSubmitting(false);
          setButtonDisabled(true);
        }
      }}
      initialValues={company && initialValues(company)}
      schema={CompanyResponsibleUserCardSchema}
    >
      <CompanyResponsibleUserCard
        radioCompanyGroup={!!radioCompanyGroup}
        radioUser={radioUser}
        onChangeRadioUser={onChangeRadioUser}
        isLoading={isLoading}
        setButtonDisabled={setButtonDisabled}
        buttonDisabled={buttonDisabled}
        users={userList?.data}
        editForm
        getShortcut={getShortcut}
      />
    </Form>
  );
};
