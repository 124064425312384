import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC, ReactNode } from 'react';

export interface ShortcutTooltipProps {
  name?: string;
  keys: ReactNode[];
}

export const ShortcutTooltip: FC<ShortcutTooltipProps> = ({ name, keys }) => {
  return (
    <Divflex style={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <span style={{ marginRight: 12 }}>{name}</span>
      {keys}
    </Divflex>
  );
};
