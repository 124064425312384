import { Modal, Text } from '@/Components';

interface IFinishReleaseModalProps {
  visible: boolean;
  changeVisibility: () => void;
}

export const FinishReleaseModal: React.FC<IFinishReleaseModalProps> = ({
  changeVisibility,
  visible,
}) => {
  return (
    <Modal
      title="Finalizar Lançamento"
      visible={visible}
      onCancelClick={changeVisibility}
      onClose={changeVisibility}
      body={
        <div style={{ padding: 20 }}>
          <Text type="paragraph">
            Deseja lançar a contagem e alterar o status do inventário para
            Conferência?
          </Text>
        </div>
      }
      widthModal={400}
      withForm
      okButtonColor="status-success-base"
      okButtonName="Finalizar Lançamento"
    />
  );
};
