import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IHeaderTableOptions } from '@/Components/Table/components/HeaderTable';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { RemoveLinkedModal } from '../Modal/RemoveLinkedModal';
import { IDeleteModalTable } from '@/Components/Table';
import { Placements } from '@/Components/Tooltip';

export const useProductMessageMemorizations = ({
  setVisibleLinkedProductMessageModal,
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addNewProductMessageHotkey = getShortcut('addNewProductMessage');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteProductMessageHotkey = getShortcut('deleteProductMessage');

  const headerOptions: IHeaderTableOptions | undefined = {
    hasSearch: true,
    searchPlaceholder: t(
      'products.productList.productMessage.searchPlaceHolder'
    ),
    deleteButton: {
      permission: {
        permission:
          PermissionEnum.Producao_ClassificacaoEmbalagem_EditarDetalhes,
        tooltip: 'noPermission.supply.messageProduct.removeLink',
      },
      label: t('common.remove'),
      dataTestId: 'delete-product-message-button',
      tooltip: {
        title: deleteProductMessageHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    newButton: {
      icon: 'plus',
      label: t(
        'products.productList.productMessage.buttonLinkedProductMessage'
      ),
      onClick() {
        setVisibleLinkedProductMessageModal(true);
      },
      dataTestId: 'new-product-message-button',
      tooltip: {
        title: addNewProductMessageHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
  };

  const rowsOptions = {
    permission: {
      permission: PermissionEnum.Estoque_MensagemProduto_EditarDetalhes,
      tooltip: 'noPermission.supply.messageProduct.edit',
    },
  };

  const deleteModal: IDeleteModalTable | undefined = useMemo(
    () => ({
      title:
        selectedRowsOnTable &&
        `${t('common.remove')} ${
          selectedRowsOnTable.length > 1
            ? `${selectedRowsOnTable.length} ${t('common.productMessage')}?`
            : `${t(
                'products.productList.productMessage.modalRemoveProductLinkedMessage.messageTitle'
              )}?`
        }`,

      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t('modal.removeProductLinkedMessageModal.removeQuestion')}:`,
            'mensagemDescricao',
            ''
          )
        ) : (
          <RemoveLinkedModal selectedRowsOnTable={selectedRowsOnTable} />
        ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      okButtonName: 'common.remove',
      widthModal: 400,
    }),
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowsOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
