import { FC } from 'react';
import { Button } from '@/Components';
import { useTranslation } from 'react-i18next';
import styles from './DetailsForm.module.scss';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';
import { App } from 'antd';

export const FooterButtons: FC<{ 
  form: any; 
  onChangeDisabled: (x: boolean) => void 
}> = ({ form, onChangeDisabled }) => {
  const { t } = useTranslation();
  const app = App.useApp();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => 
          handleCancelEdit(form, app, () => onChangeDisabled(true))
        }
      />
      <Button
        type="primary"
        children={t('common.save')}
        htmlType="submit"
        dataTestId="submit-packaging-classification"
      />
    </div>
  );
};
