import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';
import DateUtils from '@/Utils/DateUtils';
import _ from 'lodash';

const prescriberI18n = 'prescribers.createPage.error';

export const CreateSchema = yup.object().shape({
  nomeCompleto: yup
    .string()
    .required(i18n.t(`${prescriberI18n}.nameIsRequired`)),
  tipoRegistroId: yup
    .string()
    .required(i18n.t(`${prescriberI18n}.registerTypeIsRequired`))
    .nullable(),

  ufRegistroExternalId: yup
    .string()
    .required(i18n.t(`${prescriberI18n}.registerUfIsRequired`))
    .nullable(),
  codigoRegistro: yup
    .string()
    .required(i18n.t(`${prescriberI18n}.registerCodeIsRequired`)),

  dataNascimento: yup
    .string()
    .nullable()
    .test('date validation', i18n.t('common.dateInvalid'), (val) =>
      val ? DateUtils.isDateValid(val) : true
    )
    .test(
      'date not in future',
      i18n.t(`${prescriberI18n}.dateIsHigher`),
      (val) => {
        if (_.isNil(val) || !DateUtils.isDateValid(val)) return true;

        const [day, month, year] = val.split('/');
        const lossDate = new Date(Number(year), Number(month) - 1, Number(day));
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return lossDate.getTime() <= today.getTime();
      }
    ),
});
