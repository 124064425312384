import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DetailForm } from '../Tabs/DetailForm';
import { PermissionForm } from '../Tabs/PermissionForm';
import { useUserDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/user/details';

export const useEditUserMemorizations = () => {
  const { t } = useTranslation();

  const { getShortcut } = useUserDetailsShortcuts(t, {});

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabPermissionsHotkey = getShortcut('alternateTabPermissions');

  const tabItems = useMemo(
    () => [
      {
        label: t('admin.editUserPage.tab.details'),
        key: 'details',
        children: <DetailForm />,
        dataTestId: 'tab-user-details',
        shortCutTooltip: {
          title: alternateTabDetailsHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
      {
        label: t('admin.editUserPage.tab.permissions'),
        key: 'permissions',
        children: <PermissionForm />,
        dataTestId: 'tab-user-permissions',
        shortCutTooltip: {
          title: alternateTabPermissionsHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
    ],
    [t]
  );

  return {
    tabItems,
  };
};
