import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { history } from '@/Store';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { DeleteModalBody } from '../Modal/DeleteModalBody';
import { useMemo } from 'react';
import { IDeleteModalTable } from '@/Components/Table';
import { Placements } from '@/Components/Tooltip';

export const useSynonymProductMemorizations = ({
  currentProduct,
  selectedRowsOnTable,
  productExternalId,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addNewSynonymProductHotkey = getShortcut('addNewSynonymProduct');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteSynonymProductHotkey = getShortcut('deleteSynonymProduct');

  const headerOptions = {
    hasSearch: true,
    newButton: {
      onClick: () => history.push('synonym/createSynonym'),
      label: t('products.synonym.newSynonym'),
      permission: {
        permission: PermissionEnum.Estoque_ProdutosSinonimo_Cadastrar,
        tooltip:
          'noPermission.supply.products.youCantCreateProductIncompatible',
      },
      dataTestId: 'new-synonym-product-button',
      tooltip: {
        title: addNewSynonymProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    deleteButton: {
      label: t('common.remove'),
      permission: {
        permission: PermissionEnum.Estoque_ProdutosSinonimo_Excluir,
        tooltip:
          'noPermission.supply.products.youCantRemoveProductIncompatible',
      },
      dataTestId: 'delete-synonym-product-button',
      tooltip: {
        title: deleteSynonymProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    searchPlaceholder: t('products.synonym.searchSynonymPlaceholder'),
  };

  const deleteModal: IDeleteModalTable | undefined = useMemo(
    () => ({
      title:
        selectedRowsOnTable.length === 1
          ? t('modal.deleteModal.single.productSynonymTitle')
          : t('modal.deleteModal.many.productSynonym'),
      body: (
        <DeleteModalBody
          selectedRowsOnTable={selectedRowsOnTable}
          currentProduct={currentProduct ? currentProduct.descricao : ''}
        />
      ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      okButtonName: t('common.remove'),
    }),
    [t, selectedRowsOnTable, currentProduct]
  );

  const rowsOptions = {
    onRowDoubleClick: (x: any) => {
      history.push(
        `/inventory/products/${productExternalId}/synonym/${x.externalId}/synonymDetails`
      );
    },
  };

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    deleteModal,
    rowsOptions,
    tableHeaderHotKeyTooltip,
  };
};
