import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeleteUserModal } from './Modals/DeleteUserModal';
import { HorizontalTabs } from '../../../../../Components';
import { useEditUserMemorizations } from './Hooks/useEditUserMemorizations';
import { useEditUserCallAPI } from './Hooks/useEditUserCallAPI';
import { useEditUserStates } from './Hooks/useEditUserStates';
import { PageLoading } from '@/Layouts/PageLoading';
import { useUserDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/user/details';

export const EditUser: React.FC = () => {
  const { t } = useTranslation();

  const params: Readonly<Partial<{ externalId: string | undefined }>> =
    useParams();

  const { modalDeleteUserIsVisible, setModalDeleteUserIsVisible } =
    useEditUserStates();

  const { userData } = useEditUserCallAPI({
    externalId: params.externalId,
  });

  const { tabItems } = useEditUserMemorizations();

  const { getShortcut } = useUserDetailsShortcuts(t, {});

  const editMoreActionsHotkey = getShortcut('editMoreActions');

  return (
    <PageLoading loading={userData.isLoading}>
      <HorizontalTabs
        type="secondary"
        items={tabItems}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        moreActionsButton={{
          title: editMoreActionsHotkey.tooltip,
        }}
        dropdownItens={[
          {
            label: t('common.delete'),
            icon: 'trash',
            onClick: () => setModalDeleteUserIsVisible(true),
            dataTestId: 'dropdown-option-delete',
            key: 'backspace',
          },
        ]}
        dataTestIdDropdownButton="more-actions-button"
      />
      {userData.data && (
        <DeleteUserModal
          user={userData.data}
          onCancelClick={() => setModalDeleteUserIsVisible(false)}
          visible={modalDeleteUserIsVisible}
        />
      )}
    </PageLoading>
  );
};
