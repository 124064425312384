import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

export const useOrderTable = (
  initialIndexCount: number,
  finalIndexCount: number
) => {
  const form: any = useFormikContext();
  const [orientation, setOrientation] = useState('desc');
  const [isInitialRender, setIsInitialRender] = useState(true);

  const toggleOrientation = () => {
    setOrientation((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const orderItems = (tableItems: any[]) => {
    return tableItems.sort((a, b) => {
      if (orientation === 'desc') {
        return a.produtoDescricao.localeCompare(b.produtoDescricao);
      } else if (orientation === 'asc') {
        return b.produtoDescricao.localeCompare(a.produtoDescricao);
      } else {
        return 0;
      }
    });
  };

  useEffect(() => {
    if (orientation === 'asc') {
      setIsInitialRender(false);
      const currentItems = [...form.values.itens];

      const sectionToReverse = currentItems.slice(
        initialIndexCount,
        finalIndexCount
      );

      const reversedSection = sectionToReverse.reverse();

      const updatedItems = [
        ...currentItems.slice(0, initialIndexCount),
        ...reversedSection,
        ...currentItems.slice(finalIndexCount),
      ];
      form.setFieldValue('itens', updatedItems);
    }

    if (orientation === 'desc' && !isInitialRender) {
      setIsInitialRender(false);
      const currentItems = [...form.values.itens];

      const sectionToReverse = currentItems.slice(
        initialIndexCount,
        finalIndexCount
      );

      const reversedSection = sectionToReverse.reverse();

      const updatedItems = [
        ...currentItems.slice(0, initialIndexCount),
        ...reversedSection,
        ...currentItems.slice(finalIndexCount),
      ];
      form.setFieldValue('itens', updatedItems);
    }
  }, [orientation]);

  return {
    orientation,
    toggleOrientation,
    orderItems,
  };
};
