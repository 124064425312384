import { useState } from 'react';

export const useProductMessageStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);

  const [
    visibleLinkedProductMessageModal,
    setVisibleLinkedProductMessageModal,
  ] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<string[] | undefined>(
    undefined
  );

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleLinkedProductMessageModal,
    setVisibleLinkedProductMessageModal,
    selectedItems,
    setSelectedItems,
  };
};
