import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { FETCH_LIST_GROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { App } from 'antd';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { IUseFunctions } from './interfaces';

export const useListFunctions = ({
  deleteGroup,
  setSelectedRowsOnTable,
  setVisibleModal,
  setVisibleEditModal,
}: IUseFunctions) => {
  const queryClient = useQueryClient();
  const app = App.useApp();

  const onSelectedRowsOnTable = useCallback(
    (x: IProductGroupListData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  const onDelete = useCallback(
    async (group: IProductGroupListData[]) => {
      try {
        const gruposExternalId = group.map((x) => x?.externalId);
        const res = await deleteGroup.mutateAsync(
          { gruposExternalId },
          {
            onError: handleModalError(app),
          }
        );
        if (res.success) {
          queryClient.invalidateQueries([FETCH_LIST_GROUP]);
        }
      } catch {}
    },
    [deleteGroup, app, queryClient]
  );

  const onVisibleModal = useCallback(
    (x: boolean) => setVisibleModal(x),
    [setVisibleModal]
  );

  const onVisibleEditModal = useCallback(
    (x: boolean) => setVisibleEditModal(x),
    [setVisibleEditModal]
  );

  return {
    onSelectedRowsOnTable,
    onDelete,
    onVisibleModal,
    onVisibleEditModal,
  };
};
