import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Table } from '@/Components/Table';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { productMessageColumns } from './productMessage.columns';
import { LinkedProdutoMessageModal } from './Modal/LinkedProductMessageModal';
import { useProductMessageStates } from './Hooks/useProductMessageStates';
import { useProductMessageCallAPI } from './Hooks/useProductMessageCallAPI';
import { useProductMessageFunctions } from './Hooks/useProductMessageFunctions';
import { useProductMessageMemorizations } from './Hooks/useProductMessageMemorizations';
import { useProductsProductMessageShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/products/productMessage';

export const ProductMessageListTab: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    visibleLinkedProductMessageModal,
    setVisibleLinkedProductMessageModal,
    selectedItems,
    setSelectedItems,
  } = useProductMessageStates();

  const {
    productMessageLinked,
    isLoading,
    refetchProductMessageLinkedList,
    removeProductLinkedMsg,
  } = useProductMessageCallAPI({
    externalId,
  });

  const { selectRowsOnTable, onDelete, changeVisibleModal } =
    useProductMessageFunctions({
      removeProductLinkedMsg,
      externalId,
      setSelectedRowsOnTable,
      setVisibleLinkedProductMessageModal,
    });

  const { getShortcut } = useProductsProductMessageShortcuts(t, {});

  const { headerOptions, rowsOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useProductMessageMemorizations({
      setVisibleLinkedProductMessageModal,
      selectedRowsOnTable,
      getShortcut,
    });

  return (
    <ContainerPage>
      <Table
        columns={productMessageColumns(t, externalId)}
        hasSelection
        headerOptions={headerOptions}
        loading={isLoading}
        pagination={productMessageLinked}
        onRefetch={refetchProductMessageLinkedList}
        onDelete={onDelete}
        rowsOptions={rowsOptions}
        paginationCustomExternalId="mensagemExternalId"
        deleteModal={deleteModal}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
      />

      {visibleLinkedProductMessageModal && (
        <LinkedProdutoMessageModal
          visible
          changeVisible={changeVisibleModal}
          produtoExternalId={externalId}
        />
      )}
    </ContainerPage>
  );
};
