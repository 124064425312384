import { FC } from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { PageLoading } from '@/Layouts/PageLoading';
import { customersCollums } from './customers.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';

export const List: FC = () => {
  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    changeStatus,
    deleteClient,
    clientList,
    isLoading,
    refetchClientList,
  } = useListCallAPI();

  const { onEdit, onSelectedRowsOnTable, onRowDoubleClick, onDelete } =
    useListFunctions({
      changeStatus,
      setSelectedRowsOnTable,
      deleteClient,
    });

  const { headerOptions, rowsOptions, deleteModal } = useListMemorizations({
    onRowDoubleClick,
    selectedRowsOnTable,
  });

  const { t } = useTranslation();

  return (
    <>
      <PageLoading loading={isLoading}>
        <Table
          columns={customersCollums(t)}
          headerOptions={headerOptions}
          onEdit={onEdit}
          editStatusModalLoading={changeStatus.isLoading}
          setSelectedRowsOnTable={onSelectedRowsOnTable}
          rowsOptions={rowsOptions}
          editOptions={editTableStatus()}
          loading={isLoading}
          hasSelection
          fieldStatus="ativo"
          pagination={clientList}
          onRefetch={refetchClientList}
          onDelete={onDelete}
          deleteModal={deleteModal}
        />
      </PageLoading>
    </>
  );
};
