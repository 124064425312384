import _, { debounce } from 'lodash';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { VALIDATION_CNPJ_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';

export const useCheckCnpjAlreadyUsed = () => {
  const { mutateAsync } = useControllerMutateApiHook({
    uniqId: VALIDATION_CNPJ_CLIENT,
    entityApi: ClientAPI.cnpjClientValidation,
  });

  const checkCnpjDuplicationsDebounced: any = debounce(
    async (
      value: string | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = (await mutateAsync(value)) as boolean;
        callback(result);
      } catch {
        return callback(false);
      }
    },
    500
  );

  return {
    checkCnpjDuplicationsDebounced,
  };
};
