import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IGetStandardFormula,
  IStandardFormulaList,
} from '../../Interfaces/response/StandardFormula/IStandardFormulaResponse';
import {
  ICreateStandardFormula,
  IDeleteStandardFormulaRequest,
  IEditStandardFormula,
} from '../../Interfaces/request/StandardFormula/IStandardFormulaRequest';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class StandardFormulaAPI {
  public static async postStandardFormula(body: ICreateStandardFormula) {
    const res = await Http.fetcher(`/pharma/producao/v1/FormulaPadrao`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getStandardFormulaList(
    context: QueryFunctionContext
  ): Promise<IStandardFormulaList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IStandardFormulaList>(
      Http.AddQueryParams(
        `/pharma/producao/v1/FormulaPadrao/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getStandardFormula(
    context: QueryFunctionContext
  ): Promise<IGetStandardFormula | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetStandardFormula>(
      `/pharma/producao/v1/FormulaPadrao/${context.queryKey[1]}`
    );
  }

  public static async deleteStandardFormula(
    body: IDeleteStandardFormulaRequest
  ) {
    const res = await Http.fetcher(`/pharma/producao/v1/FormulaPadrao`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async isStandardFormulaNameAlreadyRegistred(
    produtoExternalId?: string
  ): Promise<boolean> {
    if (!produtoExternalId) return false;

    try{
      const result = await Http.fetcher(
        '/pharma/producao/v1/FormulaPadrao/ValidarProduto',
        {
          method: 'POST',
          body: JSON.stringify({ produtoExternalId }),
        }
      );

      return result.success;
    } catch(e) {
      return false;
    }
  }

  public static async editStandardFormula(body: IEditStandardFormula) {
    return await Http.fetcher(`/pharma/producao/v1/FormulaPadrao`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
}
