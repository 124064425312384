import { FC } from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { PageLoading } from '@/Layouts/PageLoading';
import { prescribersCollums } from './prescribers.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';

export const List: FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    changeStatus,
    prescribersList,
    changePrescriberStatus,
    deletePrescribers,
  } = useListCallAPI();

  const { onDelete, onEdit } = useListFunctions({
    deletePrescribers,
    changePrescriberStatus,
  });

  const { headerOptions, rowOptions, deleteModal } = useListMemorizations({
    selectedRowsOnTable,
  });

  return (
    <>
      <PageLoading loading={prescribersList.isLoading}>
        <Table
          columns={prescribersCollums(t)}
          headerOptions={headerOptions}
          onDelete={onDelete}
          onEdit={onEdit}
          editStatusModalLoading={changeStatus.isLoading}
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          rowsOptions={rowOptions}
          editOptions={editTableStatus()}
          loading={prescribersList.isLoading}
          hasSelection
          fieldStatus="ativo"
          pagination={prescribersList.data}
          onRefetch={prescribersList.refetch}
          deleteModal={deleteModal}
        />
      </PageLoading>
    </>
  );
};
