import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { FETCH_SIMPLE_LIST_PRESCRIBERS } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';

export const useHeaderRecipeDescriptionCallAPI = () => {
  const {
    data: clientList,
    isLoading: clientListLoading,
    fetchNewPage: fetchNewClientPage,
    refetch: refetchClientList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_CLIENT,
    entityApi: ClientAPI.listClient,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
  });

  const {
    data: pharmaceuticalList,
    isLoading: pharmaceuticalListLoading,
    fetchNewPage: fetchNewPagePharmaceuticalList,
  } = useControllerQueryListApiHook({
    uniqId: LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.listPharmaceuticalFormForDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const prescriberList = useControllerQueryListApiHook({
    uniqId: FETCH_SIMPLE_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribersForDropdown,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'nomeCompleto', direction: 'ASC' },
    },
  });

  return {
    clientList,
    clientListLoading,
    fetchNewClientPage,
    refetchClientList,

    pharmaceuticalList,
    pharmaceuticalListLoading,
    fetchNewPagePharmaceuticalList,

    prescriberList,
  };
};
