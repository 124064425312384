export const useFormatInvoice = () => {
  const formatString = (input: string): string => {
    if (!/^[0-9]+\/[0-9]+$/.test(input)) {
      throw new Error("Formato inválido. Deve estar no formato '1311/1'.");
    }
    const [left, right] = input.split('/');

    const leftPadded = left.padStart(9, '0');

    const rightPadded = right.padStart(3, '0');

    return `${leftPadded} / ${rightPadded}`;
  };

  return { formatString };
};
