import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CREATE_INVENTORY } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { messageErrorToast } from '@/Utils/MessageErrorToast';

export const useStartRelease = () => {
  const createRelease: any = useControllerMutateApiHook({
    uniqId: CREATE_INVENTORY,
    entityApi: InventoryAPI.createRelease,
    options: {
      onError: (x) => messageErrorToast(x),
    },
  });

  return { createRelease };
};
