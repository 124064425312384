import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CREATE_MANIPULATED_RECIPE } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { IUseManipulatedRecipeCallAPI } from './interfaces';

export const useManipulatedRecipeCallAPI = ({
  setStepCurrent,
}: IUseManipulatedRecipeCallAPI) => {
  const { mutateAsync: createManipulatedRecipe } = useControllerMutateApiHook({
    uniqId: CREATE_MANIPULATED_RECIPE,
    entityApi: SalesOrderAPI.createManipulatedRecipe,
    options: {
      onSuccess: () => setStepCurrent((x) => x + 1),
    },
  });

  return { createManipulatedRecipe };
};
