import { LeaveWithoutSave } from "@/Components/ModalLeaveWithoutSave";
import { useAppProps } from "antd/es/app/context";
import { FormikContextType } from "formik";

/**
 * Adds behavior to the cancel form editing button so that it displays a confirmation modal.
 * @param form - The Formik form context, which provides information about the current state of the form.
 *               Used to check if the form has unsaved changes (`form.dirty`).
 * 
 * @param app - The application context provided by Ant Design's `App.useApp()`. This provides access 
 *              to utility methods such as `modal.info` to display modals.
 * 
 * @param onOkClick - A callback function to execute if the cancel action is confirmed by the user 
 *                    or if there are no unsaved changes in the form.
 */
export const handleCancelEdit = (
  form: FormikContextType<any>, 
  app: useAppProps,
  onOkClick?: () => void
) => {
  if(form.dirty) {
    const modal = app.modal.info({});

    modal.update(LeaveWithoutSave({ form, onOkClick }, modal)); 
  } else {
    onOkClick && onOkClick();
  }
};