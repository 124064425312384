import { UppercaseFirstLetter } from '@/Utils/CapitalFirstLetterUtils';
import { deleteModalBody, deleteModalTitle } from '../Modals/DeleteModal';
import { history } from '../../../../../../Store';
import { IDeleteModalTable, ITranslationsTable } from '@/Components/Table';
import { ConfirmBody } from '../Modals/ConfirmStatusModal';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { Placements } from '@/Components/Tooltip';

export const useUserListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
  getShortcuts,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addUserHotkey = getShortcut('addUser');

  const deleteUserHotkey = getShortcut('deleteSelected');
  const editUserHotkey = getShortcut('editSelected');

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      nameKey: 'nome',
      lastNameKey: 'sobrenome',
      searchPlaceholder: t('admin.userList.searchPlaceholder'),
      newButton: {
        onClick: () => history.push('/admin/user/create'),
        dataTestId: 'create-user',
        permission: {
          permission: 1003,
          tooltip: 'noPermission.administrative.users.create',
        },
        tooltip: {
          title: addUserHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        label: `${t('common.new')} ${UppercaseFirstLetter(t('common.user'))}`,
      },
      ...((selectedRowsOnTable?.every((x: any) => x.ativo) ||
        selectedRowsOnTable?.every((x: any) => !x.ativo)) && {
        editButton: {
          dataTestId: 'edit-user',
          permission: {
            permission: 1005,
            tooltip: 'noPermission.administrative.users.statusEdit',
          },
          tooltip: {
            title: editUserHotkey.tooltip,
            placement: 'topRight' as Placements,
          },
          options: [
            {
              label: t('common.status'),
              isStatusOnly: true,
              key: 's',
            },
          ],
        },
      }),
      deleteButton: {
        dataTestId: 'delete-user',
        permission: {
          permission: 1008,
          tooltip: 'noPermission.administrative.users.delete',
        },
        tooltip: {
          title: deleteUserHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
    }),
    [selectedRowsOnTable, t]
  );

  const rowOptions = useMemo(
    () => ({
      permission: {
        permission: 1002,
        tooltip: 'noPermission.administrative.users.detail',
      },
      onRowDoubleClick: (x: any) =>
        history.push(`/admin/user/${x.externalId}/details`),
    }),
    []
  );

  const editModal = useMemo(
    () => ({
      okButtonName: t('common.save'),
      withForm: false,
    }),
    [t]
  );

  const deleteModal = useMemo(
    (): IDeleteModalTable =>
      selectedRowsOnTable
        ? {
            title: deleteModalTitle(selectedRowsOnTable, t),
            body: deleteModalBody(selectedRowsOnTable, t),

            buttons: {
              okButtonColor: 'status-danger-base',
              cancelButtonDatTestId: 'cancel-delete-user',
              okButtonDataTestId: 'ok-delete-user',
            },
            className: 'delete-one-user-modal',
          }
        : {},
    [selectedRowsOnTable, t]
  );

  const confirmEditModal = useMemo(
    () => ({
      title: `${t(`modal.editModal.single.confirm.inactivate`)} ${
        selectedRowsOnTable?.length === 1
          ? `“${selectedRowsOnTable[0].nome} ${selectedRowsOnTable[0].sobrenome}”`
          : selectedRowsOnTable?.length
      }${
        selectedRowsOnTable && selectedRowsOnTable?.length > 1
          ? ` ${t('modal.deleteModal.users')}`
          : ''
      }?`,
      body: selectedRowsOnTable && (
        <ConfirmBody
          itemNames={selectedRowsOnTable.map((x) => ({
            name: x.nome,
            lastName: x.sobrenome,
          }))}
        />
      ),
      okButtonName: t('common.inactivate'),
    }),
    [selectedRowsOnTable, t]
  );

  const translations = useMemo(
    (): ITranslationsTable => ({
      editModal: {
        customEndTitle: t('common.users'),
      },
    }),
    [t]
  );

  const selectAllTableHotKey = getShortcut('selectAll');

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    editModal,
    deleteModal,
    confirmEditModal,
    translations,
    tableHeaderHotKeyTooltip,
  };
};
