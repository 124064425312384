import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Table } from '../../../../../Components/Table';
import { pharmaceuticalFormColumns } from './PharmaceuticalForm.column';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';

export const PharmaceuticalFormList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    deletePharmaceuticalForm,
    editPharmaceuticalFormStatus,
    pharmaceuticalFormList,
    isLoadingPharmaceuticalFormList,
    refetchPharmaceuticalLit,
  } = useListCallAPI();

  const { headerOptions, rowOptions, deleteModal } = useListMemorizations({
    pharmaceuticalFormList,
    selectedRowsOnTable,
  });

  const { onSelectedRowsOnTable, onEdit, onDelete } = useListFunctions({
    setSelectedRowsOnTable,
    editPharmaceuticalFormStatus,
    deletePharmaceuticalForm,
  });

  return (
    <PageLoading loading={isLoadingPharmaceuticalFormList}>
      <Table
        columns={pharmaceuticalFormColumns(t)}
        loading={isLoadingPharmaceuticalFormList}
        headerOptions={headerOptions}
        pagination={pharmaceuticalFormList}
        onEdit={onEdit}
        fieldStatus="ativo"
        editOptions={editTableStatus()}
        editStatusModalLoading={editPharmaceuticalFormStatus.isLoading}
        setSelectedRowsOnTable={onSelectedRowsOnTable}
        hasSelection
        rowsOptions={rowOptions}
        onRefetch={refetchPharmaceuticalLit}
        onDelete={onDelete}
        deleteModal={deleteModal}
      ></Table>
    </PageLoading>
  );
};
export default PharmaceuticalFormList;
