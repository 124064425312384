import { Table } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { productSynonymColumns } from './columns';
import { useParams } from 'react-router';
import { useAssociatedProductStates } from './Hooks/useAssociatedProductStates';
import { useAssociatedProductCallAPI } from './Hooks/useAssociatedProductCallAPI';
import { useAssociatedProductMemorizations } from './Hooks/useAssociatedProductMemorizations';
import { useAssociatedProductFunctions } from './Hooks/useAssociatedProductFunctions';
import { useProductsAssociatedProductShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/products/associatedProduct';

export const AssociatedProduct: FC = () => {
  const { t } = useTranslation();
  const { externalId: productExternalId } = useParams<{ externalId: string }>();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useAssociatedProductStates();

  const { getShortcut } = useProductsAssociatedProductShortcuts(t, {});

  const {
    currentProduct,
    deleteAssociatedProduct,
    productList,
    isProductListLoading,
    refetchProductList,
  } = useAssociatedProductCallAPI({
    productExternalId,
  });

  const { headerOptions, deleteModal, rowOptions, tableHeaderHotKeyTooltip } =
    useAssociatedProductMemorizations({
      selectedRowsOnTable,
      currentProduct,
      productExternalId,
      getShortcut,
    });

  const { onDelete } = useAssociatedProductFunctions({
    deleteAssociatedProduct,
  });

  return (
    <PageLoading loading={isProductListLoading}>
      <Table
        loading={isProductListLoading}
        columns={productSynonymColumns(t)}
        hasSelection
        onDelete={onDelete}
        headerOptions={headerOptions}
        pagination={productList}
        onRefetch={refetchProductList}
        deleteModal={deleteModal}
        rowsOptions={rowOptions}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
      />
    </PageLoading>
  );
};
