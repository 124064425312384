import { useMemo } from 'react';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Text } from '@/Components';
import ReactMarkdown from 'react-markdown';
import { IDeleteModalTable } from '@/Components/Table';
import { IUseMemorizations } from './interfaces';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addUserGroupHotkey = getShortcut('addUserGroup');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteUserGroupHotkey = getShortcut('deleteSelected');
  const editUserGroupHotkey = getShortcut('editSelected');

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      searchPlaceholder: t('admin.editUserGroups.searchPlaceholder'),
      newButton: {
        dataTestId: 'create-group',
        onClick: () => history.push('/admin/user-group/create'),
        permission: {
          permission: 1011,
          tooltip: 'noPermission.administrative.userGroups.create',
        },
        tooltip: {
          title: addUserGroupHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        label: `${t('common.new')} ${t('common.userGroup')}`,
      },
      editButton: {
        permission: {
          permission: 1013,
          tooltip: 'noPermission.administrative.userGroups.statusEdit',
        },
        tooltip: {
          title: editUserGroupHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        options: [
          {
            label: t('common.status'),
            isStatusOnly: true,
            key: 's',
          },
        ],
      },
      deleteButton: {
        permission: {
          permission: 1015,
          tooltip: 'noPermission.administrative.userGroups.delete',
        },
        tooltip: {
          title: deleteUserGroupHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      nameKey: 'nome',
    }),
    [t]
  );

  const rowOptions = useMemo(
    () => ({
      permission: {
        permission: 1010,
        tooltip: 'noPermission.administrative.userGroups.detail',
      },
      onRowDoubleClick: (userGroup: any) =>
        history.push(`/admin/user-group/${userGroup.externalId}/details`),
    }),
    []
  );

  const deleteModal = useMemo(
    (): IDeleteModalTable => ({
      customEndTitle: t('common.userGroups'),

      body:
        selectedRowsOnTable?.length > 1
          ? DeleteModalOnTable(
              selectedRowsOnTable,
              `${t('modal.deleteModal.many.title')} ${t(
                'common.theUsersGroups'
              )}:`,
              'nome',
              t('common.groups')
            )
          : selectedRowsOnTable && (
              <div style={{ padding: '20px' }}>
                <Divflex>
                  <Text
                    color="text-50"
                    type="ui-tiny-content"
                    withoutMarginBottom
                  >
                    <ReactMarkdown>
                      {t(
                        'admin.userGroupList.deleteUserGroupModal.description',
                        {
                          userGroup: `**${selectedRowsOnTable[0]?.nome}**`,
                        }
                      )}
                    </ReactMarkdown>
                  </Text>
                </Divflex>
                <Text
                  color="text-50"
                  type="ui-tiny-content"
                  children={t('common.finalDescription')}
                />
              </div>
            ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
    }),
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
