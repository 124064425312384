import { useTranslation } from 'react-i18next';
import { DetailsTab } from '../../DetailsTab';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { ReleaseTab } from '../../ReleaseTab';

export const useTabs = (edit: boolean, setEdit: any) => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('common.details'),
      key: 'details',
      children: <DetailsTab edit={edit} setEdit={setEdit} />,
    },
    {
      label: t('common.release'),
      key: 'release',
      children: <ReleaseTab />,
      tabPermission: PermissionEnum.Estoque_Inventario_VerDetalhesConferencia,
      permissionTooltip: 'noPermission.supply.inventory.release',
    },
  ];

  return {
    tabs,
  };
};
