import React, { FC, useMemo, useState } from 'react';
import { Row, Col, App } from 'antd';
import { FooterButton } from '../Components/FooterButton';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { cnaeItems } from './CNAE';
import { CompanyDataCardSchema } from './CompanyDataCard.schema';
import { Card } from '../../../Components/Card';
import { Dropdown, Form } from '../../../Components';
import { TextInputWithMask } from '../../../Components/Inputs/TextInputWithMask';
import { TextInput } from '../../../Components/Inputs/TextInput';
import { TextInputPhoneMask } from '../../../Components/Inputs/TextInputPhoneMask';
import { IChangeCompanyRequest } from '../../../Data/Interfaces/request/Company/ICompanyRequest';
import { RemoveMask } from '../../../Utils/MasksUtils';
import { CHANGE_INFO_TYPE } from '../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { initialValues } from './CompanyDataCard.InitialValues';
import { IGetCompanyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';
import { useTranslation } from 'react-i18next';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import _ from 'lodash';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { useCheckCnpjAlreadyUsed } from '@/Pages/Sectors/AdminBootis/Company/CompanyCreate/Hooks/useInputCnpjFunctions';
import { BootysShortcut, getShortcut } from '@/Components/Shortcuts';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';

interface ICompanyCard {
  editForm?: boolean;
  buttonDisabled?: boolean;
  onButtonChange?: (value: boolean) => void;
  loading?: boolean;
  getShortcut?: (id: string) => BootysShortcut;
}

export const CompanyDataCard: FC<ICompanyCard> = ({
  editForm,
  buttonDisabled,
  onButtonChange,
  loading,
  getShortcut,
}) => {
  const form = useFormikContext();
  const { t } = useTranslation();
  const app = App.useApp();

  const companyI18n = useMemo(
    () => 'adminBootis.createCompany.companyDataCard',
    []
  );

  const editDataHotkeyTooltip = getShortcut && getShortcut('editInfo');

  return (
    <Card
      title={t('adminBootis.createCompany.companyData')}
      withoutCollapse={editForm}
    >
      <Row>
        <Col flex="30%">
          <TextInputWithMask
            name="cnpj"
            label={t(`${companyI18n}.cnpj`)}
            mask="cnpjMask"
            placeHolder={t(`${companyI18n}.cnpjPlaceholder`)}
            disabled={buttonDisabled}
            required
            dataTestId="cnpj"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={12}>
          <TextInput
            name="razaoSocial"
            label={t(`${companyI18n}.corporateName`)}
            placeHolder={t(`${companyI18n}.corporateNamePlaceholder`)}
            disabled={buttonDisabled}
            required
            dataTestId="corporate-name"
          />
        </Col>
        <Col span={12}>
          <TextInput
            name="nomeFantasia"
            label={t(`${companyI18n}.fantasyName`)}
            placeHolder={t(`${companyI18n}.fantasyNamePlaceholder`)}
            disabled={buttonDisabled}
            required
            dataTestId="fantasy-name"
          />
        </Col>
      </Row>
      <Row gutter={[20, 0]}>
        <Col span={8}>
          <TextInputWithMask
            name="inscricaoEstadual"
            withoutMarginBottom
            label={t(`${companyI18n}.stateRegistration`)}
            placeHolder={t(`${companyI18n}.stateRegistrationPlaceholder`)}
            mask="stateRegistration"
            disabled={buttonDisabled}
            required
            dataTestId="state-registration"
          />
        </Col>
        <Col span={8}>
          <TextInputWithMask
            name="inscricaoMunicipal"
            withoutMarginBottom
            label={t(`${companyI18n}.municipalRegistration`)}
            placeHolder={t(`${companyI18n}.municipalRegistrationPlaceholder`)}
            mask="municipalRegistration"
            disabled={buttonDisabled}
          />
        </Col>
        <Col span={8}>
          <Dropdown
            name="cnae"
            label={t(`${companyI18n}.cnae`)}
            items={cnaeItems}
            placeHolder={t(`${companyI18n}.cnaePlaceholder`)}
            disabled={buttonDisabled}
            required
            dataTestId="cnae"
          />
        </Col>
      </Row>
      <Row gutter={[20, 0]}>
        <Col span={8}>
          <TextInput
            name="email"
            withoutMarginBottom
            label={t(`${companyI18n}.email`)}
            placeHolder={t(`${companyI18n}.emailPlaceholder`)}
            disabled={buttonDisabled}
            dataTestId="email"
          />
        </Col>
        <Col span={8}>
          <TextInputPhoneMask
            name="telefone"
            withoutMarginBottom
            label={t(`${companyI18n}.cell`)}
            placeHolder={t(`${companyI18n}.cellPlaceholder`)}
            disabled={buttonDisabled}
            dataTestId="cellphone"
          />
        </Col>
        <Col span={8}>
          <TextInput
            name="site"
            withoutMarginBottom
            label={t(`${companyI18n}.website`)}
            placeHolder={t(`${companyI18n}.websitePlaceholder`)}
            disabled={buttonDisabled}
            dataTestId="website"
          />
        </Col>
      </Row>
      {onButtonChange && form && (
        <FooterButton
          disabled={!!buttonDisabled}
          editLabel={t(`${companyI18n}.edit`)}
          onChange={(value) => onButtonChange(value)}
          loading={loading}
          dataTestId="data-edit-button"
          tooltip={{
            title: editDataHotkeyTooltip?.tooltip,
            placement: 'top',
            targetOffset: [500, 0],
          }}
          cancelButtonClick={() => handleCancelEdit(form, app, () => onButtonChange(true))}
        />
      )}
    </Card>
  );
};
interface ICompanyDataCard {
  company: IGetCompanyResponse;
  getShortcut?: (id: string) => BootysShortcut;
}

export const CompanyDataCardWrapper: React.FC<ICompanyDataCard> = ({
  company,
  getShortcut,
}) => {
  const { t } = useTranslation();

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { isLoading, mutateAsync } = useControllerMutateApiHook({
    uniqId: CHANGE_INFO_TYPE,
    entityApi: CompanyAPI.changeInfoCompany,
    options: {
      onSuccess: () => setButtonDisabled(true),
    },
  });

  const onSubmit = async (
    values: IChangeCompanyRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    try {
      var body: IChangeCompanyRequest = {
        ...values,
        helpers,
      };
      body.cnpj = RemoveMask(body.cnpj) || '';

      await mutateAsync(body);
    } finally {
      helpers.setSubmitting(false);
    }
  };

  const { checkCnpjDuplicationsDebounced } = useCheckCnpjAlreadyUsed();

  return (
    <>
      <Form
        onSubmit={onSubmit}
        schema={CompanyDataCardSchema(checkCnpjDuplicationsDebounced, t)}
        initialValues={company && initialValues(company)}
        individualValidateOnChange
      >
        <ConnectedFocusError />
        <CompanyDataCard
          editForm
          buttonDisabled={buttonDisabled}
          onButtonChange={(value) => setButtonDisabled(value)}
          loading={isLoading}
          getShortcut={getShortcut}
        />
      </Form>
    </>
  );
};
