import React from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { companyCollums } from './company.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { PageLoading } from '@/Layouts/PageLoading';
import { useCompanyListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/companys/list';

export const List: React.FC = () => {
  const { t } = useTranslation();
  const { rowSelected, setRowSelected, selectedItems, setSelectedItems } =
    useListStates();

  const { companysList, changeResponsibilytiUser } = useListCallAPI();

  const { onEdit, onRowDoubleClick, modalTitle } = useListFunctions({
    rowSelected,
    changeResponsibilytiUser,
    setRowSelected,
  });

  const { getShortcut } = useCompanyListShortcuts(t, {});

  const { headerOptions, editModal, rowOptions, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      rowSelected,
      onRowDoubleClick,
      modalTitle,
      getShortcut,
    });

  return (
    <PageLoading loading={companysList.isLoading}>
      <Table
        columns={companyCollums(t)}
        headerOptions={headerOptions}
        loading={companysList.isLoading}
        hasSelection
        pagination={companysList.data}
        onEdit={onEdit}
        editModal={editModal}
        editStatusModalLoading={changeResponsibilytiUser.isLoading}
        onRefetch={companysList.refetch}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        rowsOptions={rowOptions}
        keyToSelect="externalId"
        selectedRowsOnTable={rowSelected}
        setSelectedRowsOnTable={setRowSelected}
        initialRowsSelected={selectedItems}
      />
    </PageLoading>
  );
};
