import { Col, Row } from 'antd';
import styles from '../../styles.module.scss';
import { Avatar, PopOver, Text } from '@/Components';
import { useFormatInvoice } from '../../hooks/useFormatInvoice';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { useListUnitByClass } from '@/Hooks/HooksServices/Supply/UnitMeasurement/useListUnitByClass';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useCalculateDeference } from './hooks/useCalculateDiferrence';
import { CircularStatus } from '@/Components/CircularStatus';
import { SelectPersonModal } from './components/SelectPersonModal';
import { useTranslation } from 'react-i18next';

interface ITableRowProps {
  index: number;
  item: any;
  startRelease?: boolean;
  supplyPlaceExternalId?: string;
  hideQuantityColumns: boolean;
}

export const TableRow: React.FC<ITableRowProps> = ({
  index,
  item,
  startRelease,
  supplyPlaceExternalId,
  hideQuantityColumns,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const { formatString } = useFormatInvoice();
  const [showPersonModal, setShowPersonModal] = useState(false);
  const { calculateDeference, defineColor } = useCalculateDeference(
    index,
    item.quantidade
  );
  const unitMeasurement = useListUnitByClass({
    classIds: [item.classeProdutoId],
    fieldName: `itens.${index}.unidadeMedidaId`,
    isDropdownRight: true,
    notRefetch: true,
  });

  useEffect(() => {
    form.setFieldValue(`itens.${index}.unidadeMedidaId`, item.unidadeMedidaId);
    form.setFieldValue(`itens.${index}.usuarioExternalId`, null);
  }, []);

  return (
    <Row
      className={`${styles['row']} ${index % 2 !== 0 ? styles['even'] : ''}`}
      gutter={[16, 0]}
      style={{ paddingLeft: '10px' }}
    >
      <Col span={2}>
        <Text type="paragraph">{item.produtoCodigo}</Text>
      </Col>
      <Col span={startRelease ? 8 : 11}>
        <Text type="paragraph-bold">{item.produtoDescricao}</Text>
      </Col>
      <Col span={startRelease ? 3 : 4}>
        <Text type="paragraph">{item.loteNumero}</Text>
      </Col>
      <Col span={startRelease ? 3 : 4}>
        <Text type="paragraph">{formatString(item.notaFiscalNumero)}</Text>
      </Col>
      {
        hideQuantityColumns == false &&
        <Col span={startRelease ? 1 : 3}>
          <Text type="paragraph">
            {item.quantidade}
            {item.unidadeMedidaAbreviacao}
          </Text>
        </Col>
      }
      {startRelease && (
        <>
          <Col
            span={2}
            style={{
              height: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ marginTop: '22px' }}>
              <WeightInput
                name={`itens.${index}.quantidadeInventariada`}
                dropdownRight={{
                  ...unitMeasurement,
                  name: `itens.${index}.unidadeMedidaId`,
                  disabled: true,
                }}
              />
            </div>
          </Col>
          {
            hideQuantityColumns == false &&
            <Col span={2}>
              <Text
                type="paragraph"
                style={{ marginLeft: '4px' }}
                color={defineColor()}
              >
                {calculateDeference(item.unidadeMedidaAbreviacao)}
              </Text>
            </Col>
          }
          <Col
            span={2}
            style={{
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <PopOver
              placement="bottomRight"
              visible={showPersonModal}
              onOpenChange={() => setShowPersonModal((prev) => !prev)}
              content={<SelectPersonModal index={index} />}
              trigger="click"
              title={
                <div style={{ padding: '16px' }}>
                  {t('supply.inventory.release.responsibleOperator')}
                </div>
              }
            >
              <div style={{ cursor: 'pointer' }}>
                {form.values.itens &&
                form.values.itens[index] &&
                form.values.itens[index].usuarioExternalId ? (
                  <Avatar
                    fisrtName={form.values.itens[index].userName}
                    lastName={form.values.itens[index].userLastName}
                    size="M"
                    type="paragraph"
                    color="white"
                  />
                ) : (
                  <CircularStatus icon="plus" type={'default'} size="SM" />
                )}
              </div>
            </PopOver>
          </Col>
        </>
      )}
    </Row>
  );
};
