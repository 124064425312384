import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputWithSearch
} from '@/Components';
import {
  IListProviderData
} from '@/Data/Interfaces/response/Provider/IProviderResponse';
import { IProvider } from './interfaces';
import { useProviderCallAPI } from './Hooks/useProviderCallAPI';
import { useProviderFunctions } from './Hooks/useProviderFunctions';
import { FormikContextType, useFormikContext } from 'formik';
import { 
  IPurchaseOrderGetResponse 
} from '@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse';

export const Provider: FC<IProvider> = ({
  setExternalIdProvider
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<IPurchaseOrderGetResponse> = useFormikContext();

  const {
    listProvider,
    isLoadingProvider,
    fetchNewProviderPage,
    refetchSearchProviderPage
  } = useProviderCallAPI();

  const {
    onChange,
    onSearch,
    onClear
  } = useProviderFunctions({
    setExternalIdProvider,
    refetchSearchProviderPage
  });

  const formattedListProvider = listProvider && listProvider.data ? 
  listProvider.data : [];

  return (
    <>
      <InputWithSearch
        items={formattedListProvider?.map((value: IListProviderData) => ({
          id: value?.externalId,
          label: value?.nome,
          subLabel: `${value.tipoPessoaDescricao} - ${value.tipoFornecedor}`,
        }))}
        name="fornecedorExternalId"
        placeHolder={t(
          'purchasing.purchaseOrder.create.placeHolder.provider'
        )}
        label={t('purchasing.purchaseOrder.create.provider')}
        required
        withoutMarginBottom
        isLoading={isLoadingProvider}
        onScrollEnd={fetchNewProviderPage}
        onChange={onChange}
        onSearch={onSearch}
        onClear={onClear}
      />
    </>
  );
};