import { Table } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { productSynonymColumns } from './columns';
import { useParams } from 'react-router';
import { useDilutedProductStates } from './Hooks/useDilutedProductStates';
import { useDilutedProductCallAPI } from './Hooks/useDilutedProductCallAPI';
import { useDilutedProductMemorizations } from './Hooks/useDilutedProductMemorizations';
import { useDilutedProductFunctions } from './Hooks/useDilutedProductFunctions';
import { useProductsDilutedProductShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/products/dilutedProduct';

export const DilutedProduct: FC = () => {
  const { t } = useTranslation();
  const { externalId: productExternalId } = useParams<{ externalId: string }>();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useDilutedProductStates();

  const { getShortcut } = useProductsDilutedProductShortcuts(t, {});

  const {
    currentProduct,
    deleteDilutedProduct,
    productList,
    isProductListLoading,
    refetchProductList,
  } = useDilutedProductCallAPI({
    productExternalId,
  });

  const { headerOptions, deleteModal, rowsOptions, tableHeaderHotKeyTooltip } =
    useDilutedProductMemorizations({
      currentProduct,
      selectedRowsOnTable,
      productExternalId,
      getShortcut,
    });

  const { onDelete } = useDilutedProductFunctions({
    deleteDilutedProduct,
  });

  return (
    <PageLoading loading={isProductListLoading}>
      <Table
        loading={isProductListLoading}
        columns={productSynonymColumns(t)}
        hasSelection
        onDelete={onDelete}
        headerOptions={headerOptions}
        pagination={productList}
        onRefetch={refetchProductList}
        deleteModal={deleteModal}
        rowsOptions={rowsOptions}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
      />
    </PageLoading>
  );
};
