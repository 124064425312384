import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Table } from '../../../../../Components/Table';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { laboratoryColumns } from './Laboratory.column';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';

export const LaboratoryList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useListStates();

  const {
    deleteLaboratoryForm,
    laboratoryList,
    isLoadingLaboratoryList,
    editLaboratoryFormStatus,
    refetchLaboratoryList,
  } = useListCallAPI();

  const { headerOptions, rowsOptions, deleteModal, translationsEditModal } =
    useListMemorizations({
      selectedRowsOnTable,
    });

  const { onDelete, onSelectedRowsOnTable, onEdit } = useListFunctions({
    deleteLaboratoryForm,
    setSelectedRowsOnTable,
    editLaboratoryFormStatus,
  });

  return (
    <PageLoading loading={isLoadingLaboratoryList}>
      <Table
        columns={laboratoryColumns(t)}
        loading={isLoadingLaboratoryList}
        headerOptions={headerOptions}
        onEdit={onEdit}
        fieldStatus="ativo"
        editOptions={editTableStatus()}
        editStatusModalLoading={editLaboratoryFormStatus.isLoading}
        setSelectedRowsOnTable={onSelectedRowsOnTable}
        hasSelection
        rowsOptions={rowsOptions}
        pagination={laboratoryList}
        onRefetch={refetchLaboratoryList}
        onDelete={onDelete}
        deleteModal={deleteModal}
        dataTestId="production-laboratory"
        translations={translationsEditModal}
      />
    </PageLoading>
  );
};

export default LaboratoryList;
