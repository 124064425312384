import { IListUserGroupData } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { useState } from 'react';

export const useListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IListUserGroupData[]
  >([]);

  const [selectedItems, setSelectedItems] = useState<string[] | undefined>([]);

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,

    selectedItems,
    setSelectedItems,
  };
};
