import { useTranslation } from 'react-i18next';
import { List as ProductList } from '../Components/ProductForm';
import { DetailProductGroupForm } from '../Components/DetailsForm';
import { SubGroupForm } from '../Components/SubGroupForm';
import { IUseMemorizations } from './interfaces';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useEditMemorizations = ({
  disabled,
  setDisabled,
  setModalDeleteProductGroup,
  params,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabSubGroupsHotkey = getShortcut('alternateTabSubGroups');
  const alternateTabProductsHotkey = getShortcut('alternateTabProducts');
  const editGroupHotkey = getShortcut('editGroup');

  const items = [
    {
      label: t('admin.editUserPage.tab.details'),
      key: 'details',
      children: (
        <DetailProductGroupForm
          disabled={disabled}
          toogleDisabled={(x: boolean) => setDisabled(x)}
        />
      ),
      dataTestId: 'tab-group-details',
      shortCutTooltip: {
        title: alternateTabDetailsHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
    {
      label: t('products.common.subGroups'),
      key: 'subgroups',
      children: <SubGroupForm />,
      dataTestId: 'tab-group-subgroups',
      shortCutTooltip: {
        title: alternateTabSubGroupsHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
    {
      label: t('products.common.products'),
      key: 'products',
      children: <ProductList />,
      dataTestId: 'tab-group-products',
      shortCutTooltip: {
        title: alternateTabProductsHotkey.tooltip,
        targetOffset: [0, 10],
      },
    },
  ];

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: t('admin.editUserPage.tab.buttonActions.delete'),
      icon: 'trash',
      permission: {
        permission: 3012,
        tooltip: 'noPermission.supply.groups.delete',
      },
      onClick: () => setModalDeleteProductGroup(true),
      dataTestId: 'delete-group-option',
      key: 'backspace',
    },
  ];

  const rightButton = {
    ...(disabled &&
      params.tab === 'details' && {
        onClick: () => setDisabled(false),
        isVisible: disabled,
        codePermissionEdit: 3011,
        messageTooltipPermission: 'noPermission.supply.groups.edit',
        dataTestId: 'edit-group-button',
        hotkeyTooltip: {
          title: editGroupHotkey.tooltip,
        },
      }),
  };

  return {
    items,
    dropdownItems,
    rightButton,
  };
};
