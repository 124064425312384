import { ICancelInventoryRequest, ICancelReleaseRequest } from '@/Data/Interfaces/request/Inventory/IInventoryRequest';
import { IBaseResponse } from '@/Data/Interfaces/response/Base/IBase';
import {
  IGetInventory,
  IGroupResponse,
  IInventoryResponse,
  ISubgroupResponse,
  ISupplyPlaceResponse,
} from '@/Data/Interfaces/response/Inventory/InventoryResponse';
import { Http, IQueryListParams } from '@/Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';

export class InventoryAPI {
  public static async getInventoryList(
    context: QueryFunctionContext
  ): Promise<IInventoryResponse | null> {
    return await Http.fetcher<IInventoryResponse>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/Inventario/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listDropdownSupplyPlaces(
    context: QueryFunctionContext
  ): Promise<ISupplyPlaceResponse> {
    return await Http.fetcher<ISupplyPlaceResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Inventario/ListarLocalEstoque',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listDropdownGroups(
    context: QueryFunctionContext
  ): Promise<IGroupResponse> {
    return await Http.fetcher<IGroupResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Inventario/ListarGrupo',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async listDropdownSubgroups(
    context: QueryFunctionContext
  ): Promise<ISubgroupResponse> {
    return await Http.fetcher<ISubgroupResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Inventario/ListarSubGrupo',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async createInventory(body: any): Promise<IBaseResponse<any>> {
    return await Http.fetcher('/pharma/estoque/v1/Inventario', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async getInventory(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IGetInventory>(
      `/pharma/estoque/v1/Inventario/${context.queryKey[1]}`
    );
  }

  public static async savePutInventory(body: any) {
    const result = await Http.fetcher(`/pharma/estoque/v1/Inventario`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    return result;
  }

  public static async deleteInventory(body: any) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Inventario`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getInventoryDetails(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<any>(
      `/pharma/estoque/v1/Inventario/${context.queryKey[1]}/Detalhes`
    );
  }

  public static async createRelease(body: any) {
    return await Http.fetcher(
      '/pharma/estoque/v1/Inventario/IniciarLancamento',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async getInventoryRelease(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<any>(
      `/pharma/estoque/v1/Inventario/${context.queryKey[1]}/Lancamento`
    );
  }

  public static async cancelInventoryRelease(body: ICancelReleaseRequest) {
    return await Http.fetcher<any>(
      `/pharma/estoque/v1/Inventario/CancelarLancamento`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async cancelInventory(body: ICancelInventoryRequest) {
    return await Http.fetcher<any>(
      `/pharma/estoque/v1/Inventario/Cancelar`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }
}
