import { IProductListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { BodyHandler, TitleHandler } from '../Modal/deleteModal';
import { IDeleteModalTable } from '@/Components/Table';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addProductHotkey = getShortcut('addProduct');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteProductHotkey = getShortcut('deleteSelected');
  const editProductHotkey = getShortcut('editSelected');

  const headerOptions = {
    nameKey: 'descricao',
    hasSearch: true,
    searchPlaceholder: t('products.productList.searchPlaceholder'),
    newButton: {
      label: t('products.productList.newButton'),
      onClick: () => history.push('/inventory/products/create'),
      dataTestId: 'create-product',
      permission: {
        permission: 3003,
        tooltip: 'noPermission.supply.products.create',
      },
      tooltip: {
        title: addProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    deleteButton: {
      permission: {
        permission: 3007,
        tooltip: 'noPermission.supply.products.delete',
      },
      tooltip: {
        title: deleteProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'delete-product',
    },
    editButton: {
      permission: {
        permission: 3006,
        tooltip: 'noPermission.supply.products.statusEdit',
      },
      tooltip: {
        title: editProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'edit-product',
      options: [
        {
          label: t('common.status'),
          isStatusOnly: true,
          key: 's',
        },
      ],
    },
  };

  const rowOptions = {
    onRowDoubleClick: (x: IProductListData) =>
      history.push(`/inventory/products/${x.externalId}/details`),
    permission: {
      permission: 3002,
      tooltip: 'noPermission.supply.products.detail',
    },
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
    }),
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
