import { Card, Form, Button, Checkbox, TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { SupplyPlaceRow } from './components/SupplyPlaceRow';
import { App, Col, Row } from 'antd';
import { useGetInventory } from './hooks/useGetInventory';
import { useParams } from 'react-router';
import DateUtils from '@/Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';
import { useEditInventory } from './hooks/useEditInventory';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';

import styles from './inventory.module.scss';

interface IInventoryDetailsProps {
  edit: boolean;
  setEdit: (value: boolean) => void;
}

export const InventoryDetails: React.FC<IInventoryDetailsProps> = ({
  edit,
  setEdit,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { editInventory } = useEditInventory();
  const { inventory } = useGetInventory(externalId);
  const { t } = useTranslation();
  const preferences = StorageUtils.getPreference();
  const dispatch = useDispatch();
  const app = App.useApp();

  const onSubmit = async (values: any) => {
    const data = {
      ...values,
      externalId,
      ocultarQuantidadeLancamento: !!values.ocultarQuantidadeLancamento,
      ocultarQuantidadeConferencia: !!values.ocultarQuantidadeConferencia,
    };

    await editInventory.mutate(data);
    dispatch(NotificationActions.setCurrentFormIsDirty(false));
    setEdit(false);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={inventory ? inventory : { inventarioEspecificacao: [] }}
    >
      {(props) => (
        <>
          <Card withoutCollapse title={t('supply.inventory.create.info')}>
            <Row gutter={16}>
              <Col span={2}>
                <TextInput
                  name="code"
                  label={t('supply.inventory.details.code')}
                  disabled
                  value={
                    inventory ? String(inventory.codigo).padStart(5, '0') : ''
                  }
                />
              </Col>
              <Col span={3}>
                <TextInput
                  name="date"
                  label={t('supply.inventory.details.dateCreation')}
                  disabled
                  value={
                    inventory
                      ? `${DateUtils.convertDate(inventory.dataCriacao)} ${t(
                          'common.dateBreakApart'
                        )} ${DateUtils.convertDate(
                          inventory.dataCriacao,
                          preferences?.padraoHora
                        )}`
                      : ''
                  }
                />
              </Col>
              <Col span={4}>
                <TextInput
                  name="responsible"
                  label={t('supply.inventory.details.responsible')}
                  disabled
                  value={inventory ? inventory.responsavelNomeCompleto : ''}
                />
              </Col>
            </Row>

            <SupplyPlaceRow edit={edit} />

            <Row gutter={16}>
              <Col span={12} className={styles['checkbox']}>
                <Checkbox
                  name="ocultarQuantidadeLancamento"
                  label={t('supply.inventory.create.hideAnotherValue')}
                  withTooltip={{
                    icon: 'question-mark',
                    title: t(
                      'supply.inventory.create.hiderAnotherValueTooltip'
                    ),
                  }}
                  disabled={!edit}
                />
              </Col>
              <Col span={12} className={styles['checkbox']}>
                <Checkbox
                  name="ocultarQuantidadeConferencia"
                  label={t(
                    'supply.inventory.create.hideAnotherValueImpression'
                  )}
                  withTooltip={{
                    icon: 'question-mark',
                    title: t(
                      'supply.inventory.create.hiderAnotherValueImpressionTooltip'
                    ),
                  }}
                  disabled={!edit}
                />
              </Col>
            </Row>
          </Card>
          {edit && (
            <div className={styles['footer-button']}>
              <Button
                type="secondary"
                children={t('products.create.cancel')}
                disabled={editInventory.isLoading}
                onClick={() => 
                  handleCancelEdit(props, app, () => setEdit(true))
                }
              />
              <Button
                type="primary"
                htmlType="submit"
                children={t('common.save')}
                loading={editInventory.isLoading}
              />
            </div>
          )}
        </>
      )}
    </Form>
  );
};
