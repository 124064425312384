import * as yup from 'yup';
import { i18n } from '../../../../../Components';
import { cnpj, cpf } from 'cpf-cnpj-validator';

export const CompanyCardSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) =>
  yup.object().shape({
    cnpj: yup
      .string()
      .nullable()
      .required(i18n.t('common.requiredCNPJ'))
      .test(
        'is-valid-cnpj',
        i18n.t('purchasing.provider.create.errors.requiredCnpj'),
        (value) => (value ? cnpj.isValid(value) : false)
      )
      .test(
        'checkDuplications',
        t('purchasing.provider.create.errors.cnpjAlreadyUsed'),
        (value) => {
          return new Promise((resolve) => {
            validateName(value, (success) => {
              resolve(success);
            });
          });
        }
      ),
    razaoSocial: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredSocialReason'
        )
      ),
    nomeFantasia: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredFantasyName'
        )
      ),
    inscricaoEstadual: yup
      .string()
      .min(
        12,
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.invalidStateRegistration'
        )
      )
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredStateRegistration'
        )
      ),
    cnae: yup
      .string()
      .required(
        i18n.t('adminBootis.createCompany.companyDataCard.errors.requiredCNAE')
      ),
    email: yup
      .string()
      .email(
        i18n.t(
          'adminBootis.createCompany.CompanyAdressCard.errors.invalidEmail'
        )
      ),
    endereco: yup.object().shape({
      cep: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredCEP'
          )
        ),
      numero: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredNumber'
          )
        ),
      bairro: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredNeighborhood'
          )
        ),
      cidade: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredCity'
          )
        ),
      estado: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredState'
          )
        ),
      logradouro: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.CompanyAdressCard.errors.requiredStreet'
          )
        ),
    }),

    usuario: yup.object().when('radioUser', {
      is: true,
      then: yup.object().shape({
        email: yup
          .string()
          .required(
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.invalidMail'
            )
          ),
        nome: yup
          .string()
          .required(
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.requiredName'
            )
          ),
        sobrenome: yup
          .string()
          .required(
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.requiredLastName'
            )
          ),
        dataNascimento: yup
          .string()
          .matches(
            /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/,
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.invalidBirthday'
            )
          ),
        cpf: yup
          .string()
          .test(
            'is-valid-cpf',
            i18n.t(
              'adminBootis.createCompany.responsibleUserCard.errors.invalidCpf'
            ),
            (value) => typeof value === 'string' && cpf.isValid(value)
          ),
      }),
      otherwise: yup.mixed().notRequired(),
    }),

    usuarioResponsavelExternalId: yup.string().when('radioUser', {
      is: false,
      then: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.responsibleUserCard.errors.requiredUser'
          )
        ),
      otherwise: yup.mixed().notRequired(),
    }),

    nomeConglomerado: yup.string().when('radioCompanyGroup', {
      is: true,
      then: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.companyGroupCard.errors.requiredGroupName'
          )
        ),
      otherwise: yup.mixed().notRequired(),
    }),

    conglomeradoExternalId: yup.string().when('radioCompanyGroup', {
      is: false,
      then: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.companyGroupCard.errors.requiredGroup'
          )
        ),
      otherwise: yup.mixed().notRequired(),
    }),

    empresaPagadoraExternalId: yup.string().when('radioCompanyBillings', {
      is: false,
      then: yup
        .string()
        .required(
          i18n.t(
            'adminBootis.createCompany.billingCard.errors.requiredCompanyBilling'
          )
        ),
      otherwise: yup.mixed().notRequired(),
    }),
  });
