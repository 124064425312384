import FormBreakLine from '@/Components/FormBreakLine';
import { useOrderTable } from './hooks/useOrderTable';
import { TableRow } from './components/TableRow';
import { TableHeader } from './components/TableHeader';

interface IReleaseTableProps {
  tableItems: any[];
  startRelease: boolean;
  showQuantity: boolean;
  inventoryItem: any;
  initialIndexCount: number;
  hideQuantityColumns: boolean;
}

export const ReleaseTable: React.FC<IReleaseTableProps> = ({
  tableItems,
  startRelease,
  showQuantity,
  inventoryItem,
  initialIndexCount,
  hideQuantityColumns,
}) => {
  const { orderItems, orientation, toggleOrientation } = useOrderTable(
    initialIndexCount,
    initialIndexCount + tableItems.length
  );

  const showQuantityColumn = startRelease && showQuantity;

  return (
    <>
      <TableHeader
        orientation={orientation}
        startRelease={startRelease}
        toggleOrientation={() => toggleOrientation()}
        hideQuantityColumns={hideQuantityColumns}
      />
      <FormBreakLine marginTop="10px" />
      {orderItems(tableItems).map((item: any, index: number) => (
        <TableRow
          index={index + initialIndexCount}
          item={item}
          startRelease={startRelease}
          supplyPlaceExternalId={inventoryItem.localEstoqueExternalId}
          key={JSON.stringify(item)}
          hideQuantityColumns={hideQuantityColumns}
        />
      ))}
    </>
  );
};
