import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, Form } from '@/Components';
import { ProductDetailsSchema } from './ProductDetails.schema';
import { ProductInfoCard } from '../../../Components/ProductCard';
import { DetailsInitialValues } from './InitialValues';
import { FeedStockInfoCard } from '../../../Components/FeedstockInfoCard';
import { CapsuleTypeCard } from '../../../Components/CapsuleTypeCard';
import { PackingCard } from '../../../Components/PackingCard';
import { CapsuleGranelCard } from '../../../Components/CapsuleGranelCard';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { InfoForFormCalc } from '../../../Components/InfoForFormCalc';
import { IProductCard } from './interfaces';
import { useDetailsCallAPI } from './Hooks/useDetailsCallAPI';
import { useDetailsFunctions } from './Hooks/useDetailsFunctions';
import { handleCancelEdit } from '@/Utils/BehaviorUtils';
import { App } from 'antd';

import styles from './ProductDetails.module.scss';

export const DetailsTab: FC<IProductCard> = ({
  disabledForm,
  toogleDisabled
}) => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const app = App.useApp();
  const feedStockClassId = 1;
  const capsuleTypeClassId = 7;
  const packingClassId = 2;
  const capsuleGranelId = 5;

  const {
    editProduct,
    product
  } = useDetailsCallAPI({
    externalId,
  });

  const {
    onSubmit
  } = useDetailsFunctions({
    product,
    editProduct,
    toogleDisabled,
    disabledForm,
    externalId
  });

  return (
    <>
      <Form
        initialValues={DetailsInitialValues(product) || undefined}
        onSubmit={onSubmit}
        schema={ProductDetailsSchema}
      >
        {(props) => {
          return (
            <>
              <ConnectedFocusError />
              <ProductInfoCard
                disabledForm={disabledForm}
                product={product || undefined}
              />
              {props.values.classeProdutoId === feedStockClassId && (
                <FeedStockInfoCard
                  disabledForm={disabledForm}
                  product={product ?? undefined}
                  feedStockClassId={feedStockClassId}
                />
              )}
              {props.values.classeProdutoId === feedStockClassId && (
                <InfoForFormCalc
                  disabledForm={disabledForm}
                  product={product}
                />
              )}
              {props.values.classeProdutoId === capsuleTypeClassId && (
                <CapsuleTypeCard
                  disabledForm={disabledForm}
                  product={product ?? undefined}
                />
              )}
              {props.values.classeProdutoId === packingClassId && (
                <PackingCard disabledForm={disabledForm} product={product ?? undefined} />
              )}
              {props.values.classeProdutoId === capsuleGranelId && (
                <CapsuleGranelCard
                  disabledForm={disabledForm}
                  product={product ?? undefined}
                />
              )}
              {!disabledForm && (
                <div className={styles['footer-button']}>
                  <Button
                    type="secondary"
                    children={t('products.create.cancel')}
                    onClick={() => {
                      handleCancelEdit(props, app, () => toogleDisabled(!disabledForm))
                    }}
                  />
                  <Button
                    type="primary"
                    children={
                      editProduct.isLoading
                        ? t('products.create.saveLoading')
                        : t('products.create.save')
                    }
                    htmlType="submit"
                    loading={editProduct.isLoading}
                  />
                </div>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};
