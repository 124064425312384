import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_INVENTORY } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';

export const useGetInventory = (inventoryId: string) => {
  const { data: inventory } = useControllerQueryApiHook<any>({
    uniqId: FETCH_GET_INVENTORY,
    entityApi: InventoryAPI.getInventory,
    externalIds: [inventoryId],
  });

  return { inventory };
};
