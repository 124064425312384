import { Hotkey, PossibleKey } from './Hotkey';
import { BootysShortcuts } from './keymaps/default';
import { ReactNode } from 'react';
import { ShortcutTooltip } from './Hotkey/ShortcutTooltip';
import { Text } from '@/Components/Text';

export * from './keymaps/pages/sectors/administrative/user/list';

export interface BootysShortcut {
  id: string;
  name: string;
  keys: string | string[];
  componentKeys: ReactNode[];
  tooltip: ReactNode;
}

export const getKeys = (key?: string | string[], withText?: boolean) => {
  let splittedKeys: PossibleKey[] = [];
  if (!key) {
    return splittedKeys;
  }
  if (Array.isArray(key)) {
    splittedKeys = key as PossibleKey[];
  } else {
    splittedKeys = (
      key.indexOf('+') > -1 ? key.split('+') : [key]
    ) as PossibleKey[];
  }
  const keysAsComponents = splittedKeys.map((x, index) => {
    let key: PossibleKey = x;
    switch (x) {
      case '!':
        key = '1';
        break;
      case '@':
        key = '2';
        break;
      case '#':
        key = '3';
        break;
      case '$':
        key = '4';
        break;
      case '%':
        key = '5';
        break;
      case '^':
        key = '6';
        break;
      case '&':
        key = '7';
        break;
      case '*':
        key = '8';
        break;
      case '(':
        key = '9';
        break;
      case ')':
        key = '0';
        break;
    }
    return <Hotkey key={index} hotKey={key} />;
  });
  if (withText) {
    return keysAsComponents.flatMap((key, index) =>
      index < keysAsComponents.length - 1
        ? [
            key,
            <Text
              type={'ui-tiny-content'}
              key={`text-${index}`}
              style={{ margin: '0 6px 0 3px ', fontSize: 10 }}
              color="text-200"
            >
              depois
            </Text>,
          ]
        : [key]
    );
  }
  return keysAsComponents;
};

export const getShortcuts = (
  shortcuts?: BootysShortcuts[]
): (() => undefined | BootysShortcut[]) => {
  return () => {
    return shortcuts?.map((x) => {
      const keys = getKeys(x?.keys);

      return {
        id: x?.id,
        name: x.name,
        keys: x?.keys,
        componentKeys: getKeys(x?.keys),
        tooltip: <ShortcutTooltip keys={keys} name={x?.name} />,
      } as BootysShortcut;
    });
  };
};

export const getShortcut = (
  shortcuts?: BootysShortcuts[]
): ((id: string) => BootysShortcut) => {
  return (id: string) => {
    const shortcut = shortcuts?.find((x) => x.id === id);
    const keys = getKeys(shortcut?.keys, shortcut?.withText);
    return {
      id: shortcut?.id,
      name: shortcut?.name,
      keys: shortcut?.keys,
      withText: shortcut?.withText,
      componentKeys: keys,
      tooltip: <ShortcutTooltip keys={keys} name={shortcut?.name} />,
    } as BootysShortcut;
  };
};
