import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';
import { Checkbox, InputWithSearch, Text, TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { IGetCityResponse } from '@/Data/Interfaces/response/Locality/ICityResponse';
import { IGetStateResponse } from '@/Data/Interfaces/response/Locality/IStateResponse';
import { useBodyCallAPI } from './Hooks/useBodyCallAPI';
import { useBodyMemorizations } from './Hooks/useBodyMemorizations';
import { useBodyStates } from './Hooks/useBodyStates';
import { Divflex } from '@/Layouts/DivWhithFlex';

export const CreateDeliveryModalBody: FC = () => {
  const { t } = useTranslation();
  const form = useFormikContext<FormikValues>();

  const { CEP, setCEP } = useBodyStates();

  const {
    listCity,
    refetchCityList,
    cityListLoading,
    fetchNewCityPage,
    listState,
    fetchNewStatePage,
    refetchStateList,
    stateListLoading,
    cepLoading,
  } = useBodyCallAPI({ form, CEP });

  const { changeCEP } = useBodyMemorizations({ setCEP });

  return (
    <div style={{ width: '800px', padding: '20px' }}>
      <Row gutter={[16, 0]} justify="start">
        <Col flex="26%">
          <TextInput
            name="descricao"
            label={t('address.modal.description')}
            placeHolder={t('address.modal.descriptionPlaceholder')}
            required
          />
        </Col>
        <Col flex="26%">
          <TextInputWithMask
            name="cep"
            label={t('address.modal.CEP')}
            mask="cepMask"
            placeHolder={'00000-000'}
            loading={cepLoading && form?.values?.cep}
            onChange={changeCEP}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={9}>
          <InputWithSearch
            name="estadoExternalId"
            label={t('address.modal.state')}
            placeHolder={t('address.modal.statePlaceholder')}
            items={listState?.data?.map((x: IGetStateResponse) => ({
              id: x.externalId,
              label: x.descricao,
            }))}
            onScrollEnd={fetchNewStatePage}
            isLoading={stateListLoading}
            onSearch={(search) =>
              refetchStateList({
                search: search,
              })
            }
            withoutSearchIcon
            onChange={() => form.setFieldValue('cidadeExternalId', undefined)}
            selectedItemFromForm="estadoExternalIdInitialValue"
          />
        </Col>
        <Col span={9}>
          <InputWithSearch
            name="cidadeExternalId"
            label={t('address.modal.city')}
            placeHolder={t('address.modal.cityPlaceholder')}
            items={listCity?.data?.map((x: IGetCityResponse) => ({
              id: x.externalId,
              label: x.descricao,
            }))}
            onScrollEnd={fetchNewCityPage}
            isLoading={cityListLoading}
            onSearch={(search) =>
              refetchCityList({
                search: search,
              })
            }
            withoutSearchIcon
            disabled={!form.values.estadoExternalId}
            selectedItemFromForm="cidadeExternalIdInitialValue"
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="bairro"
            label={t('address.modal.district')}
            placeHolder={t('address.modal.districtPlaceholder')}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="60%">
          <TextInput
            name="logradouro"
            label={t('address.modal.street')}
            placeHolder={t('address.modal.streetPlaceholder')}
          />
        </Col>
        <Col flex="20%">
          <TextInput
            name="numero"
            label={t('address.modal.streetNumber')}
            placeHolder={t('address.modal.streetNumberPlaceholder')}
          />
        </Col>
        <Col flex="20%">
          <TextInput
            name="complemento"
            label={t('address.modal.complement')}
            placeHolder={t('address.modal.complementPlaceholder')}
          />
        </Col>
      </Row>
      <Divflex style={{ alignItems: 'center' }}>
        <Checkbox name="principal" style={{ marginRight: 12 }} />
        <Text
          type="ui-tiny-content"
          children={t('address.modal.saveAsFavorite')}
        />
      </Divflex>
    </div>
  );
};
