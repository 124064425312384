import { useCallback } from 'react';
import DateUtils from '@/Utils/DateUtils';
import { ICreateClientRequest } from '@/Data/Interfaces/request/Client/IClientRequest';
import { FormikHelpers, FormikValues } from 'formik';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  createClient,
  personType,
}: IUseFunctions) => {
  const onSubmit = useCallback(
    async (
      values: ICreateClientRequest,
      formik: FormikHelpers<FormikValues>
    ) => {
      try {
        await createClient.mutateAsync({
          ...values,
          ...(values.dataNascimento && {
            dataNascimento: DateUtils.convertDateToApi(values.dataNascimento),
          }),
          ...(personType === 0
            ? { nome: values.nome }
            : { nome: values.fantasyName }),
        });
      } catch {
        formik.setSubmitting(false);
      }
    },
    [createClient]
  );

  return {
    onSubmit,
  };
};
