import {
  BootysShortcuts,
  defaultShortcuts,
  DefaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'user-group-permissions';

export interface UserGroupPermissionsTabCallbacks extends DefaultShortcuts {}

const shortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: UserGroupPermissionsTabCallbacks
): BootysShortcuts[] => [
  ...defaultShortcuts(t, callbacks),
  {
    id: 'alternatePermissionDown',
    name: 'alternatePermissionDown',
    keys: 'arrowDown',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const permissionRows = document.querySelectorAll('.permission-item');

      const currentFocusedIndex = Array.from(permissionRows).findIndex((item) =>
        item.className.includes('focus')
      );

      let newFocus = currentFocusedIndex + 1;

      if (currentFocusedIndex !== -1) {
        permissionRows[currentFocusedIndex].classList.remove('focus');
      }

      permissionRows[newFocus].classList.add('focus');
    },
  },
  {
    id: 'alternatePermissionUp',
    name: 'alternatePermissionDown',
    keys: 'arrowUp',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const permissionRows = document.querySelectorAll('.permission-item');

      const currentFocusedIndex = Array.from(permissionRows).findIndex((item) =>
        item.className.includes('focus')
      );

      let newFocus = currentFocusedIndex - 1;

      if (currentFocusedIndex > -1) {
        permissionRows[currentFocusedIndex].classList.remove('focus');
      }

      permissionRows[newFocus].classList.add('focus');
    },
  },
  {
    id: 'allowPermission',
    name: t('admin.editUserPage.shortcuts.allowPermission'),
    keys: 'p',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const allowButton = document.querySelector(
        '.focus [data-testid="permission-toggle-allow"]'
      );

      if (isVisible(allowButton)) {
        //@ts-ignore
        allowButton?.click();
      }
    },
  },
  {
    id: 'denyPermission',
    name: t('admin.editUserPage.shortcuts.denyPermission'),
    keys: 'n',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const denyButton = document.querySelector(
        '.focus [data-testid="permission-toggle-deny"]'
      );

      if (isVisible(denyButton)) {
        //@ts-ignore
        denyButton?.click();
      }
    },
  },
];

export const useUserGroupPermissionsShortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: UserGroupPermissionsTabCallbacks
) => {
  const pageShortcuts = shortcuts(t, callbacks);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
