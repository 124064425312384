import * as yup from 'yup';
import { i18n } from '../../../Components';
import { cnpj } from 'cpf-cnpj-validator';

export const CompanyDataCardSchema = (
  validateName: (
    value: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) =>
  yup.object().shape({
    cnpj: yup
      .string()
      .nullable()
      .required(i18n.t('common.requiredCNPJ'))
      .test(
        'is-valid-cnpj',
        i18n.t('purchasing.provider.create.errors.requiredCnpj'),
        (value) => (value ? cnpj.isValid(value) : false)
      )
      .test(
        'checkDuplications',
        t('purchasing.provider.create.errors.cnpjAlreadyUsed'),
        (value) => {
          return new Promise((resolve) => {
            validateName(value, (success) => {
              resolve(success);
            });
          });
        }
      ),
    razaoSocial: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredSocialReason'
        )
      ),
    nomeFantasia: yup
      .string()
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredFantasyName'
        )
      ),
    inscricaoEstadual: yup
      .string()
      .min(
        12,
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.invalidStateRegistration'
        )
      )
      .required(
        i18n.t(
          'adminBootis.createCompany.companyDataCard.errors.requiredStateRegistration'
        )
      ),
    cnae: yup
      .string()
      .required(
        i18n.t('adminBootis.createCompany.companyDataCard.errors.requiredCNAE')
      ),
  });
