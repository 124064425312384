import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeleteSpecialtyWithBondProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'especialidadePrescritorDescricao',
  oneErrorModal: {
    titleModal: `${t(
      'common.modalCannotDelete.messageTitle'
    )} ${t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.oneEntity.messageSubtitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.canNotDelete')} ${t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.oneEntity.messageSubtitle'
    )}`,
    reasonTranslation: t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.oneEntity.reasonTranslation'
    ),
    warningModal: t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.warning'
    ),
  },
  manyErrorsModal: {
    entity: t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.specialtiesTitle',
    ),
    complement: t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.specialtiesComplement',
    ),
    subTitleModal: t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.manyEntity.messageSubtitle'
    ),
    reasonTranslation: t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.manyEntity.reasonTranslation'
    ),
    warningModal: t(
      'specialties.modalCannotDelete.cannotDeleteSpecialtiesWithMovementsInTheSystem.warning'
    ),
  },
});
