export const addressEN = {
  address: 'Adress',
  adresses: 'Adresses',

  addAddress: 'Add address',
  editAddress: 'Edit address',

  addressEmpyt: 'No address registered',

  searchForAddress: 'Search for an address',

  description: 'Description',

  deleteTitleQuestion: 'Delete address?',
  deleteQuestion:
    'Are you sure you want to delete the address {{logradouro}}, {{numero}}, {{bairro}}, {{cidade}}, {{estado}}, {{pais}}?',

  noAddress: 'No address',

  modal: {
    description: 'Description',
    descriptionPlaceholder: 'E.g: Matriz',
    requiredDescription: 'Description is required',

    CEP: 'CEP',
    invalidCEP: 'Invalid CEP',

    street: 'Street',
    streetPlaceholder: 'E.g: Street João Augusto de Moraes',

    streetNumber: 'Number',
    streetNumberPlaceholder: 'E.g: 465',

    complement: 'Complement',
    complementPlaceholder: 'E.g: room 01',

    district: 'District',
    districtPlaceholder: 'E.g: City center',

    city: 'City',
    cityPlaceholder: 'Select a city',

    state: 'State',
    statePlaceholder: 'Select a state',

    saveAsFavorite: 'Save as main address',
  },

  areYouSureDelete: 'Are you sure you want to delete this address?',
  areYouSureDeleteWithAddress:
    'Are you sure you want to delete the location {{description}}, with address at {{address}}?',
  messages: {
    createAddress: 'Address created!',
    changeAddress: 'Address edited!',
    deleteAddress: 'Address deleted!',
    changeFavoriteAddress: 'Updated favorite address!',
  },
};

export const addressPT = {
  address: 'Endereço',
  adresses: 'Endereços',

  addAddress: 'Adicionar endereço',
  editAddress: 'Editar endereço',

  addressEmpyt: 'Nenhum endereço cadastrado',

  searchForAddress: 'Pesquise por um endereço',

  addAdressTitle: 'Adicionar endereço',
  editAdressTitle: 'Editar endereço',

  description: 'Descrição',

  deleteTitleQuestion: 'Excluir endereço?',
  deleteQuestion:
    'Você tem certeza que deseja excluir o endereço {{logradouro}}, {{numero}}, {{bairro}}, {{cidade}}, {{estado}}, {{pais}}?',

  noAddress: 'Sem endereço',

  modal: {
    description: 'Descrição',
    descriptionPlaceholder: 'Ex: Matriz',
    requiredDescription: 'Descrição é obrigatória',

    CEP: 'CEP',
    invalidCEP: 'CEP inválido',

    street: 'Logradouro',
    streetPlaceholder: 'Ex: Rua João Augusto de Moraes',

    streetNumber: 'Número',
    streetNumberPlaceholder: 'Ex: 465',

    complement: 'Complemento',
    complementPlaceholder: 'Ex: Sala 01',

    district: 'Bairro',
    districtPlaceholder: 'Ex: Centro',

    city: 'Cidade',
    cityPlaceholder: 'Selecione uma cidade',

    state: 'Estado',
    statePlaceholder: 'Selecione um estado',

    saveAsFavorite: 'Salvar como endereço principal',
  },

  areYouSureDelete: 'Você tem certeza que deseja excluir este endereço?',
  areYouSureDeleteWithAddress:
    'Você tem certeza que deseja excluir o local {{description}}, com endereço em {{address}}?',
  messages: {
    createAddress: 'Endereço criado!',
    changeAddress: 'Endereço editado!',
    deleteAddress: 'Endereço deletado!',
    changeFavoriteAddress: 'Endereço favorito atualizado!',
  },
};
