import i18n from 'i18next';
import { useParams } from 'react-router';

export const useConfigTabs = (edit: any, setEdit: any): any => {
  const { tab } = useParams<{ tab: string }>();

  const rightButtonConfig = {
    isVisible: !edit && tab === 'details',
    tooltipPosition: 'bottomRight',
    targetOffsetTooltip: [35, -15],
    onClick: () => setEdit(true),
  };

  const dropdownDetailsItems = (openDeleteModal: any) => {
    return [
      {
        label: i18n.t('purchasing.invoice.details.exclude'),
        icon: 'trash',
        targetOffsetTooltip: [35, -15],
        onClick: openDeleteModal,
      },
    ];
  };

  const dropdownReleaseItems = (openCancelModal: () => void) => {
    return [
      {
        label: i18n.t('supply.inventory.dropdownOptions.releaseOption'),
        icon: 'trash',
        targetOffsetTooltip: [35, -15],
        onClick: openCancelModal,
      },
    ];
  };

  const dropdownTitle = i18n.t('purchasing.invoice.details.moreActions');

  return { 
    rightButtonConfig, 
    dropdownDetailsItems, 
    dropdownReleaseItems,
    dropdownTitle, 
  };
};
