import { FETCH_LIST_PRESCRIBERS } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { 
  IChangePrescriberStatusRequest 
} from '@/Data/Interfaces/request/Prescribers/IPrescribersRequest';
import { useQueryClient } from '@tanstack/react-query';
import { IUseFunctions } from './interfaces';
import { useCallback } from 'react';
import { IListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { 
  cannotDeletePrescitorWithMovementsInTheSystemProps 
} from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalPrescriber';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';

export const usePrescribersFunctions = ({
  changeStatus,
  deletePrescriber,
  setSelectedRowsOnTable,
}: IUseFunctions) => {
  const app = App.useApp();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const changePrescriberStatus = useCallback(
    async (values: IChangePrescriberStatusRequest) => {
      var body: IChangePrescriberStatusRequest = { ...values };

      const res = await changeStatus.mutateAsync({
        ...body,
      });
      if (res.success) queryClient.invalidateQueries([FETCH_LIST_PRESCRIBERS]);
    },
    [changeStatus]
  );

  const deletePrescribers = useCallback(
    async (externalId: string[]) => {
      await deletePrescriber(externalId, {
        onError: handleModalError(
          app,
          cannotDeletePrescitorWithMovementsInTheSystemProps(t)
        ),
      });
    },
    [deletePrescriber]
  );

  const onDelete = useCallback(
    (prescribers: IListPrescribersData[]) =>
      deletePrescribers(prescribers.map((prescriber) => prescriber.externalId)),
    [deletePrescribers]
  );

  const onEdit = useCallback(
    (prescriber: IListPrescribersData[], values: any) =>
      changePrescriberStatus({
        prescritoresExternalId: prescriber.map((x) => x.externalId),
        ativo: values.status === 'success',
      }),
    [changePrescriberStatus]
  );

  const selectRowsOnTable = useCallback(
    (x: IListPrescribersData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  return {
    changePrescriberStatus,
    deletePrescribers,
    onDelete,
    onEdit,
    selectRowsOnTable,
  };
};
