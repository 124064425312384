import { useMemo } from 'react';
import { DetailForm } from '../Components/DetailForm';
import { useTranslation } from 'react-i18next';
import { UserForm } from '../Components/UserForm';
import { PermissionForm } from '../Components/PermissionForm';
import { useEditUserGroupsStates } from './useEditUserGroupsStates';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';
import { IUseMemorizations } from './interfaces';
import { useUserGroupDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/administrative/userGroups/details';

export const useEditUserGroupsMemorizations = ({
  userGroup,
  externalId,
  setModalDeleteUserGroup,
}: IUseMemorizations) => {
  const { formDisabled, setFormDisabled } = useEditUserGroupsStates();

  const { t } = useTranslation();

  const { getShortcut } = useUserGroupDetailsShortcuts(t, {});

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabUsersHotkey = getShortcut('alternateTabUsers');
  const alternateTabPermissionsHotkey = getShortcut('alternateTabPermissions');

  const items = useMemo(
    () => [
      {
        label: t('admin.editUserGroups.tab.details'),
        key: 'details',
        children: (
          <DetailForm
            userGroup={userGroup || undefined}
            disabledForm={formDisabled}
            onDisabledForm={setFormDisabled}
          />
        ),
        dataTestId: 'tab-user-group-details',
        shortCutTooltip: {
          title: alternateTabDetailsHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
      {
        label: t('admin.editUserGroups.tab.user'),
        key: 'users',
        children: externalId && (
          <UserForm externalId={externalId} userGroup={userGroup} />
        ),
        dataTestId: 'tab-user-group-users',
        shortCutTooltip: {
          title: alternateTabUsersHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
      {
        label: t('admin.editUserGroups.tab.permissions'),
        key: 'permissions',
        children: <PermissionForm userGroup={userGroup || undefined} />,
        dataTestId: 'tab-user-group-permissions',
        shortCutTooltip: {
          title: alternateTabPermissionsHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
    ],
    [externalId, userGroup, formDisabled, t, setFormDisabled]
  );

  const dropdownItems = useMemo(
    (): IButtonDropDownItems | undefined => [
      {
        label: t('common.delete'),
        icon: 'trash',
        onClick: () => setModalDeleteUserGroup(true),
        dataTestId: 'dropdown-option-delete',
        key: 'backspace',
      },
    ],
    [t, setModalDeleteUserGroup]
  );

  return {
    items,
    dropdownItems,
  };
};
