export const sideMenuEN = {
  shortcuts: {
    openSectors: 'Open sectors menu',
  },
  administrative: 'Administrative',
  administrativeItems: {
    users: 'Users',
    userGroups: 'User Groups',
    company: 'Companys',
    Audit: 'Audit',
  },
  adminBootis: 'Admin Bootis',
  adminBootisItems: {
    company: 'Companys',
    companyGroups: 'Company Groups',
    Audit: 'Audit',
  },
  inventory: 'Inventory',
  inventoryItems: {
    lots: 'Lots',
    stockLocations: 'Stock Locations',
    inventory: 'Inventory',
    transfers: 'Transfers',
    losses: 'Losses',
    balanceAdjustment: 'Balance Adjustment',
    movimentation: 'Movimentation',
    productMensage: 'Product Mensage',
  },
  products: 'Products',
  productsItems: {
    products: 'Products',
    group: 'Group',
    subGroup: 'SubGroups',
  },
  production: 'Production',
  productionItems: {
    recipes: 'Revenues',
    doubleCheck: 'Conference',
    records: 'Registrations',
    laboratory: 'Laboratories',
    defaultFormula: 'Standard Formula',
    form: 'Pharmaceutical form',
    dosage: 'Dosage',
    packagingClassification: 'Classification of Packing',
    capsulesColors: 'Capsule Colors',

    pharmaceuticalForms: 'Pharmaceutical Forms',
    formulas: 'Formulas',
    packingClassification: 'Packing Classif.',
    capsuleColors: 'Capsule Colors',
  },
  purchases: 'Purchases',
  purchasesItems: {
    purchaseRequests: 'Purchase Requests',
    providers: 'Providers',
    entryInvoices: 'Entry Invoices',
  },
  sales: 'Sales',
  salesItems: {
    prescribers: 'Prescribers',
    prescribersItems: {
      specialties: 'Specialties',
    },
    service: 'Services',
    saleOrder: 'Sales Orders',
    client: 'Clients',
  },
  financial: 'Financial',
  reports: 'Reports',
};

export const sideMenuPT = {
  shortcuts: {
    openSectors: 'Abrir menu de setores',
  },
  administrative: 'Administrativo',
  administrativeItems: {
    users: 'Usuários',
    userGroups: 'Grupos de Usuários',
    company: 'Empresas',
    Audit: 'Auditoria',
  },
  adminBootis: 'Admin Bootis',
  adminBootisItems: {
    company: 'Empresas',
    companyGroups: 'Grupos de Empresas',
    Audit: 'Auditoria',
  },
  inventory: 'Estoque',
  inventoryItems: {
    lots: 'Lotes',
    stockLocations: 'Locais de Estoque',
    inventory: 'Inventário',
    transfers: 'Transferências',
    losses: 'Perdas',
    balanceAdjustment: 'Ajuste de Saldo',
    movimentation: 'Movimentações',
    productMensage: 'Mensagem do Produto',
  },
  products: 'Produtos',
  productsItems: {
    products: 'Produtos',
    group: 'Grupos',
    subGroup: 'Subgrupos',
  },
  production: 'Produção',
  productionItems: {
    recipes: 'Receitas',
    doubleCheck: 'Conferência',
    records: 'Cadastros',
    laboratory: 'Laboratórios',
    defaultFormula: 'Fórmula Padrão',
    form: 'Forma Farmacêutica',
    dosage: 'Posologia',
    packagingClassification: 'Classificação de Embalagem',
    capsulesColors: 'Cores de Cápsulas',

    pharmaceuticalForms: 'Formas Farmacêuticas',
    formulas: 'Fórmulas',
    packingClassification: 'Classif. de Embalagens',
    capsuleColors: 'Cores de Cápsulas',
  },
  purchases: 'Compras',
  purchasesItems: {
    purchaseRequests: 'Pedidos de Compra',
    providers: 'Fornecedores',
    entryInvoices: 'Notas Fiscais',
  },
  sales: 'Vendas',
  salesItems: {
    prescribers: 'Prescritores',
    prescribersItems: {
      specialties: 'Especialidades',
    },
    service: 'Atendimentos',
    saleOrder: 'Pedidos de Venda',
    client: 'Clientes',
  },
  financial: 'Financeiro',
  reports: 'Relatórios',
};
