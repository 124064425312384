import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { DELETE_INVENTORY } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { history } from '@/Store';

export const useDeleteInventory = () => {
  const { mutateAsync: deleteInventory, isLoading }: any =
    useControllerMutateApiHook({
      uniqId: DELETE_INVENTORY,
      entityApi: InventoryAPI.deleteInventory,
      options: {
        onSuccess: () => {
          history.push('/inventory/stock-taking');
        },
      },
    });

  return { deleteInventory, isLoading };
};
