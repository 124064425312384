import { FC } from 'react';
import { Form } from '@/Components';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { InfoCard } from './Components/Cards/InfoCard';
import { ContactCard } from './Components/Cards/ContactCard';
import { AddressCard } from './Components/Cards/AddressCard';
import { DocumentCard } from './Components/Cards/DocumentCard';
import { FooterButton } from './Components/FooterButton';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { CreateSchema } from './Create.schema';

export const CreatePrescribers: FC = () => {
  const { createPrescriber, createPrescriberIsLoading } = useCreateCallAPI();

  const { preferences } = useCreateMemorizations();

  const { onSubmit } = useCreateFunctions({
    createPrescriber,
    preferences,
  });

  return (
    <Form onSubmit={onSubmit} schema={CreateSchema}>
      {(props) => (
        <>
          <ConnectedFocusError />
          <InfoCard />
          <ContactCard props={props} />
          <AddressCard props={props} />
          <DocumentCard props={props} />
          <FooterButton
            createPrescriberIsLoading={createPrescriberIsLoading}
          />
        </>
      )}
    </Form>
  );
};
