export const GET_COMPANY_ADMIN_TYPE = '[COMPANY] GET_COMPANY_ADMIN_TYPE';
export const GET_COMPANY_TYPE = '[COMPANY] GET_COMPANY_TYPE';
export const GET_COMPANY_CONFIG_TYPE = '[COMPANY] GET_COMPANY_CONFIG_TYPE';

export const LIST_COMPANY_TYPE = '[COMPANY] LIST_COMPANY_TYPE';
export const LIST_COMPANY_ADMIN_TYPE = '[COMPANY] LIST_COMPANY_ADMIN_TYPE';
export const LIST_FOR_DORPDOWN_ADMIN_TYPE =
  '[COMPANY] LIST_FOR_DORPDOWN_ADMIN_TYPE';
export const LIST_FOR_DROPDOWN_TYPE = '[COMPANY] LIST_FOR_DORPDOWN_TYPE';

export const CREATE_COMPANY_TYPE = '[COMPANY] CHANGE_COMPANY_TYPE';
export const FETCH_DELETE_TYPE = '[COMPANY] FETCH_DELETE_TYPE';

export const CHANGE_STATUS_TYPE = '[COMPANY] CHANGE_STATUS_TYPE';
export const CHANGE_INFO_TYPE = '[COMPANY] CHANGE_INFO_TYPE';
export const CHANGE_ADRESS_TYPE = '[COMPANY] CHANGE_ADRESS_TYPE';
export const CHANGE_RESPONSIBLE_USER_TYPE =
  '[COMPANY] CHANGE_RESPONSIBLE_USER_TYPE';
export const CHANGE_PAYING_TYPE = '[COMPANY] CHANGE_PAYING_TYPE';

export const VALIDATION_CNPJ_COMPANY = '[COMPANY] VALIDATION_CNPJ_COMPANY';
