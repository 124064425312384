import { IUseMemorizations } from './interfaces';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DeleteModalBody } from '../Modal/DeleteModalBody';
import { IDeleteModalTable } from '@/Components/Table';
import { Placements } from '@/Components/Tooltip';

export const useIncompatibleProductMemorizations = ({
  productExternalId,
  selectedRowsOnTable,
  currentProduct,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addNewIncompatibleProductHotkey = getShortcut(
    'addNewIncompatibleProduct'
  );
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteIncompatibleProductHotkey = getShortcut(
    'deleteIncompatibleProduct'
  );

  const headerOptions = {
    hasSearch: true,
    newButton: {
      onClick: () =>
        history.push(
          `/inventory/products/${productExternalId}/incompatible/createIncompatible`
        ),
      label: t('products.incompatible.newIncompatibleProduct'),
      permission: {
        permission: PermissionEnum.Estoque_ProdutosIncompativel_Cadastrar,
        tooltip:
          'noPermission.supply.products.youCantCreateProductIncompatible',
      },
      dataTestId: 'new-incompatible-product-button',
      tooltip: {
        title: addNewIncompatibleProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    deleteButton: {
      label: t('common.remove'),
      permission: {
        permission: PermissionEnum.Estoque_ProdutosIncompativel_Excluir,
        tooltip:
          'noPermission.supply.products.youCantRemoveProductIncompatible',
      },
      dataTestId: 'delete-incompatible-product-button',
      tooltip: {
        title: deleteIncompatibleProductHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    searchPlaceholder: t(
      'products.incompatible.searchForIncompatibleProductPlaceholder'
    ),
  };

  const deleteModal: IDeleteModalTable | undefined = useMemo(
    () => ({
      title:
        selectedRowsOnTable.length === 1
          ? t('modal.deleteModal.single.productIncompatibleTitle')
          : t('modal.deleteModal.many.productIncompatibleTitle'),
      body: (
        <DeleteModalBody
          selectedRowsOnTable={selectedRowsOnTable}
          currentProduct={currentProduct ? currentProduct.descricao : ''}
        />
      ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      okButtonName: t('common.remove'),
    }),
    [currentProduct, selectedRowsOnTable]
  );

  const rowsOptions = {
    onRowDoubleClick: (x: any) => {
      history.push(
        `/inventory/products/${productExternalId}/incompatible/${x.externalId}/incompatibleDetails`
      );
    },
  };

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    deleteModal,
    rowsOptions,
    tableHeaderHotKeyTooltip,
  };
};
