import { Button, Icon, Form, TextInput, i18n, Dropdown } from '@/Components';
import { useTranslation } from 'react-i18next';
import { StatusCard as StatusCardComponent } from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { CardDates } from './components/CardDates';
import { useParams } from 'react-router';
import { useGetInventoryDetails } from './hooks/useGetInventoryDetails';
import { useEffect, useState } from 'react';
import { StartReleaseModal } from './components/StartReleaseModal';
import { useGetInventory } from './hooks/useGetInventory';
import { useStartRelease } from './hooks/useStartRelease';
import { useQueryClient } from '@tanstack/react-query';
import {
  FETCH_GET_INVENTORY,
  FETCH_GET_INVENTORY_DETAILS,
  FETCH_LIST_INVENTORY,
} from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { Progress } from './components/Progress';
import { TableCard } from './components/TableCard';
import { FinishReleaseModal } from './components/FinishReleaseModal';
import { FinishButton } from './components/FinishButton';
import { useGetInventoryRelease } from './hooks/useGetInventoryRelease';
import { ButtonDropdown } from '@/Components/Button/ButtonDropdown';
import { useReleaseMemorizations } from './hooks/useReleaseMemorizations';
import { CancelReleaseModal } from './components/CancelReleaseModal';
import { useReleaseCallAPI } from './hooks/useReleseCallAPI';
import { useReleaseFunctions } from './hooks/useReleaseFunctions';
import DateUtils from '@/Utils/DateUtils';
import { IReleaseHistory } from './interfaces';

import styles from './styles.module.scss';

export const Release: React.FC = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { inventory } = useGetInventory(externalId);
  const { inventoryDetails } = useGetInventoryDetails(externalId);
  const { inventoryRelease } = useGetInventoryRelease(externalId);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleCancelModal, setIsVisibleCancelModal] = useState(false);
  const [startRelease, setStartRelease] = useState(false);
  const [inventoryIsCanceled, setInventoryIsCanceled] = useState(false);
  const [inventoryConference, setInventoryConference] = useState(false);
  const { createRelease } = useStartRelease();
  const [isFinishReleaseModalVisible, setIsFinishReleaseModalVisible] =
    useState(false);

  const {
    dropdownReleaseOptionsItems,
    releaseHistoryReverted,
    CONFERENCE_STATUS,
    CANCELED_STATUS,
  } = useReleaseMemorizations({
    setIsVisibleCancelModal,
    releaseHistory: inventoryRelease?.historico
  });

  const {
    cancelRelease
  } = useReleaseCallAPI();

  const {
    onOkClickCancelReleaseModal
  } = useReleaseFunctions({
    externalId,
    setIsVisibleCancelModal,
    setStartRelease
  });

  const inventoryMap = inventoryRelease ? inventoryRelease : inventoryDetails;

  useEffect(() => {
    const inventoryReleaseId = 2;

    if (inventory && inventory.statusInventarioId === inventoryReleaseId) {
      setStartRelease(true);
    } else {
      setStartRelease(false);
    }

    if(inventory && inventory.statusInventarioId === CANCELED_STATUS) {
      setInventoryIsCanceled(true);
    } else {
      setInventoryIsCanceled(false);
    }

    if(inventory && inventory.statusInventarioId === CONFERENCE_STATUS) {
      setInventoryConference(true);
    } else {
      setInventoryConference(false);
    }
  }, [inventory]);

  return (
    <>
      <Form>
        <div className={styles['releaseCard']}>
          <div className={styles['cardContainer']}>
            {inventoryRelease &&
              inventory &&
              inventoryDetails?.especificacao?.map((item: any, index: number) => (
                <TableCard
                  index={index}
                  inventory={inventory}
                  inventoryDetails={inventoryDetails}
                  item={item}
                  startRelease={startRelease}
                  hideQuantityColumns={inventory?.ocultarQuantidadeLancamento}
                />
              ))}
          </div>
          <div className={styles['statusContainer']}>
            <StatusCardComponent
              type="ui-main-semibold"
              children={
                startRelease
                  ? t('supply.inventory.release.release') :
                inventoryIsCanceled
                  ? t('supply.inventory.release.inventoryCanceled') :
                inventoryConference
                  ? t('supply.inventory.release.inventoryConference')
                  : t('supply.inventory.release.waitingRelease')
              }
              icon={{ icon: 'time' as any }}
              className={`${
                startRelease
                  ? styles[`releasing-status`] :
                  inventoryIsCanceled
                  ? styles['canceled-status'] :
                  inventoryConference
                  ? styles[`conference-status`]
                  : styles[`waitingApproval-status`]
              } ${styles['status-card']}`}
            />
            {startRelease && <Progress />}

            {!inventoryIsCanceled &&
              <div
                style={{
                  justifyContent: 'space-between',
                  margin: '15px 0 15px 0',
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: !inventoryConference ? '7fr 1fr' : '1fr',
                  gap: 5,
                }}
              >
                {!inventoryConference &&
                  <FinishButton
                    setIsVisibleModal={
                      startRelease
                        ? setIsFinishReleaseModalVisible
                        : setIsVisibleModal
                    }
                    startRelease={startRelease}
                  />
                }
                {
                  !startRelease &&
                  <Button
                    type="secondary"
                    style={{ width: '100%' }}
                  >
                    <>
                      <Icon
                        icon='printer'
                        color="text-50"
                        size="ML"
                      />
                      {
                        inventoryConference && 
                        <span style={{marginLeft: '8px'}}>
                          {t('supply.inventory.release.dropdownOptions.print')}
                        </span>
                      }
                    </>
                  </Button>
                }
                {
                  startRelease &&
                  <ButtonDropdown
                    dropdownDirection="bottomRight"
                    leftIcon='menu-ellipsis-horizontal'
                    dropdownItens={dropdownReleaseOptionsItems}
                    dropdownTitle={t('supply.inventory.release.dropdownOptions.title')}
                    type='secondary'
                    dataTestId='release-options'
                  />
                }
              </div>
            }
            <FormBreakLine 
              style={inventoryIsCanceled ? {marginBottom: '10px', marginTop: '10px'} : {}} 
            />
            <CardDates>
              {
                inventoryConference &&
                <Dropdown
                  label={t('supply.inventory.release.previousReleases')}
                  name="previousReleases"
                  items={
                    releaseHistoryReverted?.map((item: IReleaseHistory, index) => ({
                      id: item.codigoLancamento,
                      label: `
                        ${t('supply.inventory.release.previousReleasesLabel')}
                        ${releaseHistoryReverted.length - index}
                        ${index === 0 ? ` ${t('supply.inventory.release.previousReleasesExtraLabel')}` 
                          : ''
                        }
                      `
                    })) || []
                  }
                />
              }
              {!inventoryIsCanceled &&
                <TextInput
                  label={t('supply.inventory.release.releaseDate')}
                  name="releaseDate"
                  disabled
                  value="-"
                />
              }
              {inventoryIsCanceled &&
                <TextInput
                  label={t('supply.inventory.release.cancellationDate')}
                  name="cancellationDate"
                  disabled
                  value={
                    inventoryRelease?.historico?.length > 0 ?
                    DateUtils.formatLongDate(
                      inventoryRelease?.historico[inventoryRelease?.historico.length - 1]?.data,
                      'dd/mm/yyyy'
                    ) : '-'
                  }
                />
              }
              <TextInput
                label={t('supply.inventory.release.responsible')}
                name="responsible"
                disabled
                value="-"
              />
            </CardDates>
          </div>
        </div>
        <FinishReleaseModal
          visible={isFinishReleaseModalVisible}
          changeVisibility={() => {
            setIsFinishReleaseModalVisible((prev) => !prev);
          }}
        />
      </Form>
      <StartReleaseModal
        onCancel={() => setIsVisibleModal(false)}
        visible={isVisibleModal}
        onOk={async () => {
          await createRelease.mutateAsync({ externalId });
          queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
          queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
          queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
          setIsVisibleModal(false);
          setStartRelease(true);
        }}
        loading={createRelease.isLoading}
      />
      <CancelReleaseModal
        onCancel={() => setIsVisibleCancelModal(false)}
        visible={isVisibleCancelModal}
        onOk={() => onOkClickCancelReleaseModal(cancelRelease)}
        loading={cancelRelease.isLoading}
      />
    </>
  );
};
